<script setup>
import InlineSvg from 'vue-inline-svg';
import useApp from '@/customer/Composables/useApp';

const { app } = useApp();
</script>

<template>
    <component :is="app.logo?.is_svg ? InlineSvg : 'img'" :src="app.logo?.company_url" />
</template>
