<script setup>
import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
import { SelectionSummary, TrailingInputs, AccordionPanel, FixedItemBar } from '.';
import { Image } from '@/customer/Components';
import { useMenuItemStore } from '@/customer/Composables';
import StartOrderButton from './StartOrderButton.vue';
import SizeSelectorPill from './SizeSelectorPill.vue';

const $store = useMenuItemStore();

const props = defineProps({
    menuItem: { type: Object },
    price: { type: String },
    name: { type: String, default: null },
    instructions: { type: String, default: null },
    selections: { type: Object, default: () => ({}) },
    quantities: { type: Object, default: () => ({}) },
    requiredSelections: { type: Array, default: [] },
});

const showDisplaySummary = ref(props.menuItem.displaySummary ?? false);

// initialize refs related to resizing addToCart button
const buttonPositionAttribute = ref('fixed');
const addToCartRef = ref(null);
const leftSideRef = ref(null);

// callback to position addToCart button depending on position (element's width as fixed isn't bound to parent like absolute)
let wasFixed = false;
const setAddToButtonCartWidth = () => {
    if (leftSideRef.value && addToCartRef.value) {
        const rect = leftSideRef.value.getBoundingClientRect();
        if (buttonPositionAttribute.value === 'fixed') {
            addToCartRef.value.style.left = rect.left + 'px';
            addToCartRef.value.style.width = rect.width + 'px';
            wasFixed = true;
        } else if (wasFixed) {
            wasFixed = false;
            addToCartRef.value.style.left = 0;
            addToCartRef.value.style.width = rect.width + 'px';
        }
    }
};

const rightContainerRef = ref(null);
const checkHeight = () => {
    if (
        rightContainerRef.value &&
        rightContainerRef.value.getBoundingClientRect().bottom + 16 >= window.innerHeight
    ) {
        buttonPositionAttribute.value = 'fixed';
    } else {
        buttonPositionAttribute.value = 'absolute';
    }
    setAddToButtonCartWidth();
};

/**
 * since the addToCart button isn't necessarily mounted on mount
 * because it waits for $store.ready to resolve to true
 * watch the ref for when the element mounts and call width function
 */
watch(
    () => addToCartRef.value,
    (newVal) => {
        if (newVal) {
            setAddToButtonCartWidth();
        }
    },
    { immediate: true, deep: true },
);

/**
 * on mount, get DOM Rect for page header, initiate flow with checkHeight
 * add eventListeners to scroll and resize to maintain UI
 */
onMounted(() => {
    checkHeight();
    window.addEventListener('scroll', checkHeight);
    window.addEventListener('resize', setAddToButtonCartWidth);
});

// clean up eventListeners
onUnmounted(() => {
    window.removeEventListener('scroll', checkHeight);
    window.removeEventListener('resize', setAddToButtonCartWidth);
});

const accordionAction = () => {
    setTimeout(() => {
        checkHeight();
    }, 100);
};

const choicePrice = computed(() => $store.totalChoicePrice + $store.totalInnerChoicePrice);
const totalPriceWithoutChoice = computed(() => $store.price.raw);
const hasUpgrade = computed(() => {
    if (props.menuItem.type == 'simple') {
        return true;
    }

    let bool = false;

    if (props.menuItem.type == 'combination') {
        props.menuItem.comboItemOptions?.forEach((item) => {
            item.type == 'upgrade' ? (bool = true) : null;
        });
    } else {
        props.menuItem.configurations?.forEach((item) =>
            item.configuration_type === 'upgrade' ? (bool = true) : null,
        );
    }
    return bool;
});

const allRequiredSelectionsSelected = ref(
    props.requiredSelections.every((selectionId) => {
        if (props.selections.hasOwnProperty(selectionId)) {
            return props.selections[selectionId].length > 0;
        }
        return false;
    }),
);

const allRequiredInnerSelectionsSelected = ref(
    props.menuItem.type !== 'combination' ? true : !$store.form.requiredInnerConfigs ? true : false,
);

watch(
    props.selections,
    (newSelections) => {
        allRequiredSelectionsSelected.value = props.requiredSelections.every((selectionId) => {
            if (newSelections.hasOwnProperty(selectionId)) {
                return newSelections[selectionId].length > 0;
            }
            return false;
        });
    },
    { deep: true },
);
console.log($store.form.requiredInnerConfigs);

watch(
    [() => $store.form.requiredInnerConfigs, () => $store.selectedInnerConfigChoices],
    ([newRequiredInnerConfigs, newSelectedInnerConfigChoices]) => {
        if (newRequiredInnerConfigs !== undefined) {
            console.log(
                'Watcher triggered, requiredInnerConfigs now exists:',
                newRequiredInnerConfigs,
            );

            allRequiredInnerSelectionsSelected.value = newRequiredInnerConfigs.every(
                (selectionId) => {
                    console.log(selectionId);
                    return Object.values(newSelectedInnerConfigChoices).some((choice) => {
                        if (choice && choice.hasOwnProperty(selectionId)) {
                            console.log(choice[selectionId]);
                            return choice[selectionId].length > 0;
                        }

                        return false;
                    });
                },
            );
        }
    },
    { immediate: true, deep: true },
);

const listContainer = ref(null);

const bottomVal = computed(() => listContainer.value?.getBoundingClientRect().bottom ?? 0);
</script>

<template>
    <div ref="listContainer" class="grid grid-cols-1 pb-20 md:grid-cols-2 md:pb-16">
        <!-- left half -->
        <div class="flex justify-start">
            <div class="relative h-[calc(100%+1rem)] w-full" ref="leftSideRef">
                <div class="z-20 aspect-square bg-white md:sticky md:top-40">
                    <Image class="md:pr-6" :src="menuItem.image?.original" />
                </div>
                <!-- <div ref="addToCartRef" :class="`bottom-0 ${buttonPositionAttribute} pb-16`">
                    <div
                        class="hidden flex-col items-center justify-center p-4 text-xl font-semibold uppercase text-palmers-charcoal-grey md:flex"
                    >
                        <div
                            class="flex w-full items-center justify-between p-4"
                            v-if="$store.ready"
                        >
                            <p>Total for this item:</p>
                            <p class="text-3xl font-bold">
                                {{ formatCurrency(totalPriceWithoutChoice + choicePrice) }}
                            </p>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <!-- right half -->
        <div class="flex flex-col" ref="rightContainerRef">
            <div class="z-20 space-y-4 px-4 md:px-0">
                <!-- name -->
                <div class="text-2xl font-semibold uppercase text-palmers-charcoal-grey">
                    {{ menuItem.name }}
                </div>
                <!-- allergen indicators -->
                <div class="relative flex">
                    <template
                        v-for="allergenIndicator in props.menuItem.allergenIndicators"
                        :key="allergenIndicator.id"
                    >
                        <Image
                            :src="allergenIndicator?.image?.preview_url"
                            image-container-class="flex"
                            style="width: 8%"
                            :title="allergenIndicator.label"
                        />
                    </template>
                </div>

                <div class="flex items-center justify-between" v-if="menuItem.type === 'schedule'">
                    <a
                        :href="menuItem.scheduleHref"
                        target="_blank"
                        class="font-bold text-palmers-dragon-fruit supports-hover:hover:underline"
                    >
                        View Schedule
                    </a>

                    <PrintButton
                        theme="pink"
                        title="Print soup schedule"
                        :url="menuItem.scheduleHref"
                    />
                </div>

                <!-- description -->
                <p class="prose">
                    {{ menuItem.description }}
                </p>

                <!-- size selection -->
                <SizeSelectorPill :quantities="quantities" v-if="$store.ready" />
            </div>

            <div class="relative z-10 mt-[20px] w-full">
                <!-- boxes -->
                <AccordionPanel
                    @update:selections="$emit('update:selections', $event)"
                    @update:quantitites="$emit('update:quantities', $event)"
                    @action="accordionAction"
                    :menuItem="menuItem"
                    :item-configurations="menuItem.configurations"
                    :item-combo-item-options="menuItem.comboItemOptions"
                    :quantities="quantities"
                    :selections="selections"
                    :ready="$store.ready"
                    v-if="$store.ready"
                    as="list"
                />

                <div
                    class="divide-y-2 border-x-2 border-b-2 md:divide-y-0"
                    :class="{ 'border-t-2': hasUpgrade }"
                    v-if="$store.ready"
                >
                    <TrailingInputs class="p-4" />

                    <div
                        class="space-y-4 p-4 md:p-0"
                        v-if="menuItem?.type !== 'simple' && showDisplaySummary"
                    >
                        <SelectionSummary
                            :class="menuItem.type !== 'combination' ? 'md:border-t-2 md:p-4' : ''"
                            v-bind="{ menuItem, quantities }"
                        />

                        <!-- total -->
                        <!-- <div
                            class="text-xl font-semibold uppercase text-palmers-charcoal-grey md:hidden"
                        >
                            Total for this item:
                        </div>
                        <div class="text-right text-3xl font-bold md:hidden">
                            {{ formatCurrency(totalPriceWithoutChoice + choicePrice) }}
                        </div> -->
                    </div>
                </div>

                <div class="hidden flex-1 items-center justify-center md:flex" v-if="!$store.ready">
                    <StartOrderButton />
                </div>
            </div>
        </div>
        <FixedItemBar
            :all-required-selections-selected="
                allRequiredSelectionsSelected && allRequiredInnerSelectionsSelected
            "
            :bottom="bottomVal"
            v-if="$store.ready"
        />
        <StartOrderButton v-if="!$store.ready" class="absolute bottom-0 left-0 w-full md:hidden" />
    </div>
</template>
