<script setup>
import { InputGroup } from '@/customer/Components';
import { string } from 'yup';
import { ref, watch } from 'vue';

const contactInfo = ref({
    name: '',
    email: '',
    phone: '',
});

const emit = defineEmits(['allow', 'deny']);

watch(
    () => contactInfo.value,
    (newVal) =>
        emit(newVal.name !== '' && newVal.email !== '' && newVal.phone !== '' ? 'allow' : 'deny'),
    { deep: true },
);
</script>
<template>
    <div class="space-y-2">
        <div class="text-xl font-bold text-palmers-charcoal-grey">Contact Information</div>
        <InputGroup
            class="w-full text-black"
            label-class="text-palmers-charcoal-grey"
            v-model="contactInfo.name"
            label="Full Name"
            name="name"
            :validator="string().required('required')"
            :validate="contactInfo.name"
            error-class="text-[#404041]"
        />
        <div class="grid grid-cols-1 gap-2">
            <InputGroup
                class="w-full text-black"
                label-class="text-palmers-charcoal-grey"
                label="Email Address"
                name="email"
                v-model="contactInfo.email"
                :validate="contactInfo.email"
                :validator="string().required('required').email('Please enter a valid email.')"
                error-class="text-[#404041]"
            />
        </div>
        <div class="grid grid-cols-1 gap-2">
            <InputGroup
                class="w-full text-black"
                label-class="text-palmers-charcoal-grey"
                label="Phone Number"
                name="phone"
                v-model="contactInfo.phone"
                :validate="contactInfo.phone"
                :validator="
                    string().required('required').min(7, 'Please enter a valid phone number.')
                "
                error-class="text-[#404041]"
            />
        </div>
    </div>
</template>
