<script setup>
import { computed } from 'vue';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
});

const modData = computed(() => Object.values(props.item.mods));
const modLabels = computed(() => Object.keys(props.item.mods));
</script>

<template>
    <div class="border-b-2 border-palmers-charcoal-grey/40 py-6 first:pt-0">
        <div
            class="flex items-center justify-between text-xl font-semibold uppercase text-palmers-charcoal-grey"
        >
            <p>{{ item.item_name }}</p>
            <p>${{ item.price.toFixed(2) }}</p>
        </div>
        <p v-if="item.size" class="font-semibold text-palmers-charcoal-grey/80">
            {{ item.size }}
        </p>

        <p class="mt-3 font-semibold text-palmers-charcoal-grey/80">QTY: {{ item.quantity }}</p>
    </div>
</template>
