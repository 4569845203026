<script setup>
const binding = defineModel();

defineProps({
    states: {
        type: Array,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    inputClass: {
        type: String,
        default: null,
    },
});
</script>

<template>
    <select
        class="h-min w-full rounded-full border-2 border-palmers-dragon-fruit px-6 py-3 text-base tracking-widest !text-palmers-charcoal-grey focus:border-palmers-dragon-fruit focus:ring-palmers-dragon-fruit focus:ring-offset-2"
        :class="inputClass"
        name="cc-state"
        :disabled="disabled"
        v-model="binding"
    >
        <template v-for="state in states">
            <option :value="state.full">
                {{ state.full }}
            </option>
        </template>
    </select>
</template>
