import { defineStore } from 'pinia';
import { usePage } from '@inertiajs/vue3';
import { isSchema } from 'yup';
import { get, set } from 'lodash';

export default defineStore('validatorStore', {
    state: () => ({
        errors: {},
        tests: {},
        messages: {
            required: 'This field is required.',
            name: 'Enter Valid Name',
            'cc-number': 'Enter Valid Card Number',
            'cc-zip': 'Enter Valid U.S. Postal Code.',
            'cc-exp': 'Enter Valid Expiration',
            'cc-code': 'Enter Valid Security Code',
            'cc-street': 'Enter Address',
            'cc-city': 'Enter City',
            'cc-state': 'Select State',
            'state-zip': 'Zip Code Is Not Valid In State',
            'email': 'Enter Valid Email',
            'phone': 'Enter Valid Phone Number',
            'checkout-invalid': 'Please correct the errors above before continuing.',
            'checkout-failed': 'Something went wrong when placing your order.',
        },
    }),
    getters: {},
    actions: {
        initialize() {
            console.log('init validator', usePage().props);
            this.errors = usePage().props.errors;
        },
        clear() {
            this.errors = {};
        },
        validate({ attribute, value, validator, attributes = [], test = false }) {
            set(this.tests, [attribute, 'validator'].join('.'), validator);
            set(this.tests, [attribute, 'value'].join('.'), value);
            set(this.tests, [attribute, 'attributes'].join('.'), attributes);
            if (!test) {
                return;
            }

            this.test(attribute);
        },
        remove(attribute) {
            delete this.tests[attribute];
        },
        test(attribute) {
            console.log(this.tests);
            const test = get(this.tests, attribute);
            const notNestedFlag = test.hasOwnProperty('validator', 'value', 'attributes');
            const iterations = notNestedFlag ? 1 : Object.keys(test).length;
            for (let i = 0; i < iterations; i++) {
                const key = iterations === 1 ? attribute : `${attribute}.${[Object.keys(test)[i]]}`;
                const { validator, value, attributes } = get(this.tests, key);

                if (!isSchema(validator)) {
                    throw new Error('validator must be schema from yup.js');
                }

                delete this.errors[key];
                if (attributes && Array.isArray(attributes)) {
                    attributes.forEach((attribute) => {
                        delete this.errors[attribute];
                    });
                }

                return new Promise((resolve, reject) => {
                    validator
                        .validate(value)
                        .then(() => resolve(true))
                        .catch((error) => {
                            const message =
                                get(this.messages, error.message) ??
                                get(this.messages, key) ??
                                get(this.messages, error.type) ??
                                this.setError(key, message ?? error.message);

                            this.errors[key] = message ?? error.message;
                            resolve(false);
                        });
                });
            }
        },
        validateAll() {
            this.clear();
            return new Promise((resolve, reject) => {
                Promise.all(
                    Object.keys(this.tests).map((attribute) => {
                        console.log(attribute);
                        return this.test(attribute);
                    }),
                ).then((results) => {
                    console.log(results);
                    const result = results.every(Boolean);
                    console.log({ result });
                    resolve(result);
                });
            });
        },
        getError(attributes) {
            if (typeof attributes === 'string' || typeof attributes === 'number') {
                return get(this.errors, attributes);
            }

            const errors = [];
            attributes.forEach((attribute) => {
                const error = get(this.errors, attribute);
                if (typeof error !== 'undefined') {
                    errors.push(error);
                }
            });
            return errors[0];
        },
        setErrors(obj = {}) {
            Object.keys(obj ?? {}).forEach((attribute) => {
                const value = get(obj, attribute);
                console.log({ value, attribute });
                this.setError(attribute, value?.[0] ?? value);
            });
        },
        setError(attribute, message) {
            const defaultMessage = get(this.messages, attribute) ?? get(this.messages, message);
            set(this.errors, attribute, defaultMessage ?? message);
        },
    },
});
