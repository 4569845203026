<script setup>
import OverviewItem from './OverviewItem.vue';

import { ref } from 'vue';

const props = defineProps({
    order: {
        type: Object,
        required: true,
    },
    fulfillmentDisplay: {
        type: String,
        required: true,
    },
    isCatering: {
        type: Boolean,
        required: true,
    },
});

const total = props.order.summary_snapshot?.total ?? 0;
const subtotal = props.order.summary_snapshot?.subtotal ?? 0;

const raw = props.order.formatted_order_items;

const ordersByName = ref({});

if (!props.isCatering) {
    raw.forEach((item) => {
        const names = Object.keys(ordersByName.value);
        const cName = item.customer_name ?? 'Other';
        if (names.indexOf(cName) === -1) {
            ordersByName.value[cName] = {
                items: [
                    {
                        is_combo: item.is_combo,
                        item_name: item.item_name,
                        mods: item.mods,
                        price: item.price,
                        quantity: item.quantity,
                        size: item.size,
                        instructions: item.instructions,
                        combo_items: item?.combo_items,
                    },
                ],
            };
        } else {
            ordersByName.value[cName].items.push({
                is_combo: item.is_combo,
                item_name: item.item_name,
                mods: item.mods,
                price: item.price,
                quantity: item.quantity,
                size: item.size,
                instructions: item.instructions,
                combo_items: item?.combo_items,
            });
        }
    });

    for (const customerName in ordersByName.value) {
        const customer = ordersByName.value[customerName];
        const items = customer.items;
        const totalPrice = items.reduce((acc, item) => acc + item.price, 0);
        const ratioToSubtotal = totalPrice / subtotal;
        const finalPrice = ratioToSubtotal * total;
        customer.totalPrice = finalPrice;
    }
}
</script>

<template>
    <div
        class="relative z-20 h-fit w-full overflow-hidden bg-palmers-white sm:rounded-2xl sm:border sm:shadow-md"
    >
        <div
            class="text-md flex w-full flex-col items-center bg-palmers-havarti py-3 font-bold text-palmers-charcoal-grey"
        >
            <p class="uppercase">
                {{ isCatering ? 'Item Summary' : `Order Summary: #${order.id}` }}
            </p>
            <div class="flex items-center gap-x-2 uppercase text-palmers-charcoal-grey/70">
                <p>
                    {{ order.order_items.length }} item{{ order.order_items.length > 1 ? 's' : '' }}
                </p>
                <p>•</p>
                <p>${{ order.summary_snapshot.total.toFixed(2) }}</p>
                <template v-if="!isCatering">
                    <p>•</p>
                    <p>{{ fulfillmentDisplay }}</p>
                </template>
            </div>
        </div>
        <div class="max-h-[50rem] overflow-y-auto shadow-inner">
            <!-- {{ Object.entries(ordersByName) }} -->
            <template v-if="!isCatering">
                <OverviewItem v-for="group in Object.entries(ordersByName)" :group="group" />
            </template>
            <template v-else>
                <div v-for="item in raw" class="border-t px-6 py-4 pb-5 first:border-0">
                    <div
                        class="flex items-center justify-between text-xl font-semibold uppercase text-palmers-charcoal-grey"
                    >
                        <p>{{ item.item_name }}</p>
                        <p>${{ item.price.toFixed(2) }}</p>
                    </div>
                    <p v-if="item.size" class="text-md font-semibold text-palmers-charcoal-grey/80">
                        {{ item.size }}
                    </p>
                    <div class="space-y-2">
                        <ComboItemDifferences v-if="item.is_combo" :comboItems="item.combo_items" />
                        <ItemDifferences v-else-if="item.mods" :mods="item.mods" />
                    </div>
                    <div v-if="item.instructions" class="flex items-start gap-2">
                        <ChatBubbleBottomCenterTextIcon
                            class="mt-[4px] size-4 min-w-4 text-palmers-mush-grey"
                        />
                        <p class="text-[1rem] text-palmers-charcoal-grey">
                            {{ item.instructions }}
                        </p>
                    </div>
                    <p class="mt-3 w-full font-semibold leading-none text-palmers-charcoal-grey/80">
                        QTY: {{ item.quantity }}
                    </p>
                </div>
            </template>
        </div>
        <div>
            <div
                class="grid grid-cols-2 gap-y-3 rounded-b-lg bg-palmers-havarti px-6 py-4 font-semibold uppercase leading-none text-palmers-charcoal-grey/85"
                style="
                    box-shadow:
                        rgba(0, 0, 0, 0.16) 0px 3px 6px,
                        rgba(0, 0, 0, 0.23) 0px 3px 6px;
                "
            >
                <template v-if="!isCatering">
                    <p>Subtotal</p>
                    <p class="flex justify-end">
                        ${{ order.summary_snapshot?.subtotal?.toFixed(2) ?? '0.00' }}
                    </p>
                    <template v-if="order.summary_snapshot.adjustments?.['cc-rate']">
                        <p>Processing Fee</p>
                        <p class="flex justify-end">
                            ${{
                                order.summary_snapshot.adjustments?.['cc-rate']?.amount?.toFixed(
                                    2,
                                ) ?? '0.00'
                            }}
                        </p>
                    </template>
                    <template v-if="order.summary_snapshot.adjustments?.['delivery-fee']">
                        <p>Delivery Fee</p>
                        <p class="flex justify-end">
                            ${{
                                order.summary_snapshot.adjustments?.[
                                    'delivery-fee'
                                ]?.amount?.toFixed(2) ?? '0.00'
                            }}
                        </p>
                    </template>
                    <p>Tax</p>
                    <p class="flex justify-end">
                        ${{ order.summary_snapshot?.adjustments?.tax?.amount.toFixed(2) ?? '0.00' }}
                    </p>
                    <p>Tip</p>
                    <p class="flex justify-end">
                        ${{ order.summary_snapshot?.adjustments.tip?.amount.toFixed(2) ?? '0.00' }}
                    </p>
                </template>
                <template v-if="order.summary_snapshot.adjustments.discount">
                    <p>{{ order.summary_snapshot.adjustments.discount.label }}</p>
                    <p class="flex justify-end">
                        {{ order.summary_snapshot.adjustments.discount.amount < 0 ? '-' : '' }}
                        ${{
                            order.summary_snapshot.adjustments.discount.amount < 0
                                ? (order.summary_snapshot.adjustments.discount.amount * -1).toFixed(
                                      2,
                                  )
                                : order.summary_snapshot.adjustments.discount.amount.toFixed(2)
                        }}
                    </p>
                </template>
                <p class="text-2xl font-bold text-palmers-avocado-green">
                    {{ isCatering ? 'Quote' : 'Order Total' }}
                </p>
                <p class="flex justify-end text-2xl font-bold text-palmers-avocado-green">
                    ${{
                        isCatering
                            ? (order.summary_snapshot.subtotal?.toFixed(2) ?? '0.00')
                            : (order.summary_snapshot.total?.toFixed(2) ?? '0.00')
                    }}
                </p>
            </div>
        </div>
    </div>
</template>
