<script setup>
import { computed } from 'vue';
import * as Cards from '@/common/Utils/cards';
import InputText from '@/customer/Components/InputText.vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: String,
        default: '',
    },
    vendor: {
        type: String,
        default: 'generic',
    },
});

const predictedCardVendor = computed(() => props.vendor);
const cardIcon = computed(() => Cards.cardIcons()['code']);

const needs4Digits = computed(() => predictedCardVendor.value.toLowerCase() === 'amex');

const maskPattern = computed(() => {
    if (needs4Digits.value) {
        return '####';
    }

    return '###';
});

const tokens = {
    '#': { pattern: /[0-9]/, optional: true, transform: (ch) => ch },
};

const inputValue = defineModel();
defineExpose({ modelValue: inputValue });
</script>

<template>
    <div class="relative">
        <InputText
            v-model="inputValue"
            :mask-options="{
                mask: maskPattern,
                tokens,
            }"
            class="pl-16"
        />
        <div class="pointer-events-none absolute inset-y-0 flex items-center pl-6">
            <div>
                <component :is="cardIcon" class="h-5" />
            </div>
        </div>
    </div>
</template>
