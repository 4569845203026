<script setup>
import { ref } from "vue";

const props = defineProps({
    fixed: Boolean,
    top: Boolean,
    right: Boolean,
    bottom: Boolean,
    left: Boolean,
    minimized: Boolean,
    label: String
});

const show = import.meta.env.DEV;
const minimized = ref(props.minimized);
</script>

<template>
    <code
        v-if="show"
        class="prose m-1 max-h-screen overflow-y-auto z-50 block"
        :class="{
            fixed: fixed || top || right || bottom || left,
            'top-0': top,
            'right-0': right,
            'bottom-0': bottom,
            'left-0': left,
        }"
    >
        <pre v-if="!minimized"><slot  /></pre>

        <div class="top-0 right-0 flex items-center space-x-1 leading-none" @click.prevent="minimized = !minimized" :class="{'absolute': !minimized}">
            <span class="bg-gray-200 h-6 flex p-1" :class="{'rounded cursor-pointer': minimized}" v-if="label">{{ label }}</span>
            <button
                class=" bg-gray-200 w-6 h-6"
                :class="[minimized ? 'relative rounded' : 'rounded-tr-md']"
            >

                {{ minimized ? "^" : "-" }}
            </button>
        </div>

    </code>
</template>
