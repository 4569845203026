<script setup>
import ConfirmationLink from '@/common/Components/UI/Alerts/ConfirmationLink.vue';
import { Link } from "@inertiajs/vue3"
import { useLang } from '@/customer/Composables'

const { lang } = useLang()

const props = defineProps({
    'id': { type: Number },
    'label': { type: String },
    'phoneDisplay': { type: String },
})

</script>

<template>
    <div>
        <div class="flex items-center space-x-3">
            <div>{{ label ?? lang.label_default }}:</div>
            <div>{{ phoneDisplay }}</div>
        </div>

        <div class="flex items-center space-x-3">
            <Link :href="route('customer.profile.contacts.edit', id)" class="font-medium underline">{{ lang.edit }}</Link>

            <ConfirmationLink v-slot="{show}" :href="route('customer.profile.contacts.destroy', id)" method="DELETE">
                <button @click.prevent="show" class="text-palmers-burnt-orange font-medium underline">{{ lang.delete }}</button>
            </ConfirmationLink>
        </div>
    </div>
</template>
