<script setup>
import Form from '@/customer/Pages/Profile/Partials/Form.vue';
import InputGroup from '@/customer/Components/InputGroup.vue';
import PhoneInput from '@/customer/Components/UI/Input/MaskedInputs/PhoneInput.vue';
import { useLang } from '@/customer/Composables';

const { lang } = useLang();

defineEmits(['submit']);

defineProps({
    form: { type: Object },
});
</script>

<template>
    <Form v-bind="{ form }" @submit="$emit('submit')">
        <InputGroup
            id="label"
            v-model="form.label"
            :label="lang.label"
            name="label"
            :error-message="form.errors.label"
            type="text"
            class="mt-1 block w-full"
            autofocus
            autocomplete="label"
            :placeholder="lang.label_placeholder"
        />

        <InputGroup
            id="number"
            v-model="form.number"
            :label="lang.phone"
            name="number"
            :error-message="form.errors.number"
            :type="PhoneInput"
            class="mt-1 block w-full"
            autocomplete="phone"
        />
    </Form>
</template>
