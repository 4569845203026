<script setup>
import { ref, watch } from 'vue';
import { useCartStore } from '@/customer/Composables';

const cart = useCartStore();

const showBanner = ref(false);

watch(
    () => cart.getCartAdjustmentLabel('discount'),
    (value) => {
        if (value !== null) {
            showBanner.value = true;
            setTimeout(() => {
                showBanner.value = false;
            }, 2000);
        } else {
            showBanner.value = false;
        }
    },
);
</script>

<template>
    <div
        class="absolute z-40 flex h-auto w-full justify-center"
        :class="[showBanner ? '' : 'pointer-events-none']"
    >
        <div
            class="flex h-20 w-[calc(100%-2rem)] items-center justify-between rounded-md bg-palmers-avocado-green p-4 shadow-md transition-all duration-[400ms] ease-in-out"
            :class="[showBanner ? 'translate-y-[1rem]' : 'translate-y-[-5rem]']"
        >
            <p class="text-2xl font-semibold text-white">Applied Discount</p>
            <button
                class="rounded-full bg-palmers-avocado-light px-3 py-1 text-xl font-semibold text-white active:opacity-60 supports-hover:hover:shadow-inner"
                @click="showBanner = false"
            >
                Close
            </button>
        </div>
    </div>
</template>
