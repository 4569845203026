import button from './button';
import calendar from './calendar';
import datatable from './datatable';
import dropdown from './dropdown';
import global from './global';
import inputnumber from './inputnumber';
import inputtext from './inputtext';
import paginator from './paginator';
import ripple from './ripple';
import tag from './tag';
import datepicker from './datepicker';

export default {
    global,
    directives: {
        ripple,
    },

    //forms
    dropdown,
    inputnumber,
    inputtext,
    calendar,

    //buttons
    button,

    //data
    paginator,
    datatable,

    //misc
    tag,
    datepicker,
};
