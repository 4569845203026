<script setup>
import { ref, watch } from 'vue';
import { ChevronDownIcon, PlusIcon } from '@heroicons/vue/24/outline';
import { TransitionRoot } from '@headlessui/vue';
import { computed, getCurrentInstance } from 'vue';

const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    expanded: {
        type: Boolean,
        default: false,
    },
    handleAccordion: {
        type: Function,
        required: true,
    },
    id: {
        type: String,
    },
    isUpgrade: {
        type: Boolean,
    },
    noSpace: {
        type: Boolean,
        default: false,
    }
});

const showContent = ref(props.expanded);

const togglePanel = (event) => {
    showContent.value = !showContent.value;
    props.handleAccordion();
};

const computedAccordionId = computed(() => props.id ?? `accordion-${getCurrentInstance().uid}`);

watch(
    () => props.expanded,
    (newValue, oldValue) => {
        showContent.value = newValue;
    },
);
</script>

<template>
    <div class="overflow-hidden">
        <button
            @click.prevent="togglePanel"
            class="flex w-full flex-row items-center justify-between p-4 font-semibold uppercase text-palmers-charcoal-grey"
            :data-accordion="computedAccordionId"
        >
            <slot name="label"  v-bind="{ label }">
                <div :class="noSpace ? 'space-y-4' : ''">{{ label }}</div>
            </slot>
            <PlusIcon
                v-if="isUpgrade"
                class="h-6 w-6 transform text-palmers-avocado-green transition duration-300"
                :class="showContent ? '-rotate-90' : ''"
            />
            <ChevronDownIcon
                v-else
                class="h-6 w-6 transform text-palmers-dragon-fruit transition duration-300"
                :class="showContent ? '-rotate-180' : ''"
            />
        </button>
        <TransitionRoot
            :show="showContent"
            enter="transition-all ease-in-out duration-300 transform"
            enter-from="max-h-0"
            enter-to="max-h-screen"
            leave="transition-all ease-in-out duration-100 transform"
            leave-from="max-h-screen"
            leave-to="max-h-0"
        >
            <div class="px-4 pb-4" :class="noSpace ? '' : 'space-y-4'" :data-accordion-content="computedAccordionId">
                <slot></slot>
            </div>
        </TransitionRoot>
    </div>
</template>
