<script setup>
import { computed } from 'vue';
import { Head, Link } from '@inertiajs/vue3';
import AccountSettingsLayout from '@/customer/Layouts/AccountSettingsLayout.vue';
import ContactCard from '../Partials/ContactCard.vue';
import { default as Button } from '@/customer/Components/BaseButton.vue';
import { useLang } from '@/customer/Composables';

const { lang } = useLang();

const props = defineProps({
    contacts: {
        type: Object,
    },
});

const contacts = computed(() => props.contacts.data);
</script>

<template>
    <AccountSettingsLayout :title="lang.title_index" class="pb-6 md:pb-0">
        <Head title="Profile" />

        <template v-for="contact in contacts">
            <ContactCard v-bind="{ ...contact }" />
        </template>

        <Button :as="Link" :href="route('customer.profile.contacts.create')">
            {{ lang.add }}</Button
        >
    </AccountSettingsLayout>
</template>
