<script setup>
import { computed, ref } from 'vue';
import { useCartStore } from '@/customer/Composables';
import CartItemDifference from './CartItemDifference.vue';

const emit = defineEmits(['remove', 'edit', 'updateQuantity']);
const cart = useCartStore();

const props = defineProps({
    differences: { type: Array, required: true },
    innerConfigDifferences: { type: Array, required: false },
});
console.log(props);

const additions = computed(() => props.differences?.filter((x) => x.delta > 0));
const removals = computed(() => props.differences?.filter((x) => x.delta <= 0));
const differences = computed(() => [].concat(removals.value).concat(additions.value));
const innerAdditions = computed(() => props.innerConfigDifferences?.filter((x) => x.delta > 0));
const innerRemovals = computed(() => props.innerConfigDifferences?.filter((x) => x.delta <= 0));
const innerDifferences = computed(() => [].concat(innerRemovals.value).concat(innerAdditions.value));

const showingMore = ref(false);
const hideAfter = ref(3);
const shouldShowMore = computed(() => differences.value.filter(item => item !== undefined)?.length + innerDifferences.value.filter(item => item !== undefined)?.length  > hideAfter.value);

const sortedDifferences = computed(() => {
      if (differences?.value?.length > 0 && differences.value[0]?.comboItemOption !== null) {
        return differences.value.slice().sort((a, b) => {
          if (a.comboItemOption && b.comboItemOption) {
            return a.comboItemOption.sort_order - b.comboItemOption.sort_order;
          }
          return 0;
        });
      } else {
        return differences.value;
      }
    });
</script>

<template>
    <template v-for="(difference, index) in sortedDifferences">
        <template v-if="index < hideAfter || showingMore">
            <CartItemDifference v-bind="{ difference }" v-bind:key="difference?.item_id" />
            <template v-for="(innerDifference, index) in innerDifferences">
              <template v-if="index < hideAfter || showingMore">
                <CartItemDifference class="ml-5" v-if="innerDifference?.item_id == difference?.item_id || difference?.scheduledChoices?.includes(innerDifference.item_id)" :difference="innerDifference" v-bind:key="index"/>
              </template>
            </template>
        </template>
    </template>
    <template v-if="!showingMore && shouldShowMore">
        <button class="font-semibold" @click="showingMore = true">Show more</button>
    </template>
    <template v-if="showingMore">
        <button class="font-semibold" @click="showingMore = false">Show less</button>
    </template>
</template>
