<script setup>
import { computed, inject, useSlots } from 'vue';
import { switcherProps } from '@/customer/Components/Order/Internal/props';
import Dropdown from '@/customer/Components/Dropdown.vue';
import { orderState } from '@/customer/Utils/keys';

const $slots = useSlots();

const props = defineProps({
    ...switcherProps,
    isCheckout: {
        type: Boolean,
        default: false,
    },
    align: {
        type: String,
        default: 'center',
    },
    checkoutModal: {
        type: Boolean,
        default: false,
    },
});

const disabled = computed(() => props.dropdownDisabled);

const emit = defineEmits(['responsive-dialog']);

const contentSlot = computed(() => $slots['dropdown.content']);

const order = inject(orderState);
</script>

<template>
    <Dropdown
        content-classes="pt-3 w-auto bg-white overflow-hidden -translate-x-[26%] bg-palmers-white border-2 border-white/20"
        popper-classes="top-1"
        :align="align"
        arrow
        @responsive-dialog="$emit('responsive-dialog', contentSlot)"
        :disabled="disabled"
        :is-checkout="isCheckout"
        @open-modal="$emit('open-modal')"
        :checkout-modal="checkoutModal"
    >
        <template #trigger>
            <button
                class="flex flex-row items-center space-x-3 rounded-md border border-transparent p-1 px-3"
                :class="[
                    buttonClass,
                    {
                        'supports-hover:hover:bg-white/20 cursor-pointer focus:border-white/50 focus:bg-palmers-himalayan-pink/40':
                            !disabled,
                        'cursor-default': disabled,
                    },
                ]"
            >
                <slot name="icon">
                    <component :is="icon" :class="iconClass" />
                </slot>
                <slot name="label">
                    <div v-if="label">{{ label }}</div>
                </slot>
            </button>
        </template>
        <template #content>
            <div class="inline-block w-full space-y-3 text-black">
                <slot name="dropdown.content" />
            </div>
        </template>
    </Dropdown>
</template>
