<script setup>
import Accordion from '@/customer/Components/UI/Accordion/Accordion.vue';
import {
    CheckboxGroupInput,
    CheckboxGroupInputSingle,
    RadioGroupInput,
} from '@/customer/Components';
import { EmptyBoundary } from '@/common/Components';
import { InputGroup } from '@/company/Components';
import { computed } from 'vue';
import { mapValues } from 'lodash';
import { useMenuItemStore, useOrderStore } from '@/customer/Composables';
import { arrToObj } from '@/common/Utils';

const emit = defineEmits(['update:selections', 'update:quantities']);

const $store = useMenuItemStore();
const orderStore = useOrderStore();

const props = defineProps({
    configuration: {
        type: Object,
        default: () => ({}),
    },
    selections: {
        type: [Object, Array],
        default: () => [],
    },
    quantities: {
        type: [Object, Array],
        default: () => [],
    },
    expanded: {
        type: Boolean,
        required: true,
    },
    handleAccordion: {
        type: Function,
        required: true,
    },
    itemWithin: {
        type: Boolean,
        required: false,
        default: false,
    },
    itemWithinWithin: {
        type: Boolean,
        required: false,
        default: false,
    },
    withinWithinConfigId: {
        type: Number,
        required: false,
        default: 0,
    },
    parentComboItem: {
        type: Number,
        required: false,
        default: 0,
    },
    parentComboItemOption: {
        type: Number,
        required: false,
        default: 0,
    },
    choiceId: {
        type: Number,
        required: false,
        default: 0,
    },
    adderPricingSize: {
        type: Number,
        required: false,
        default: 0,
    },
    name: {
        type: String,
    },
    hideUpgrades: {
        type: Boolean,
    },
});

const SelectionComponent = computed(() => {
    const { selection_type, required } = props.configuration;

    if (selection_type === 'single') {
        return required ? RadioGroupInput : CheckboxGroupInputSingle;
    } else {
        return CheckboxGroupInput;
    }
});

function flattenNestedObject(obj) {
    const result = {};

    for (const outerKey in obj) {
        if (obj.hasOwnProperty(outerKey)) {
            result[outerKey] = {};
            for (const innerKey in obj[outerKey]) {
                if (obj[outerKey].hasOwnProperty(innerKey)) {
                    const nestedObj = obj[outerKey][innerKey];
                    for (const key in nestedObj) {
                        if (nestedObj.hasOwnProperty(key)) {
                            result[outerKey][key] = nestedObj[key];
                        }
                    }
                }
            }
        }
    }

    return result;
}

const configChoices = computed(() => {
    return props.configuration.choices.filter((choice) => {
        if (choice.activeLocations) {
            return choice.activeLocations.includes(orderStore.selectedLocation?.id);
        }
        return true;
    });
});

const handleSelections = ($event) => {
    console.log($event);
    if (props.itemWithin) {
        const quantities = $store.getInnerConfigQuantities(props.choiceId, props.configuration);
        const newQuantities = mapValues(arrToObj($event), (id) => Math.max(quantities[id] ?? 1, 1));
        $store.setInnerConfigQuantities(props.choiceId, props.configuration.id, newQuantities);
    } else if (props.itemWithinWithin) {
        const quantities = $store.getInnerConfigQuantities(
            props.withinWithinConfigId,
            props.configuration,
        );
        const newQuantities = mapValues(arrToObj($event), (id) => Math.max(quantities[id] ?? 1, 1));
        $store.setInnerConfigQuantities(
            props.withinWithinConfigId,
            props.configuration.id,
            newQuantities,
        );

        let newOuterQuantity = {};
        newOuterQuantity[props.choiceId] = {
            ...$store.innerConfigQuantities[props.withinWithinConfigId],
        };
        console.log(newOuterQuantity);
        console.log(flattenNestedObject(newOuterQuantity));
        $store.setConfigQuantities(props.parentComboItemOption, newOuterQuantity);
    } else {
        const quantities = $store.getConfigQuantities(props.configuration.id);
        const newQuantities = mapValues(arrToObj($event), (id) => Math.max(quantities[id] ?? 1, 1));
        console.log(props.configuration.id, newQuantities);
        $store.setConfigQuantities(props.configuration.id, newQuantities);
    }
};

const handleQuantities = ($event) => {
    if (props.itemWithin) {
        const quantities = $store.getInnerConfigQuantities(props.choiceId, props.configuration);
        const newQuantities = Object.assign(quantities, $event);
        $store.setInnerConfigQuantities(props.choiceId, props.configuration.id, newQuantities);
    } else if (props.itemWithinWithin) {
        const quantities = $store.getInnerConfigQuantities(
            props.withinWithinConfigId,
            props.configuration,
        );
        const newQuantities = mapValues(arrToObj($event), (id) => Math.max(quantities[id] ?? 1, 1));
        $store.setInnerConfigQuantities(
            props.withinWithinConfigId,
            props.configuration.id,
            newQuantities,
        );
    } else {
        const quantities = $store.getConfigQuantities(props.configuration.id);
        const newQuantities = Object.assign(quantities, $event);
        $store.setConfigQuantities(props.configuration.id, newQuantities);
    }
};

const accordionLabel = computed(() => {
    if (props.itemWithin || props.itemWithinWithin) {
        if (props.adderPricingSize) {
            const optionAdditionalPrice = props.configuration.price.find(
                (priceObj) => priceObj.size.id === props.adderPricingSize,
            );
            console.log(props.configuration);
            if (
                optionAdditionalPrice?.price?.formatted == '$0.00' &&
                (props.configuration.min == 0 || props.configuration.min == null)
            ) {
                return props.configuration.label;
            } else {
                return props.configuration.label + ' +' + optionAdditionalPrice?.price?.formatted;
            }
        } else {
            return props.configuration.label;
        }
    } else {
        const optionAdditionalPrice = props.configuration.price.find(
            (priceObj) => priceObj.size.id === $store.form.size,
        );

        if (optionAdditionalPrice?.price?.formatted == '$0.00') {
            return props.configuration.label;
        } else {
            if (props.configuration.min == 0 || props.configuration.min == null) {
                return props.configuration.label + ' +' + optionAdditionalPrice?.price?.formatted;
            } else {
                return props.configuration.label;
            }
        }
    }
});

const isUpgrade = computed(() =>
    props.configuration.configuration_type
        ? props.configuration.configuration_type === 'upgrade'
        : props.configuration.type === 'upgrade',
);
const maxQuantityReached = computed(() =>
    props.configuration.max == null
        ? false
        : props.itemWithin || props.itemWithinWithin
          ? $store.getInnerConfigQuantitySum(props.configuration, props.choiceId) >=
            props.configuration.max
          : $store.getConfigQuantitySum(props.configuration.id) >= props.configuration.max,
);
</script>

<template>
    <Accordion
        :is-upgrade="isUpgrade"
        :label="accordionLabel"
        :expanded="expanded"
        :handleAccordion="props.handleAccordion"
        :id="configuration.id"
        class="border-x-2"
        :class="[isUpgrade ? 'my-2 border-t-2' : '', !hideUpgrades ? 'border-b-2' : '']"
    >
        <template #label="{ label }">
            <div class="flex items-center space-x-2">
                <span
                    >{{ label }} <span v-if="configuration.required" class="required">*</span></span
                >
            </div>
        </template>
        <template #default>
            <div v-if="configuration.description" class="text-gray-600">
                {{ configuration.description }}
            </div>
            <EmptyBoundary v-model="SelectionComponent" message="Unknown selection type">
                <InputGroup
                    :label="true"
                    :type="SelectionComponent"
                    :options="configChoices"
                    value-key="id"
                    label-key="choice.name"
                    :model-value="selections"
                    @update:model-value="handleSelections"
                    :quantities="quantities"
                    @update:quantities="handleQuantities"
                    :name="name"
                    :adder-pricing-size="adderPricingSize"
                    :max-quantity-reached="maxQuantityReached"
                />
            </EmptyBoundary>
        </template>
    </Accordion>
</template>
