<script setup></script>

<template>
    <div>
        <div class="flex items-start justify-between pr-4">
            <div>
                <p class="text-xl font-bold uppercase text-palmers-charcoal-grey">
                    In-Store Options
                </p>
                <p class="font-bold text-palmers-charcoal-grey">Pickup Time</p>
            </div>
            <p
                class="cursor-pointer rounded-full bg-palmers-mush-grey bg-opacity-30 px-3 py-1 font-semibold uppercase text-palmers-charcoal-grey supports-hover:hover:bg-opacity-60"
                v-if="!readyAsap"
                @click="setToAsap"
            >
                ASAP
            </p>
        </div>
        <div>
            <div class="mt-3 grid grid-cols-2 gap-2">
                <div>
                    <InputGroup
                        id="scheduledDate"
                        name="scheduledDate"
                        :label="null"
                        class="w-full text-black"
                        label-class="text-white"
                        :type="DatePicker"
                        placeholder="Today"
                        v-model="data.date"
                        :required="!readyAsap"
                    />
                </div>
                <div
                    class="relative"
                    :class="{
                        'cursor-not-allowed': showTimeTip,
                    }"
                    @mouseenter="() => checkTip('enter')"
                    @mouseleave="() => checkTip('leave')"
                >
                    <div
                        v-if="showTimeTip"
                        class="absolute bottom-0 left-0 right-0 top-0 z-20 w-full rounded-full p-1"
                    >
                        <p
                            class="flex h-full select-none items-center justify-center rounded-full bg-palmers-charcoal-grey font-semibold uppercase"
                        >
                            Select a date first
                        </p>
                    </div>
                    <InputGroup
                        id="scheduledTime"
                        name="scheduledTime"
                        class="w-full text-white"
                        label-class="text-white"
                        :label="null"
                        :type="TimePicker"
                        placeholder="ASAP"
                        v-model="data.time"
                        :required="!readyAsap"
                        :disabled="data.date === null"
                        formatted-text-color="text-black"
                    />
                </div>
                <p v-if="isPastDate" class="text-palmers-dragon-fruit">Enter a Future Time</p>
            </div>
        </div>
    </div>
</template>
