<script setup>
import Checkbox from '@/customer/Components/UI/Input/Checkboxes/CheckboxInput.vue';
import InputError from '@/customer/Components/InputError.vue';
import InputLabel from '@/customer/Components/InputLabel.vue';
import Button from '@/customer/Components/BaseButton.vue';
import InputText from '@/customer/Components/InputText.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import AppIcon from '@/customer/Components/AppIcon.vue';
import { InputPassword } from '@/customer/Components';
import InputGroup from '@/customer/Components/InputGroup.vue';

defineProps({
    status: {
        type: String,
        default: null,
    },
    errors: {
        type: Object,
        default: () => ({}),
    },
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.post(route('customer.login'), {
        onFinish: () => form.reset('password'),
        onError: () => {},
        replace: true,
    });
};
</script>

<template>
    <Head title="Log in" />

    <div
        class="mx-auto my-16 max-w-screen-xl grid-cols-2 gap-0 divide-y divide-neutral-700 px-2 md:grid md:divide-y-0 md:px-0"
    >
        <div class="border-r-neutral-700 pb-12 md:border-r md:px-0 md:pb-0 md:pr-16 lg:pr-24">
            <div v-if="status" class="mb-4 text-sm font-medium text-green-600">
                {{ status }}
            </div>

            <h2
                class="mb-1 flex w-full items-center justify-center font-sans text-2xl font-bold uppercase"
            >
                <AppIcon icon="BagIcon" class="h-8 w-10" />
                <span>Start Your Order</span>
            </h2>

            <h3 class="text-center text-lg">Sign in to start adding yummy food.</h3>

            <form @submit.prevent="submit" class="mt-6">
                <div>
                    <!-- <InputLabel for="email" value="Email" /> -->

                    <InputGroup
                        id="email"
                        v-model="form.email"
                        type="email"
                        class="mt-1 block w-full"
                        placeholder="User Name"
                        required
                        autofocus
                        autocomplete="username"
                    />

                    <InputError class="mt-2" :message="form.errors.email" />
                </div>

                <div class="mt-4">
                    <!-- <InputLabel for="password" value="Password" /> -->

                    <InputPassword
                        id="password"
                        placeholder="Password"
                        v-model="form.password"
                        class="mt-2 block w-full"
                        required
                        autocomplete="current-password"
                    />

                    <InputError class="mt-2" :message="form.errors.password" />
                </div>
                <ul class="mt-4 list-inside list-disc text-sm text-red-600">
                    <div v-for="error in errors.error" class="whitespace-pre">
                        <li>{{ error }}</li>
                    </div>
                </ul>
                <div class="mt-4 block">
                    <Checkbox :checked="form.remember" :value="form.remember">
                        <template #label>
                            <span class="ml-2 text-sm text-gray-600"> Remember me </span>
                        </template>
                    </Checkbox>
                </div>

                <div class="mt-4 grid grid-flow-col align-top">
                    <div class="flex h-14 items-center place-self-start">
                        <Link
                            :href="route('customer.password.request')"
                            class="rounded-md text-sm font-semibold text-neutral-700 underline focus:outline-none focus:ring-2 focus:ring-palmers-dragon-fruit focus:ring-offset-2 supports-hover:hover:text-gray-900"
                        >
                            Forgot your password?
                        </Link>
                    </div>

                    <div class="flex h-14 items-center place-self-end">
                        <Button
                            :class="{ 'opacity-25': form.processing }"
                            :disabled="form.processing"
                        >
                            Log in
                        </Button>
                    </div>
                </div>
            </form>
        </div>

        <div class="space-y-6 pt-12 md:pl-16 md:pt-0 lg:pl-24">
            <h2 class="font-sans text-2xl font-bold uppercase leading-9">
                Don't have an account?<br />
                Sign up now!
            </h2>

            <div>
                <h3 class="mb-1 text-base font-bold">Here's some benefits to having an account:</h3>

                <ul
                    class="list-outside list-disc pl-6 leading-loose marker:text-palmers-dragon-fruit"
                >
                    <li>Customize orders and save your favorites</li>
                    <li>Easily place group orders online</li>
                    <li>Enjoy exclusive discounts and loyalty benefits</li>
                    <li>Get a free birthday reward on us!</li>
                </ul>
            </div>

            <Button
                as="a"
                class="ml-0"
                :class="{ 'opacity-25': form.processing }"
                :disabled="form.processing"
                :href="route('customer.register')"
            >
                Register
            </Button>
        </div>
    </div>
</template>
