<script setup>
import { Head, useForm, usePage } from '@inertiajs/vue3';
import AccountSettingsLayout from '@/customer/Layouts/AccountSettingsLayout.vue';
import ContactForm from '@/customer/Pages/Profile/Partials/ContactForm.vue';
import { computed } from 'vue';
import { useLang } from '@/customer/Composables';

const { lang } = useLang();

const props = defineProps({
    contact: { type: Object },
});

const contact = computed(() => props.contact);

const form = useForm(route().current(), {
    label: contact.value.label,
    number: contact.value.phoneDisplay,
});

const submit = () => {
    const url = route('customer.profile.contacts.update', contact.value);

    form.patch(url, {
        preserveScroll: true,
        preserveState: true,
    });
};
</script>

<template>
    <AccountSettingsLayout class="-mt-6 pb-6 md:pb-0">
        <Head title="Profile" />

        <h2 class="text-lg font-bold uppercase">Contact Number</h2>

        <ContactForm v-bind="{ form }" @submit="submit" />
    </AccountSettingsLayout>
</template>
