<script setup>
import { defineAsyncComponent } from "vue";

const props = defineProps({
    icon: {
        type: String,
        default: null,
    },
});

const asyncIcon = defineAsyncComponent(() =>
    import(`./Icons/${props.icon}.vue`),
);
</script>

<template>
    <component :is="asyncIcon" v-if="icon" />
</template>
