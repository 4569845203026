<script setup>
import { Mask } from "maska";
import { computed } from "vue";
import InputText from "@/customer/Components/InputText.vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
        default: "",
    },
    mask: {
        type: String,
        default: null,
    },
    tokens: {
        type: Object,
        default: null,
    },
    placeholder: {
        type: String,
        default(propsRaw) {
            return propsRaw.mask;
        },
    },
});

const masker = computed(
    () =>
        new Mask({
            mask: props.mask,
            tokens: props.tokens,
        }),
);

const inputValue = computed({
    get: () => {
        return masker.value.masked(props.modelValue);
    },
    set: (value) => {
        emit("update:modelValue", value);
    },
});
</script>

<template>
    <InputText
        v-bind="{ ...$attrs, placeholder: placeholder || props.mask }"
        v-model.lazy="inputValue"
    />
</template>
