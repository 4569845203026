<script setup>
import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
import { SelectionSummary, TrailingInputs, AccordionPanel } from '.';
import { Image } from '@/customer/Components';
import { useMenuItemStore } from '@/customer/Composables';
import StartOrderButton from './StartOrderButton.vue';
import SizeSelectorPill from './SizeSelectorPill.vue';

const $store = useMenuItemStore();

const props = defineProps({
    parentComboItemOption: { type: Number },
    parentComboItem: { type: Number },
    choiceId: { type: Number },
    adderPricingSize: { type: Number },
    menuItem: { type: Object },
    price: { type: String },
    name: { type: String, default: null },
    instructions: { type: String, default: null },
    innerConfigSelections: { type: Object, default: () => ({}) },
    innerConfigQuantities: { type: Object, default: () => ({}) },
    hideUpgrades: { type: Boolean, default: true },
    requiredSelections: { type: Array, default: [] },
});

const showDisplaySummary = ref(props.menuItem.displaySummary ?? false);

// initialize refs related to resizing addToCart button
const buttonPositionAttribute = ref('fixed');
const addToCartRef = ref(null);
const leftSideRef = ref(null);

// callback to position addToCart button depending on position (element's width as fixed isn't bound to parent like absolute)
let wasFixed = false;
const setAddToButtonCartWidth = () => {
    if (leftSideRef.value && addToCartRef.value) {
        const rect = leftSideRef.value.getBoundingClientRect();
        if (buttonPositionAttribute.value === 'fixed') {
            addToCartRef.value.style.left = rect.left + 'px';
            addToCartRef.value.style.width = rect.width + 'px';
            wasFixed = true;
        } else if (wasFixed) {
            wasFixed = false;
            addToCartRef.value.style.left = 0;
            addToCartRef.value.style.width = rect.width + 'px';
        }
    }
};

const rightContainerRef = ref(null);
const checkHeight = () => {
    if (
        rightContainerRef.value &&
        rightContainerRef.value.getBoundingClientRect().bottom + 16 >= window.innerHeight
    ) {
        buttonPositionAttribute.value = 'fixed';
    } else {
        buttonPositionAttribute.value = 'absolute';
    }
    setAddToButtonCartWidth();
};

/**
 * since the addToCart button isn't necessarily mounted on mount
 * because it waits for $store.ready to resolve to true
 * watch the ref for when the element mounts and call width function
 */
watch(
    () => addToCartRef.value,
    (newVal) => {
        if (newVal) {
            setAddToButtonCartWidth();
        }
    },
    { immediate: true, deep: true },
);

/**
 * on mount, get DOM Rect for page header, initiate flow with checkHeight
 * add eventListeners to scroll and resize to maintain UI
 */
onMounted(() => {
    checkHeight();
    window.addEventListener('scroll', checkHeight);
    window.addEventListener('resize', setAddToButtonCartWidth);
});

// clean up eventListeners
onUnmounted(() => {
    window.removeEventListener('scroll', checkHeight);
    window.removeEventListener('resize', setAddToButtonCartWidth);
});

const accordionAction = () => {
    setTimeout(() => {
        checkHeight();
    }, 100);
};

const choicePrice = computed(() => $store.totalChoicePrice + $store.totalInnerChoicePrice);
const totalPriceWithoutChoice = computed(() => $store.price.raw);
const hasUpgrade = computed(() => {
    if (props.menuItem.type == 'simple') {
        return true;
    }

    let bool = false;

    if (props.menuItem.type == 'combination') {
        props.menuItem.comboItemOptions?.forEach((item) => {
            item.type == 'upgrade' ? (bool = true) : null;
        });
    } else {
        props.menuItem.configurations?.forEach((item) =>
            item.configuration_type === 'upgrade' ? (bool = true) : null,
        );
    }
    return bool;
});
</script>

<template>
    <div class="grid grid-cols-1">
        <div class="flex flex-col" ref="rightContainerRef">
            <div class="relative z-10 w-full pt-16">
                <!-- boxes -->
                <AccordionPanel
                    @update:selections="$emit('update:selections', $event)"
                    @update:quantities="$emit('update:quantities', $event)"
                    @action="accordionAction"
                    :menuItem="menuItem"
                    :hide-upgrades="hideUpgrades"
                    :item-configurations="menuItem.configurations"
                    :item-combo-item-options="menuItem.comboItemOptions"
                    :quantities="innerConfigQuantities"
                    :selections="innerConfigSelections"
                    :ready="$store.ready"
                    as="list"
                    v-if="$store.ready"
                    :item-within-within="true"
                    :parent-combo-item="parentComboItem"
                    :parent-combo-item-option="parentComboItemOption"
                    :choice-id="choiceId"
                    :adder-pricing-size="adderPricingSize"
                />

                <div
                    class="divide-y-2 border-x-1 border-b-1 md:divide-y-0"
                    :class="{ 'border-t-2': hasUpgrade }"
                    v-if="$store.ready"
                >
                    
                </div>
            </div>
        </div>
    </div>
</template>
