<script setup>
import { shallowRef, ref, watch, computed } from 'vue';
import ShoppingStoreIcon from '@/customer/Components/Icons/ShoppingStoreIcon.vue';
import DeliveryIcon from '@/customer/Components/Icons/DeliveryIcon.vue';
import { useOrderStore } from '@/customer/Composables';

const order = useOrderStore();

const fulfillmentTypes = shallowRef([
    {
        type: 'carryout',
        label: 'In-Store',
        icon: ShoppingStoreIcon,
    },
    {
        type: 'delivery',
        label: 'Delivery',
        icon: DeliveryIcon,
    },
]);

const selectedType = ref(order.form.data().fulfillment_type);

watch(
    () => selectedType.value,
    (value) => {
        order.updateFulfillment(value);
    },
);
</script>

<template>
    <div class="flex w-full flex-col items-start justify-center p-4">
        <div class="flex items-start justify-between pr-4">
            <div>
                <p class="font-bold leading-none text-palmers-charcoal-grey">Fulfillment Type</p>
            </div>
        </div>
        <div class="mt-4 w-full items-center space-y-3">
            <div
                v-for="option in fulfillmentTypes"
                @click="selectedType = option.type"
                :class="[
                    selectedType === option.type
                        ? 'border-2 border-palmers-avocado-green'
                        : 'cursor-pointer border supports-hover:hover:bg-neutral-100',
                ]"
                class="flex h-[3.3rem] w-full items-center justify-between rounded-full bg-white px-5"
            >
                <p class="text-gray-600">{{ option.label }}</p>
                <component class="mr-1 size-7 text-palmers-dragon-fruit" :is="option.icon" />
            </div>
        </div>
    </div>
</template>
