<script setup>
import { BaseButton, Image } from '@/customer/Components';
import { Link } from '@inertiajs/vue3';
import { ChevronRightIcon } from '@heroicons/vue/24/outline';
import { ref, onMounted, onUnmounted } from 'vue';

const props = defineProps({
    menu: {
        type: Object,
    },
});

const menuItemText = ref(null);
const offset = ref(0);

const setOffset = () => {
    const elLength = menuItemText.value.getBoundingClientRect().width;
    offset.value = elLength + 45;
};

onMounted(() => {
    setOffset();
    window.addEventListener('resize', setOffset);
});

onUnmounted(() => window.removeEventListener('resize', setOffset));
</script>

<template>
    <li
        class="relative hidden aspect-square flex-col items-center justify-between space-y-6 p-6 md:flex"
    >
        <div class="absolute inset-0">
            <div
                v-if="menu.badge"
                class="absolute -right-6 top-0 z-20 flex aspect-square max-w-[4rem] -rotate-12 items-center overflow-clip rounded-full bg-palmers-avocado-green p-1 text-center text-sm font-semibold leading-tight text-black"
            >
                {{ menu.badge }}
            </div>

            <Image :src="menu.image?.original" :href="menu.href" />
        </div>

        <BaseButton
            class="absolute bottom-4 z-10 mx-4 mt-auto text-center"
            :as="Link"
            :href="menu.href"
        >
            {{ menu.name }}
        </BaseButton>
    </li>
    <li class="block w-full md:hidden">
        <Link
            class="flex items-center justify-between gap-x-6 bg-white px-5 py-1 active:bg-neutral-100 supports-hover:hover:bg-white md:px-9 md:supports-hover:hover:bg-gray-50"
            :href="menu.href"
        >
            <div class="mr-3 flex items-center justify-start gap-3">
                <div class="w-fit text-lg font-semibold uppercase">
                    <p ref="menuItemText" class="w-fit">
                        {{ menu.name }}
                    </p>
                </div>

                <div
                    v-if="menu.badge"
                    class="flex items-center justify-center overflow-clip rounded-full bg-palmers-avocado-green px-3 py-[.4rem] text-xs font-semibold uppercase leading-tight text-palmers-black"
                    :style="`left: ${offset}px`"
                >
                    <p class="max-w-[8rem] text-center">{{ menu.badge }}</p>
                </div>
            </div>

            <div class="h-16 w-[1px] opacity-0 md:h-48"></div>

            <ChevronRightIcon class="size-5 stroke-[3] text-palmers-dragon-fruit" />
        </Link>
    </li>
</template>
