import { usePage } from '@inertiajs/vue3';
import { inject, computed, ref } from 'vue';
import moment from 'moment';

const useOrderDelay = (order) => {
    const convertTo24Hour = (timeString) => {
        return moment(timeString, 'h:mm A').format('HH:mm');
    };

    const currentDay = ref(order?.form?.ready_at ? moment(order.form.ready_at).format('dddd') : moment().format('dddd'));

    const currentLocation = computed(
        () =>
            usePage().props.app?.locations?.filter(
                (location) => location.slug === order?.form?.location,
            )[0],
    );

    const currentSchedule = computed(
        () =>
            currentLocation?.value?.schedule
                .filter((schedule) => schedule.day == currentDay.value)
                .filter((schedule) => schedule.fulfillment_type == order.form.fulfillment_type)[0],
    );

    const minTime = computed(() =>
        convertTo24Hour(currentSchedule?.value?.starts_at ?? '8:00', 'h:mm A'),
    );

    const maxTime = computed(() =>
        convertTo24Hour(currentSchedule?.value?.ends_at ?? '17:00', 'h:mm A'),
    );

    const delayInMinutes = computed(() =>
        Number(
            order?.form?.fulfillment_type == 'delivery'
                ? Number(currentLocation?.value?.delivery_delay) + Number(currentLocation?.value?.carryout_delay)
                : currentLocation?.value?.carryout_delay,
        ),
    );

    return { currentDay, currentLocation, minTime, maxTime, delayInMinutes, convertTo24Hour };
};

export default useOrderDelay;
