<script setup>
import { computed } from 'vue';
import { PrinterIcon } from '@heroicons/vue/24/outline';
import { usePrint } from '@/common/Composables';

const { print } = usePrint();

const props = defineProps({
    url: {
        type: [String],
        default: null,
    },
    title: {
        type: [String],
        default: 'Print',
    },
    theme: { type: [String], default: 'default' },
    size: { type: [String], default: 'default' },
    position: { type: [String], default: 'default' },
});

const computedSizeClass = computed(
    () =>
        ({
            default: `h-10 rounded-lg p-2 px-3 text-sm`,
        })[props.size],
);

const computedThemeClass = computed(
    () =>
        ({
            pink: `bg-white text-palmers-dragon-fruit`,
            default: `bg-white enabled:supports-hover:hover:bg-gray-200`,
        })[props.theme],
);

const computedPositionClass = computed(
    () =>
        ({
            fixed: `fixed right-3 bottom-3`,
            default: ``,
        })[props.position],
);
</script>

<template>
    <button
        class="disabled:cursor-not-allowed disabled:text-gray-600"
        :class="[computedSizeClass, computedThemeClass]"
        @click="print(url)"
        :title="title"
    >
        <component :is="PrinterIcon" class="h-full w-full stroke-[2]" />
    </button>
</template>
