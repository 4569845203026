<script setup>
import Form from '@/customer/Pages/Profile/Partials/Form.vue';
import { Head, useForm, usePage } from '@inertiajs/vue3';
import AccountSettingsLayout from '@/customer/Layouts/AccountSettingsLayout.vue';
import InputGroup from '@/customer/Components/InputGroup.vue';
import GenericMaskedInput from '@/customer/Components/UI/Input/MaskedInputs/GenericMaskedInput.vue';
import Checkbox from '@/customer/Components/UI/Input/Checkboxes/CheckboxInput.vue';
import InputLabel from '@/customer/Components/InputLabel.vue';
import { useLang } from '@/customer/Composables';

const { lang } = useLang();

const props = defineProps({
    mustVerifyEmail: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const form = useForm(route().current(), {
    first_name: usePage().props.first_name,
    last_name: usePage().props.last_name,
    email: usePage().props.email,
    birthday: usePage().props.birthday,
    notifications: usePage().props.notifications,
});

const submit = () => {
    const url = route('customer.profile.update.information');

    form.patch(url, {
        preserveScroll: true,
        preserveState: true,
    });
};
</script>

<template>
    <Head title="Profile" />

    <AccountSettingsLayout class="pb-6 md:pb-0">
        <Form v-bind="{ form }" @submit="submit">
            <div class="grid grid-cols-2 gap-8">
                <div>
                    <InputGroup
                        id="first_name"
                        v-model="form.first_name"
                        :label="lang.first_name"
                        name="first_name"
                        :error-message="form.errors.first_name"
                        type="text"
                        class="mt-1 block w-full"
                        required
                        autofocus
                        autocomplete="first_name"
                        placeholder="Andrea"
                    />
                </div>
                <div>
                    <InputGroup
                        id="last_name"
                        v-model="form.last_name"
                        :label="lang.last_name"
                        name="last_name"
                        :error-message="form.errors.last_name"
                        type="text"
                        class="mt-1 block w-full"
                        required
                        autofocus
                        autocomplete="last_name"
                        placeholder="Walker"
                    />
                </div>
            </div>

            <InputGroup
                id="email"
                v-model="form.email"
                :label="lang.email"
                name="email"
                :error-message="form.errors.email"
                type="email"
                class="mt-1 block w-full"
                required
                autocomplete="email"
                placeholder="andreaw@webspec.com"
            />

            <InputGroup
                id="birthday"
                v-model="form.birthday"
                :type="GenericMaskedInput"
                :label="lang.birthday"
                class="mt-1 block w-full"
                autocomplete="birthday"
                placeholder="MM/DD"
                :error-message="form.errors.birthday"
                mask="mM/dD"
                :tokens="{
                    m: { pattern: /[0-1]/ },
                    M: { pattern: /[0-9]/ },
                    d: { pattern: /[0-3]/ },
                    D: { pattern: /[0-9]/ },
                }"
            />

            <InputLabel :value="lang.preferences" />

            <InputGroup
                id="notifications"
                v-model="form.notifications"
                :label="lang.notifications"
                name="notifications"
                :error-message="form.errors.notifications"
                :type="Checkbox"
                autocomplete="email"
            />
        </Form>
    </AccountSettingsLayout>
</template>
