<script setup>
import { Head } from '@inertiajs/vue3';
import AccountSettingsLayout from '@/customer/Layouts/AccountSettingsLayout.vue';
import AccountOverview from './Partials/AccountOverview.vue';
import LinkList from '@/customer/Components/UI/Lists/LinkList.vue';
import LinkListItem from '@/customer/Components/UI/Lists/LinkListItem.vue';
import { useLang } from '@/customer/Composables';

const { lang } = useLang();

defineProps({
    first_name: { type: String },
    last_name: { type: String },
    email: { type: String },
    birthday: { type: String },
    phoneDisplay: { type: String },
});
</script>

<template>
    <Head title="Profile" />

    <AccountSettingsLayout>
        <AccountOverview v-bind="{ ...$props }" />

        <LinkList class="border-t border-gray-300 sm:border">
            <LinkListItem
                class="px-3 sm:px-8"
                :href="route('customer.profile.edit.information')"
                :label="lang.info"
            />
            <LinkListItem
                class="px-3 sm:px-8"
                :href="route('customer.profile.edit.password')"
                :label="lang.password"
            />
            <LinkListItem
                class="px-3 sm:px-8"
                :href="route('customer.profile.addresses.index')"
                :label="lang.addresses"
            />
            <LinkListItem
                class="px-3 sm:px-8"
                :href="route('customer.profile.contacts.index')"
                :label="lang.contacts"
            />
            <LinkListItem
                class="px-3 sm:px-8"
                :href="route('customer.profile.pastOrders')"
                :label="lang.past_orders"
            />
            <LinkListItem
                class="px-3 sm:px-8"
                :href="route('customer.profile.savedOrders')"
                :label="lang.saved_orders"
            />
        </LinkList>
    </AccountSettingsLayout>
</template>
