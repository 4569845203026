<script setup>
import { computed } from 'vue';
import { Head, Link } from '@inertiajs/vue3';
import AccountSettingsLayout from '@/customer/Layouts/AccountSettingsLayout.vue';
import AddressCard from '../Partials/AddressCard.vue';
import { default as Button } from '@/customer/Components/BaseButton.vue';
import { useLang } from '@/customer/Composables';

const { lang } = useLang();

const props = defineProps({
    addresses: {
        type: Object,
    },
});

const addresses = computed(() => props.addresses.data);
</script>

<template>
    <AccountSettingsLayout :title="lang.title_index" class="pb-6 md:pb-0">
        <Head title="Profile" />

        <template v-for="address in addresses">
            <AddressCard v-bind="{ ...address }" />
        </template>

        <Button :as="Link" :href="route('customer.profile.addresses.create')">
            {{ lang.add }}
        </Button>
    </AccountSettingsLayout>
</template>
