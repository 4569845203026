<script setup>
import { ref } from 'vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';
import { onMounted } from 'vue';
import { nextTick } from 'vue';

const props = defineProps({
    configurations: {
        type: Array,
        default: [],
    },
    comboItemOptions: {
        type: Array,
        default: [],
    },
});

const showArrows = ref(null);

// initialize relevant element nodes
const containerComponent = ref(null);
const parentComponent = ref(null);
const rightArrowButton = ref(null);

// initialize values for calculations
const offsets = ref([0]);
const currentElement = ref(0);
const maxElement = ref(0);

// callback to set calculation values
const getChildNodes = () => {
    offsets.value = [0];
    maxElement.value = 0;
    const children = parentComponent.value?.childNodes;
    const rightArrowLeftEdge = rightArrowButton.value.getBoundingClientRect().left;
    children.forEach((item) => {
        if (item.nodeType === 1) {
            const rightEdge = item.getBoundingClientRect().right;
            if (rightEdge > rightArrowLeftEdge) {
                offsets.value.push(rightEdge - rightArrowLeftEdge);
                maxElement.value++;
            }
        }
    });
};

/**
 * there is an onMounted and window.onload call to getChildNodes
 *
 * when selecting a menu item there is no page reload
 * however, if you manually refresh or someone opened a bookmark per se
 * something weird happens to the values related to our element node rects
 * if we don't wait for page load to get them (in a scenario where it's a fresh reload)
 *
 * needsArrows is a boolean that checks if the tabs are overflowing their container
 * if they aren't, we don't need arrows
 * and since window.onload comes after this component mounts
 * we don't have to call getChildNodes onload if we don't even need arrows
 */
const needsArrows = ref(null);

onMounted(() => {
    const containerWidth = containerComponent.value.getBoundingClientRect().width;
    const parentWidth = parentComponent.value.getBoundingClientRect().width;
    needsArrows.value = containerWidth < parentWidth;
    if (needsArrows.value) {
        showArrows.value = true;
        nextTick(() => getChildNodes());
    } else {
        showArrows.value = false;
    }
});

window.onload = () => {
    if (needsArrows.value) {
        getChildNodes();
    }
};

/**
 * callback for arrow buttons
 * sets the translation of tabs parent container
 * parent container has transition-transform so the update is smooth like a scroll
 */
const useArrow = (direction) => {
    const makeTranslation = (stepper) => {
        currentElement.value = currentElement.value + stepper;
        let offset = offsets.value[currentElement.value];
        currentElement.value === 0 ? null : (offset = offset - 2);
        parentComponent.value.style.transform = `translateX(-${offset}px)`;
    };

    if (direction === 'right' && currentElement.value < maxElement.value) {
        makeTranslation(1);
    } else if (direction === 'left' && currentElement.value > 0) {
        makeTranslation(-1);
    }
};
</script>

<template>
    <!--
TODO: Create tabbed navigation to jump to customization sections.
TODO: Mobile friendly should behave like Subway
TODO: Horizontal scrolling and possibly menu
-->

    <div ref="containerComponent" class="relative flex overflow-hidden">
        <button
            v-if="showArrows"
            @click="useArrow('left')"
            class="z-20 h-12 w-8 border-r-2 bg-white"
        >
            <div class="tabbedArrowButton tabbedButton h-full">
                <ChevronLeftIcon class="h-5 w-5" />
            </div>
        </button>
        <div
            ref="parentComponent"
            class="z-0 flex h-12 items-center divide-x-2 bg-white transition-transform duration-[350ms] ease-in-out"
            :class="[showArrows === false ? 'w-full' : '']"
        >
            <button
                v-for="(configuration, index) in configurations"
                @click.prevent="$emit('expand', index)"
                class="tabbedButtonClass"
                :class="[showArrows === false ? 'w-full' : '']"
            >
                {{ configuration.name }}
            </button>

            <button
                v-for="(comboItemOption, index) in comboItemOptions"
                @click.prevent="$emit('expand', index)"
                class="tabbedButtonClass"
                :class="[showArrows === false ? 'w-full' : '']"
            >
                {{ comboItemOption.name }}
            </button>
        </div>
        <button
            v-if="showArrows"
            @click="useArrow('right')"
            class="absolute right-0 z-20 h-full w-8 border-l-2 bg-white"
            ref="rightArrowButton"
        >
            <div class="tabbedArrowButton tabbedButton ml-auto h-full">
                <ChevronRightIcon class="h-5 w-5" />
            </div>
        </button>
    </div>
</template>

<style scoped>
.tabbedButton {
    @apply supports-hover:hover:bg-palmers-dragon-fruit/10 supports-hover:hover:text-palmers-dragon-fruit;
}

.tabbedArrowButton {
    @apply flex w-8 items-center justify-center;
}

.tabbedButtonClass {
    @apply tabbedButton inline-block whitespace-pre p-2 py-6 text-center text-xs font-semibold uppercase text-gray-600;
}
</style>
