<script setup>
import Form from '@/customer/Pages/Profile/Partials/Form.vue';
import InputGroup from '@/customer/Components/InputGroup.vue';
import Checkbox from '@/customer/Components/UI/Input/Checkboxes/CheckboxInput.vue';
import PhoneInput from '@/customer/Components/UI/Input/MaskedInputs/PhoneInput.vue';
import TextareaInput from '@/customer/Components/UI/Input/TextareaInput.vue';
import { useLang } from '@/customer/Composables';
import InputSelectState from '@/customer/Components/InputSelectState.vue';
import { states } from '@/common/Utils/states';
const { lang } = useLang();
import { string, boolean } from 'yup';
import { checkStateZip } from '@/customer/Composables/useCheckStateZip';
import { ref, watch } from 'vue';
import InputError from '@/customer/Components/InputError.vue';

defineEmits(['submit']);

const props = defineProps({
    form: { type: Object },
});

const errors = ref([]);

const cityStateZipIsValid = ref(true);
watch(
    () => [props.form.state, props.form.zip],
    () => {
        const inputState = props.form.state;
        const inputZip = props.form.zip;
        if (
            typeof inputState === 'string' &&
            inputState.length > 0 &&
            typeof inputZip === 'string' &&
            inputZip.length > 0
        ) {
            cityStateZipIsValid.value = checkStateZip(inputState, inputZip);
            if (!cityStateZipIsValid.value) {
                errors.value.push('Zip code does not match selected state');
            } else {
                errors.value.length = 0;
            }
        }
    },
);
</script>

<template>
    <Form v-bind="{ form }" :disabled="!cityStateZipIsValid" @submit="$emit('submit')">
        <InputGroup
            id="name"
            v-model="form.name"
            :label="lang.name"
            name="name"
            :error-message="form.errors.name"
            type="text"
            class="mt-1 block w-full"
            autofocus
            autocomplete="name"
        />

        <InputGroup
            id="phone"
            v-model="form.phone"
            :label="lang.phone"
            name="phone"
            :error-message="form.errors.phone"
            :type="PhoneInput"
            class="mt-1 block w-full"
            autocomplete="phone"
        />

        <InputGroup
            id="street"
            v-model="form.street"
            :label="lang.street"
            name="street"
            :error-message="form.errors.street"
            type="text"
            class="mt-1 block w-full"
            autocomplete="street-address"
        />

        <InputGroup
            id="city"
            v-model="form.city"
            :label="lang.city"
            name="city"
            :error-message="form.errors.city"
            type="text"
            class="mt-1 block w-full"
            autocomplete="address-level2"
        />

        <InputGroup
            id="state"
            v-model="form.state"
            :label="lang.state"
            name="state"
            :states="states"
            :error-message="form.errors.state"
            :validator-attributes="['form.state']"
            :validator="
                string()
                    .required('cc-state')
                    .test('state-zip', 'state-zip', () => {
                        return cityStateZipIsValid;
                    })
            "
            :type="InputSelectState"
            class="mt-1 block w-full"
            autocomplete="address-level1"
        />

        <InputGroup
            id="zip"
            v-model="form.zip"
            :label="lang.zip"
            name="zip"
            :error-message="form.errors.zip"
            :validator-attributes="['form.zip']"
            :validator="
                string()
                    .required('cc-zip')
                    .matches(/^\d{5}(?:-\d{4})?$/, 'cc-zip')
                    .test('state-zip', 'state-zip', () => {
                        return cityStateZipIsValid;
                    })
            "
            type="text"
            class="mt-1 block w-full"
            autocomplete="postal-code"
        />

        <InputError v-if="errors.length" :message="errors[0]" class="m-2 tracking-normal" />

        <InputGroup
            id="landmarks"
            v-model="form.landmarks"
            :label="lang.landmarks"
            name="landmarks"
            :error-message="form.errors.landmarks"
            :type="TextareaInput"
            class="mt-1 block w-full"
            rows="4"
            autocomplete="landmarks"
        />

        <InputGroup
            id="building"
            v-model="form.building"
            :label="lang.building"
            name="building"
            :error-message="form.errors.building"
            type="text"
            class="mt-1 block w-full"
            autocomplete="building"
        />

        <div class="flex items-center space-x-6">
            <InputGroup
                id="suite"
                v-model="form.suite"
                :label="lang.suite"
                name="suite"
                :error-message="form.errors.suite"
                type="text"
                class="mt-1 block w-full"
                autocomplete="suite"
            />

            <InputGroup
                id="floor"
                v-model="form.floor"
                label="Floor"
                name="floor"
                :error-message="form.errors.floor"
                type="text"
                class="mt-1 block w-full"
                autocomplete="floor"
            />
        </div>

        <InputGroup
            id="drop_off"
            v-model="form.drop_off"
            :label="lang.drop_off"
            name="drop_off"
            :error-message="form.errors.drop_off"
            :type="Checkbox"
            class="mt-1 block"
            autocomplete="drop_off"
        />

        <InputGroup
            id="notes"
            v-model="form.notes"
            :label="lang.notes"
            name="notes"
            :error-message="form.errors.notes"
            :type="TextareaInput"
            class="mt-1 block w-full"
            rows="4"
            autocomplete="notes"
        />

        <InputGroup
            id="call"
            v-model="form.call"
            :label="lang.call"
            name="call"
            :error-message="form.errors.call"
            :type="Checkbox"
            class="mt-1 block"
            autocomplete="call"
        />
    </Form>
</template>
