<script setup>
import { computed, ref } from 'vue';
import { useCartStore, useOrderStore } from '@/customer/Composables';
import { MenuType } from '@/common/Utils/enums.js';
import CartAddons from './CartAddons.vue';
import { Tooltip } from '@/customer/Components';
import Disclaimers from '@/customer/Pages/Checkout/Partials/Disclaimers.vue';
import SpinnerIcon from '../Icons/SpinnerIcon.vue';

const cart = useCartStore();
const order = useOrderStore();

const checkoutLoading = ref(false);
const hasInvalidMessages = computed(() => {
    if (cart.items && typeof cart.items === 'object') {
        const itemsArray = Object.values(cart.items);
        const hasInvalidMessage = itemsArray.some((item) => item.unavailable_message !== null);
        return hasInvalidMessage;
    }
    return false;
});
</script>

<template>
    <template v-if="!order.isCatering">
        <CartAddons />
    </template>

    <!-- Summary -->
    <div
        v-if="!order.isCatering"
        class="border-t-2 px-10 py-8 pb-[calc(176px+2rem)] text-palmers-charcoal-grey sm:px-20 [@media(width>639px)]:pb-[calc(96px+2rem)] [@media(width>768px)]:pb-8"
    >
        <div class="flex flex-wrap items-center justify-between">
            <h3 class="text-2xl font-bold">SUBTOTAL:</h3>
            <div class="text-5xl font-bold">
                {{ cart.summary.subtotal.formatted }}
            </div>
        </div>

        <!-- Legal Disclosures -->
        <Disclaimers />
    </div>

    <div v-else>
        <div class="space-y-4 bg-palmers-havarti px-10 py-8 text-palmers-charcoal-grey sm:px-20">
            <div class="flex items-center justify-between gap-x-2">
                <h3 class="text-4xl font-bold">Quote:</h3>
                <div class="text-5xl font-bold">{{ cart.summary.subtotal.formatted }}</div>
            </div>
            <p class="text-sm">
                This is our best estimate for your order before tax and tip. Upon future
                communications, we will provide a final cost.
            </p>
        </div>
        <div class="border-t-2 px-10 py-8 text-palmers-charcoal-grey sm:px-20">
            <Disclaimers class="!mt-0 !pt-0" />
        </div>
    </div>

    <!-- Actions -->
    <div
        class="z-40 w-full [@media(width<=768px)]:fixed [@media(width<=768px)]:bottom-0 [@media(width>768px)]:flex [@media(width>768px)]:w-auto"
    >
        <div
            class="item-center flex w-full flex-wrap justify-between gap-y-4 bg-palmers-dragon-fruit px-6 py-6 text-palmers-charcoal-grey sm:px-20"
        >
            <a
                :href="
                    route('customer.menu.index', {
                        type: MenuType.MAIN,
                    })
                "
                class="w-full rounded-full border-2 border-palmers-charcoal-grey bg-white px-4 py-3 text-center text-xl font-bold supports-hover:hover:bg-palmers-avocado-green sm:w-auto sm:py-2"
            >
                {{ order.isCatering ? 'NEED MORE?' : 'STILL HUNGRY?' }}
            </a>
            <Tooltip
                v-if="hasInvalidMessages"
                message="Please check your cart items."
                class="flex w-full items-center justify-center sm:mb-0 sm:w-auto"
            >
                <a
                    href="#"
                    class="w-full cursor-not-allowed rounded-full border-2 border-black bg-white px-4 py-3 text-center text-xl font-bold sm:w-auto sm:py-2"
                >
                    CHECKOUT
                </a>
            </Tooltip>
            <Tooltip
                v-else-if="order.isDelivery && !order.isCatering && cart.summary.subtotal.raw < 60"
                message="Deliveries must be at least $60"
                class="flex w-full items-center justify-center sm:mb-0 sm:w-auto"
            >
                <a
                    href="#"
                    class="w-full cursor-not-allowed rounded-full border-2 border-black bg-white px-4 py-3 text-center text-xl font-bold sm:w-auto sm:py-2"
                >
                    CHECKOUT
                </a>
            </Tooltip>
            <a
                v-else
                :href="route('customer.checkout.index')"
                @click="checkoutLoading = true"
                class="relative flex w-full items-center justify-center rounded-full border-2 border-black bg-white px-4 py-3 text-center text-xl font-bold supports-hover:hover:bg-palmers-avocado-green sm:mb-0 sm:w-auto sm:py-2"
            >
                <span :class="{ 'opacity-0': checkoutLoading }">{{
                    order.isCatering ? 'PLACE INQUIRY' : 'CHECKOUT'
                }}</span>
                <SpinnerIcon
                    :class="{ hidden: !checkoutLoading }"
                    class="absolute size-7 animate-spin text-palmers-dragon-fruit"
                />
            </a>
        </div>
    </div>
</template>
