export const FulfillmentType = Object.freeze({
    CARRYOUT: "carryout",
    DELIVERY: "delivery",
});

export const MenuType = Object.freeze({
    MAIN: "menu",
    CATERING: "catering",
});

export const DiscountType = Object.freeze({
    PERCENTAGE: "percentage",
    FIXED: "fixed",
});

export const ServiceType = Object.freeze({
    ALL_DAY: 'all-day',
    BREAKFAST: "breakfast",
    BRUNCH: "brunch",
    LUNCH: "lunch",
    DINNER: "dinner",
});
