<script setup>
import { computed } from "vue";
import { Link } from "@inertiajs/vue3";

const props = defineProps({
    href: {
        type: String,
        required: true,
    },
    active: {
        type: Boolean,
    },
});

const classes = computed(() =>
    props.active
        ? "tracking-widest inline-flex items-center px-1 pt-1 text-sm leading-5 text-black font-bold focus:outline-none transition duration-150 ease-in-out"
        : "tracking-widest inline-flex items-center px-1 pt-1 border-transparent text-sm leading-5 text-black font-bold transition duration-150 ease-in-out",
);
</script>

<template>
    <Link :href="href" class="uppercase" :class="classes">
        <slot />
    </Link>
</template>
