<script setup>
import { computed } from 'vue';
import { optionIcons as iconMap } from '@/customer/Components/Order/internal';

const props = defineProps({
    icon: Object,
    label: { type: String, required: true },
    value: { type: String, required: true },
    active: Boolean,
    checked: Boolean,
    info: String,
});

const iconComponent = computed(() => props.icon || iconMap[props.value]);
</script>

<template>
    <button
        class="group relative h-52 w-40 overflow-hidden rounded-xl font-extrabold uppercase shadow-md ring-4"
        :class="{
            'shadow-none ring-palmers-dragon-fruit': checked,
            'supports-hover:hover:ring-palmers-dragon-fruit border border-palmers-dragon-fruit/50 ring-transparent !ring-opacity-50':
                !checked,
            '': active,
        }"
    >
        <span
            class="absolute inset-0 flex flex-col items-center justify-center rounded-lg bg-palmers-himalayan-pink p-4 pt-6 ring-4"
            :class="{
                'ring-palmers-dragon-fruit': checked,
                'supports-hover:group-hover:ring-palmers-dragon-fruit ring-transparent !ring-opacity-50':
                    !checked,
                '': active,
            }"
        >
            <span>{{ label }}</span>
            <component :is="iconComponent" class="rounded-sm !stroke-1 p-6" />
        </span>
    </button>
    <div class="group relative w-40 overflow-hidden pt-5 text-center">
        <address class="text-xs not-italic leading-tight">
            <span> {{ info }} </span>
        </address>
    </div>
</template>
