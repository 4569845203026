<script setup>
import { ref } from 'vue';
import { PhotoIcon, MinusIcon, PlusIcon } from '@heroicons/vue/20/solid';
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    src: {
        type: String,
    },
    alt: {
        type: String,
        default: '',
    },
    imageContainerClass: {
        type: String,
        default: 'relative flex h-full w-full items-center justify-center',
    },
    imageClass: {
        type: String,
        default: '',
    },
    href: {
        type: String,
        required: false,
    }
});

const loading = ref(true);
</script>

<template>
    <div :class="imageContainerClass">
        <template v-if="loading">
            <PhotoIcon class="h-16 w-16 animate-pulse text-gray-500/50" />
        </template>
    
        <Link v-if="href" :href="href">
            <img
                v-show="!loading"
                v-bind="{ src, alt }"
                @error="loading = true"
                class="relative z-10 h-full w-full object-contain object-center"
                :class="imageClass"
                @load="loading = false"
            />
        </Link>
        <img v-else
            v-show="!loading"
            v-bind="{ src, alt }"
            @error="loading = true"
            class="relative z-10 h-full w-full object-contain object-center"
            :class="imageClass"
            @load="loading = false"
        />
    </div>
</template>
