<script setup>
import StepHeader from './StepHeader.vue';
import LocationPicker from './LocationStep/LocationPicker.vue';
import { optionProps } from '@/customer/Components/Order/internal';
import ShoppingStoreIcon from '@/customer/Components/Icons/ShoppingStoreIcon.vue';

const props = defineProps({
    ...optionProps,
});
</script>

<template>
    <div>
        <div class="flex flex-col items-center justify-start pt-6">
            <ShoppingStoreIcon class="-mb-3 block size-10 bg-white [@media(width>610px)]:hidden" />
            <StepHeader> {{ prompt }} </StepHeader>
        </div>

        <LocationPicker
            v-model="form.location"
            :locations="options"
            :errorMessage="props.form.errors.location"
        />
    </div>
</template>
