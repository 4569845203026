<script setup>
import { computed } from 'vue';
import ApplicationLogo from '@/customer/Components/ApplicationLogo.vue';
import AppIcon from '@/customer/Components/AppIcon.vue';
import Dropdown from '@/customer/Components/Dropdown.vue';
import DropdownLink from '@/customer/Components/DropdownLink.vue';
import NavLink from '@/customer/Components/NavLink.vue';
import { Link, usePage } from '@inertiajs/vue3';
import useAuth from '@/customer/Composables/useAuth.js';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { MenuType } from '@/common/Utils/enums.js';
import SlideCart from '@/customer/Components/Cart/SlideCart.vue';
import { useOrderStore } from '@/customer/Composables';

defineProps({
    showResponsiveMenu: {
        type: Boolean,
        required: true,
    },
});

const { auth, check: authCheck } = useAuth();

const $orderStore = useOrderStore();

const orderReady = computed(() => usePage().props.order.started && !usePage().props.cart);
</script>

<template>
    <header id="navHeader" class="border-b-zigzag sticky top-0 z-[600] border-gray-100 bg-white">
        <nav aria-label="Top">
            <!-- Primary Navigation Menu -->
            <div class="mx-auto max-w-screen-2xl px-4 sm:px-6">
                <div class="flex h-24 justify-between">
                    <div class="flex">
                        <!-- Logo -->
                        <div class="flex shrink-0 items-center">
                            <Link :href="route('customer.order.start')">
                                <ApplicationLogo class="h-16 w-auto" />
                            </Link>
                        </div>
                    </div>

                    <div class="flex items-center">
                        <div class="flex">
                            <div
                                v-if="!orderReady ? true : $orderStore.isCatering ? false : true"
                                class="hidden space-x-4 sm:-my-px sm:ml-5 sm:flex"
                            >
                                <NavLink
                                    :href="
                                        route('customer.menu.index', {
                                            type: MenuType.MAIN,
                                        })
                                    "
                                >
                                    Menu
                                </NavLink>
                            </div>

                            <!-- <div
                            v-if="!orderReady ? true : $orderStore.isCatering ? true : false"
                            class="hidden space-x-4 sm:-my-px sm:ml-5 sm:flex"
                        >
                            <NavLink
                                :href="
                                    route('customer.menu.index', {
                                        type: MenuType.CATERING,
                                    })
                                "
                            >
                                Catering
                            </NavLink>
                        </div> -->

                            <div class="hidden space-x-4 sm:-my-px sm:ml-5 sm:flex">
                                <NavLink
                                    :href="route('customer.locations')"
                                    :active="route().current('customer.locations')"
                                >
                                    Locations
                                </NavLink>
                            </div>

                            <div
                                v-if="!authCheck() && !route().current('customer.login')"
                                class="hidden space-x-4 sm:-my-px sm:ml-5 sm:flex"
                            >
                                <NavLink
                                    :href="route('customer.login')"
                                    :active="route().current('customer.login')"
                                >
                                    Login
                                </NavLink>
                            </div>
                            <div v-if="authCheck()" class="hidden sm:ml-6 sm:flex sm:items-center">
                                <!-- Settings Dropdown -->
                                <Dropdown
                                    width="48"
                                    content-classes="py-1 bg-white"
                                    popper-classes="-top-4"
                                    :align="`right`"
                                    arrow
                                >
                                    <template #trigger>
                                        <span class="inline-flex h-full rounded-md">
                                            <button
                                                type="button"
                                                class="relative inline-flex h-24 flex-col items-center justify-center rounded-md border border-transparent bg-white px-1 pt-1 text-center text-sm font-bold uppercase leading-4 tracking-widest text-black transition duration-150 ease-in-out focus:outline-none"
                                            >
                                                <span class="inline-flex items-center">
                                                    My Account
                                                    <ChevronDownIcon class="ml-1 h-5 w-5" />
                                                </span>
                                            </button>
                                        </span>
                                    </template>
                                    <template #content>
                                        <DropdownLink :href="route('customer.profile.edit')">
                                            Profile
                                        </DropdownLink>
                                        <DropdownLink
                                            :href="route('customer.logout')"
                                            method="post"
                                            as="button"
                                        >
                                            Log Out
                                        </DropdownLink>
                                    </template>
                                </Dropdown>
                            </div>
                        </div>

                        <div class="mr-2 flex h-full space-x-4 sm:-my-px sm:ml-5 sm:mr-0">
                            <SlideCart v-if="orderReady" />
                        </div>

                        <!-- Hamburger -->
                        <div class="-mr-2 flex items-center sm:hidden">
                            <button
                                class="flex items-center justify-center rounded-full p-2 text-gray-400 transition duration-150 ease-in-out focus:bg-gray-100 focus:text-gray-500 focus:outline-none supports-hover:hover:bg-gray-100 supports-hover:hover:text-gray-500"
                                @click="$emit('handleResponsiveMenu', !showResponsiveMenu)"
                            >
                                <svg class="h-8 w-8" stroke="black" fill="none" viewBox="0 0 24 24">
                                    <path
                                        :class="{
                                            hidden: showResponsiveMenu,
                                            'inline-flex': !showResponsiveMenu,
                                        }"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                    <path
                                        :class="{
                                            hidden: !showResponsiveMenu,
                                            'inline-flex': showResponsiveMenu,
                                        }"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>
