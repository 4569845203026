export const optionProps = {
    form: { type: Object },
    prompt: { type: String },
    description: { type: String },
    options: { type: Object },
    by: { type: Function, default: (a, b) => a === b },
};

export const switcherProps = {
    icon: { type: [Function, Object] },
    label: { type: String },
    step: { type: String },
    dropdownDisabled: { type: Boolean, default: true },
    iconClass: { type: String, default: 'w-4 h-4' },
    buttonClass: { type: String, default: '' },
};
