<script setup>
import { useApp } from '@/customer/Composables';
import { computed } from 'vue';
import { ref } from 'vue';

const app = useApp();

const disclaimer = ref(app.app.value.disclaimers?.[0]);

const alignmentClass = computed(
    () =>
        ({
            left: 'text-left',
            right: 'text-right',
            center: 'text-center',
            justify: 'text-justify',
        })[disclaimer.value?.alignment ?? 'left'],
);
</script>

<template>
    <div v-if="disclaimer" class="mt-4">
        <p class="text-sm" :class="alignmentClass">
            {{ disclaimer.text }}
        </p>
    </div>
</template>
