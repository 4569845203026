<script setup>
import { MinusIcon, PlusIcon } from '@heroicons/vue/20/solid';
import { onBeforeMount, computed } from 'vue';
import { useMenuItemStore } from '@/customer/Composables';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: Number,
        required: true,
    },
    min: {
        type: Number,
        default: 0,
    },
    max: {
        type: Number,
        default: Number.MAX_SAFE_INTEGER,
    },
});

onBeforeMount(() => {
    if (typeof props.modelValue !== 'number') {
        throw new Error('QuantityEditor expects the quantity to be a number');
    }
});

const decrement = () => {
    const newValue = props.modelValue - 1;
    if (newValue > props.min) {
        emit('update:modelValue', props.modelValue - 1);
    }
};

const increment = () => {
    const newValue = props.modelValue + 1;
    if (newValue <= props.max) {
        emit('update:modelValue', props.modelValue + 1);
    }
};
</script>

<template>
    <div class="flex items-center justify-between">
        <MinusIcon
            class="h-8 w-8 cursor-pointer rounded-full border-2 p-1"
            :class="
                modelValue - 1 > min
                    ? 'border-palmers-dragon-fruit text-black'
                    : 'border-gray-500 text-gray-500'
            "
            @click.stop="decrement"
        />
        <span
            class="flex h-8 w-8 select-none items-center justify-center rounded-full border-2 border-palmers-dragon-fruit p-1"
        >
            {{ modelValue }}
        </span>
        <PlusIcon
            class="h-8 w-8 cursor-pointer rounded-full border-2 p-1"
            :class="
                modelValue + 1 <= max
                    ? 'border-palmers-dragon-fruit text-black'
                    : 'border-gray-500 text-gray-500'
            "
            @click.stop="increment"
        />
    </div>
</template>
