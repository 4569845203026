<script setup>
import { computed } from "vue";
import InputTextarea from "@/customer/Components/InputTextarea.vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
    modelValue: {
        type: String,
        required: false,
        default: "",
    },
    placeholder: {
        type: String,
    },
});

const inputValue = computed({
    get: () => {
        return props.modelValue;
    },
    set: (value) => {
        emit("update:modelValue", value);
    },
});
</script>

<template>
    <div class="relative">
        <InputTextarea
            v-bind="{ ...$attrs, placeholder: props.mask }"
            v-model.lazy="inputValue"
        />
        <div v-if="!inputValue" class="absolute inset-y-3 inset-x-6 pointer-events-none text-gray-500">
            {{ placeholder }}
        </div>
    </div>
</template>
