import AsapIcon from '@/customer/Components/Icons/AsapIcon.vue';
import CateringMenuIcon from '@/customer/Components/Icons/CateringMenuIcon.vue';
import DeliveryIcon from '@/customer/Components/Icons/DeliveryIcon.vue';
import MainMenuAltIcon from '@/customer/Components/Icons/MainMenuAltIcon.vue';
import ShoppingStoreIcon from '@/customer/Components/Icons/ShoppingStoreIcon.vue';
import FulfillmentTypeSwitcher from '@/customer/Components/Order/Switchers/FulfillmentTypeSwitcher.vue';
import LocationSwitcher from '@/customer/Components/Order/Switchers/LocationSwitcher.vue';
import MenuTypeSwitcher from '@/customer/Components/Order/Switchers/MenuTypeSwitcher.vue';
import OrderTypeSwitcher from '@/customer/Components/Order/Switchers/OrderTypeSwitcher.vue';
import ReadyTypeSwitcher from '@/customer/Components/Order/Switchers/ReadyTypeSwitcher.vue';

import {
    BuildingStorefrontIcon,
    CalendarIcon,
    UserGroupIcon,
    UserIcon,
} from '@heroicons/vue/24/outline';

export const optionIcons = {
    location: BuildingStorefrontIcon,
    single: UserIcon,
    group: UserGroupIcon,
    catering: CateringMenuIcon,
    menu: MainMenuAltIcon,
    carryout: ShoppingStoreIcon,
    delivery: DeliveryIcon,
    asap: AsapIcon,
    scheduled: CalendarIcon,
};

export const optionSwitchers = {
    location: LocationSwitcher,
    order_type: OrderTypeSwitcher,
    menu_type: MenuTypeSwitcher,
    fulfillment_type: FulfillmentTypeSwitcher,
    ready_type: ReadyTypeSwitcher,
};

export const optionIcon = (value) => optionIcons[value];
export const optionSwitcher = (value) => optionSwitchers[value];
