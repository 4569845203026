<script setup>
import { InputGroup } from '@/customer/Components';
import DatePicker from '@/customer/Components/UI/Input/Date/DatePicker.vue';
import { ref, computed, watch } from 'vue';
import { useOrderStore } from '@/customer/Composables';
import moment from 'moment-timezone';
import useOrderDelay from '@/company/Composables/useOrderDelay';

const order = useOrderStore();

const { currentDay, minTime, maxTime, delayInMinutes, currentLocation, convertTo24Hour } = useOrderDelay(order);

const isCatering = computed(() => order.isCatering);

const closedDaysOfWeek = computed(() => {
    const daysOfWeek = {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
    };
    const openDays = currentLocation.value?.schedule
        ?.filter((schedule) => schedule.fulfillment_type == order.form.fulfillment_type)
        .map((schedule) => schedule.day);
    const closedDays = Object.values(daysOfWeek).filter((day) => !openDays.includes(day));

    const closedNums = closedDays.map((day) => {
        return parseInt(Object.keys(daysOfWeek).find((k) => daysOfWeek[k] == day));
    });
    return closedNums;
});

const closedHolidays = computed(() => {
    const closed = [];
    const closures = currentLocation.value?.schedule?.filter(
        (schedule) => schedule.starts_at == null,
    );
    closures?.map((closure) => {
        closed.push(moment(closure.override_on).format('YYYY-MM-DD'));
    });
    return closed;
});

const calcMinTime = computed(() => {
    if (!isToday.value) {
        return moment(
            `${moment().format('YYYY-MM-DD')} ${minTime.value}`,
            'YYYY-MM-DD HH:mm',
        ).format('hh:mm a');
    } else {
        // if today opening time is after the current time plus the delay,
        if (
            moment(`${moment().format('YYYY-MM-DD')} ${minTime.value}`).format('YYYY-MM-DD HH:mm') <
            moment().add(delayInMinutes.value, 'minutes').format('YYYY-MM-DD HH:mm')
        ) {
            return moment().add(delayInMinutes.value, 'minutes').format('hh:mm a');
        } else {
            // too early
            return moment(
                `${moment().format('YYYY-MM-DD')} ${minTime.value}`,
                'YYYY-MM-DD HH:mm',
            ).format('hh:mm a');
        }
    }
});

const calcMaxTime = computed(() => {
    return moment(`${moment().format('YYYY-MM-DD')} ${maxTime.value}`, 'YYYY-MM-DD HH:mm').format(
        'hh:mm a',
    );
});

function isSameDate(d1, d2) {
    return d1.toISOString().split('T')[0] === d2.toISOString().split('T')[0];
}

const hideAsap = computed(() => {
    if (closedHolidays.value.some((holiday) => isSameDate(moment(holiday).toDate(), moment().toDate()))) {
        return true;
    } else if (closedDaysOfWeek.value.includes(moment().day())) {
        return true;
    } else if(
        moment(moment().format('YYYY-MM-DD') + ' ' + currentLocation.value?.schedule?.filter((schedule) => schedule.fulfillment_type == order.form.fulfillment_type && schedule.day == moment().format('dddd'))[0]?.ends_at).isBefore(moment().add(delayInMinutes.value, 'minutes'))
    ) {
        // too late hide today
        closedHolidays.value.push(moment());
        return true;
    } else if (
        moment(moment().format('YYYY-MM-DD') + ' ' + currentLocation.value?.schedule?.filter((schedule) => schedule.fulfillment_type == order.form.fulfillment_type && schedule.day == moment().format('dddd'))[0]?.starts_at).isAfter(moment().add(delayInMinutes.value, 'minutes'))
    ) {
        // too early
        return true;
    } else {
        return false;
    }
});

const nextAvailableDay = () => {
    const schedule =
        order.form.fulfillment_type == 'carryout'
            ? currentLocation.value.schedule.filter((day) => day.fulfillment_type === 'carryout')
            : currentLocation.value.schedule.filter((day) => day.fulfillment_type !== 'carryout');
    const tempDate = moment().add(1, 'day');
    while (closedHolidays.value.some((holiday) => moment(holiday).isSame(tempDate, 'day'))) {
        tempDate.add(1, 'day');
    }
    return tempDate.format(
        `YYYY-MM-DD ${convertTo24Hour(schedule.find((day) => day.day == moment(tempDate).format('dddd')).starts_at)}`,
    );
};

watch(
    () => order.form.fulfillment_type,
    () => {
        if (order.form.ready_type == 'asap') {
            const isBeforeClose = moment()
                .add(delayInMinutes.value, 'minutes')
                .isBefore(moment(maxTime.value, 'HH:mm'));
            order.form.ready_at = isBeforeClose
                ? moment().add(delayInMinutes.value, 'minutes').format('YYYY-MM-DD h:mm:00')
                : nextAvailableDay();
        } else {
            const readyAtMoment = moment(
                order.form.ready_at,
                'YYYY-MM-DD HH:mm:ss',
                'YYYY-MM-DDTHH:mm:ss[Z]',
            );
            const delayMoment = moment().add(delayInMinutes.value, 'minutes');
            if (readyAtMoment.isBefore(delayMoment)) {
                const minutesToAdd = Math.ceil(
                    Math.abs(readyAtMoment.diff(delayMoment)) / 1000 / 60,
                );
                const readyAtWithMinutes = readyAtMoment.add(minutesToAdd, 'minutes');

                const keepToday = moment(readyAtWithMinutes)
                    .format('HH:mm')
                    .isBefore(moment(maxTime.value, 'HH:mm'));

                order.form.ready_at = keepToday
                    ? readyAtWithMinutes.format('YYYY-MM-DD h:mm:00')
                    : nextAvailableDay();
            }
        }
    },
);

/**
 * initialize values from current order object
 * parse the ready_at datetime string to set values of date and time respectively
 */
const readyAsap = ref(order.form.data().ready_type === 'asap' ? true : false);
const readyAtDate = ref(
    moment(order.form.ready_at) ??
        moment().add(delayInMinutes.value, 'm').format('YYYY-MM-DD h:mm:00'),
);

const isToday = computed(
    () => moment(readyAtDate.value).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'),
);

const adjustedTime = computed(() => moment().add(delayInMinutes.value, 'minutes').toDate());

/**
 * callback to update order ready_type and ready_at in DB through OrderStore and OrderController
 */
const updateOrder = async (ready_type, ready_at = null) => {
    if (ready_at == null) {
        readyAtDate.value = moment().add(delayInMinutes.value, 'm').format('YYYY-MM-DD HH:mm:ss');
    } else {
        if(moment(ready_at).format('YYYY-MM-DD') !== moment(readyAtDate.value).format('YYYY-MM-DD')) {
            console.log('date change');
            currentDay.value = moment(ready_at, 'YYYY-MM-DD h:mm A').format('dddd');

            if(moment(calcMaxTime.value, 'hh:mm a').isBefore(moment(moment(ready_at).format('hh:mm a'), 'hh:mm a'))) {
                readyAtDate.value = moment(moment(ready_at).format('YYYY-MM-DD') + ' ' + moment(calcMaxTime.value, 'hh:mm a').format('hh:mm a')).format('YYYY-MM-DD HH:mm:ss');
            } else if (moment(calcMinTime.value, 'hh:mm a').isAfter(moment(moment(ready_at).format('hh:mm a'), 'hh:mm a'))) {
                readyAtDate.value = moment(moment(ready_at).format('YYYY-MM-DD') + ' ' + moment(calcMinTime.value, 'hh:mm a').format('hh:mm a')).format('YYYY-MM-DD HH:mm:ss');
            } else {
                readyAtDate.value = moment(ready_at).format('YYYY-MM-DD HH:mm:ss');
            }
        } else {
            readyAtDate.value = moment(ready_at).format('YYYY-MM-DD HH:mm:ss');
        }
    }
    await order.updateReadyAtTime(ready_type, readyAtDate.value);
};

/**
 * callback from 'ASAP' button to go from scheduled order to asap
 */
const setToAsap = () => {
    readyAsap.value = true;
    readyAtDate.value = moment().add(delayInMinutes.value, 'm').format('YYYY-MM-DD HH:mm:ss');
    updateOrder('asap');
};

const formatDate = (e) => {
    if (e !== null && e !== '') {
        const parsedValue = moment(e);
        readyAsap.value = false;
        updateOrder('scheduled', parsedValue);
    }
};

</script>

<template>
    <div v-if="order.inProgress" class="w-full p-4">
        <div class="mt-1 flex items-center justify-between">
            <p class="font-bold leading-none text-palmers-charcoal-grey">Ready At</p>
            <p
                class="flex h-full cursor-pointer items-center justify-center rounded-r-full px-4 font-semibold uppercase leading-none text-palmers-mush-grey underline supports-hover:hover:opacity-80"
                v-if="!readyAsap && !isCatering && !hideAsap"
                @click="setToAsap"
            >
                ASAP
            </p>
        </div>
        <div class="relative mt-3 text-black">
            <InputGroup
                id="scheduledDate"
                name="scheduledDate"
                :hideOnDateTimeSelect="true"
                :showTime="true"
                :minTime="calcMinTime"
                :maxTime="calcMaxTime"
                :model-value="moment(readyAtDate).toDate()"
                @update:model-value="($event) => formatDate($event)"
                :placeholder="
                    isCatering
                        ? moment().add(1, 'week').format('MMMM DD, YYYY').toString()
                        : readyAsap
                          ? 'ASAP'
                          : moment(adjustedTime).format('YYYY-MM-DD HH:mm').toString()
                "
                :type="DatePicker"
                :minDate="
                    isCatering
                        ? moment().add(1, 'week').format('YYYY-MM-DD HH:mm')
                        : moment(adjustedTime)
                "
                :disabledDaysOfWeek="closedDaysOfWeek"
                :closedDays="closedHolidays"
                class="text-gray-600"
                required
            />
        </div>
    </div>
</template>
