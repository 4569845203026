<script setup>
import { inject, computed } from 'vue';
import { switcherProps } from '@/customer/Components/Order/Internal/props';
import ProgressSwitcher from '../ProgressSwitcher.vue';
import { orderState } from '@/customer/Utils/keys';
import moment from 'moment';
import ModalToggle from '@/customer/Pages/Checkout/Partials/OrderSummaryModal/ModalToggle.vue';
import { usePage } from '@inertiajs/vue3';
import useOrderDelay from '@/company/Composables/useOrderDelay';

const props = defineProps({
    ...switcherProps,
    isCheckout: {
        type: Boolean,
        default: false,
    },
});

const order = inject(orderState);
const orderDelay = useOrderDelay(order);

const scheduled_at = computed(() => moment(order.form.ready_at));
const label = computed(() =>
    scheduled_at.value.diff(moment().add(orderDelay.delayInMinutes.value, 'minutes')) !== 0
        ? (order.form.ready_type == 'scheduled'
            ? moment(scheduled_at.value).format('MMM Do @ h:mm a')
            : `ASAP (~${orderDelay.delayInMinutes.value} min)`)
        : props.label,
);

const dropdownDisabled = computed(() => !(order.isStep('ready_type') || order.isStep('location')));
</script>

<template>
    <ProgressSwitcher
        @open-modal="$emit('open-modal', 'ready_type')"
        :checkout-modal="true"
        :is-checkout="isCheckout"
        v-bind="{ ...props, label, dropdownDisabled }"
    >
        <template #dropdown.content>
            <div class="bg-palmers-himalayan-pink [@media(width>894px)]:-mt-3">
                <div class="flex items-center justify-center space-x-3 whitespace-pre p-5 px-8">
                    <span>Need it at a different time?</span>
                    <ModalToggle step="ready_type" @open-modal="$emit('open-modal', $event)" />
                </div>
            </div>
        </template>
    </ProgressSwitcher>
</template>
