<script setup>
import { inject, computed } from 'vue';
import { orderState } from '@/customer/Utils/keys';
import { Link } from '@inertiajs/vue3';
import { switcherProps } from '@/customer/Components/Order/Internal/props';
import ProgressSwitcher from '../ProgressSwitcher.vue';
import ModalToggle from '@/customer/Pages/Checkout/Partials/OrderSummaryModal/ModalToggle.vue';

const order = inject(orderState);

const props = defineProps({
    ...switcherProps,
    isCheckout: {
        type: Boolean,
        default: false,
    },
});

const dropdownDisabled = computed(() => order.isStep('fulfillment_type') && !order.inProgress);
const label = computed(() =>
    order.progress.fulfillment_type === 'carryout' ? 'In-Store' : 'Delivery',
);
const tip = computed(() =>
    order.progress.fulfillment_type === 'carryout' ? 'Need it delivered?' : 'Want to pick it up?',
);
</script>

<template>
    <ProgressSwitcher
        @open-modal="$emit('open-modal', 'fulfillment_type')"
        :checkout-modal="true"
        :is-checkout="isCheckout"
        v-bind="{ ...props, dropdownDisabled, label }"
    >
        <template #dropdown.content>
            <div class="bg-palmers-himalayan-pink [@media(width>894px)]:-mt-3">
                <div class="flex items-center justify-center space-x-3 whitespace-pre p-5 px-8">
                    <span>{{ tip }}</span>
                    <ModalToggle
                        step="fulfillment_type"
                        @open-modal="$emit('open-modal', $event)"
                    />
                </div>
            </div>
        </template>
    </ProgressSwitcher>
</template>
