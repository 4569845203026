<script setup>
import { ref } from 'vue';
import InputText from '@/customer/Components/InputText.vue';
import moment from 'moment';

const props = defineProps();

const month = defineModel('month');
const year = defineModel('year');

const maskPattern = ref('0M/##');

const inputValue = defineModel(
    { default: '' },
    {
        set(value) {
            if (value.startsWith('1')) {
                if (value[1] === '/') {
                    maskPattern.value = '0M/##';
                } else {
                    maskPattern.value = '1m/##';
                }
            } else if (value.startsWith('0')) {
                maskPattern.value = 'ZM/##';
            } else {
                maskPattern.value = '0M/##';
            }

            const [m, y] = value.split('/');
            month.value = m;
            year.value = y;

            return value;
        },
    },
);

inputValue.value = [month.value, year.value].filter((x) => x).join('/');

const eagerDisabled = ref(false);

const tokens = {
    '#': { pattern: /[0-9]/, required: true, transform: (ch) => ch },
    m: { pattern: /[0-2]/ },
    M: { pattern: /[1-9]/ },
    1: { pattern: /1/ },
    Z: { pattern: /0/ },
};

const keyUp = (e) => {
    eagerDisabled.value = e.key === 'Backspace';
};

defineExpose({ modelValue: inputValue });
</script>

<template>
    <div class="relative">
        <InputText
            v-model="inputValue"
            placeholder="MM / YY"
            @keyup="keyUp"
            :mask-options="{
                mask: maskPattern,
                tokens,
                eager: !eagerDisabled && inputValue.length >= 2,
            }"
        />
    </div>
</template>
