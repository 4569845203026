<script setup>
import { MenuType } from '@/common/Utils/enums.js';
import CateringCheckoutButton from './CateringCheckoutButton.vue';
import { useCheckoutStore } from '@/customer/Composables';

defineProps({
    preventCheckout: {
        type: Boolean,
        default: false,
        required: false,
    },
});

const checkout = useCheckoutStore();
</script>

<template>
    <div
        class="item-center -mt-2 flex flex-wrap items-start gap-6 text-palmers-charcoal-grey md:-mt-4 md:flex-nowrap"
    >
        <a
            :href="route('customer.menu.index', { type: MenuType.MAIN })"
            class="h-min w-full rounded-xl border-2 border-palmers-charcoal-grey bg-white p-4 text-center text-xl font-bold shadow-lg supports-hover:hover:bg-palmers-avocado-green md:w-1/2"
            id="still_hungry"
        >
            NEED MORE?
        </a>

        <div class="flex w-full flex-col items-start space-x-2 md:w-1/2">
            <CateringCheckoutButton :prevent-checkout="preventCheckout" />
            <div class="mt-1 pl-2 text-white" v-if="checkout.failed">Invalid.</div>
        </div>
    </div>
</template>
