<script setup>
import { useMenuItemStore } from '@/customer/Composables';
import { Link } from '@inertiajs/vue3';

const $store = useMenuItemStore();
</script>

<template>
    <Link
        :href="$store.start"
        class="btn group mt-4 block w-full bg-palmers-dragon-fruit p-4 text-center text-2xl font-bold uppercase text-white ring-inset active:bg-palmers-havarti active:text-palmers-mush-grey active:ring-2 active:ring-palmers-dragon-fruit supports-hover:hover:bg-palmers-dragon-fruit/55 supports-hover:hover:ring-2 supports-hover:hover:ring-palmers-dragon-fruit"
    >
        Start Order
    </Link>
</template>
