<script setup>
import { MenuType } from '@/common/Utils/enums.js';
import CheckoutButton from './CheckoutButton.vue';
import { useCartStore, useCheckoutStore, useOrderStore } from '@/customer/Composables';
import { Tooltip } from '@/customer/Components';
import { computed } from 'vue';

defineProps({
    preventCheckout: {
        type: Boolean,
        default: false,
        required: false,
    },
});

const checkout = useCheckoutStore();
const order = useOrderStore();
const cart = useCartStore();
const hasInvalidMessages = computed(() => {
    if (cart.items && typeof cart.items === 'object') {
        const itemsArray = Object.values(cart.items);
        const hasInvalidMessage = itemsArray.some((item) => item.unavailable_message !== null);
        return hasInvalidMessage;
    }
    return false;
});
</script>

<template>
    <div
        class="item-center -mt-2 flex flex-wrap items-start gap-6 text-palmers-charcoal-grey md:-mt-4 md:flex-nowrap"
    >
        <a
            :href="route('customer.menu.index', { type: MenuType.MAIN })"
            class="h-min w-full rounded-xl border-2 border-palmers-charcoal-grey bg-white p-4 text-center text-xl font-bold shadow-lg supports-hover:hover:bg-palmers-avocado-green md:w-1/2"
            id="still_hungry"
        >
            STILL HUNGRY?
        </a>
        <Tooltip
            v-if="hasInvalidMessages"
            message="Please check your cart"
            class="flex w-full items-start space-x-2 md:w-1/2"
        >
            <a
                href="#"
                class="w-full cursor-not-allowed space-x-2 rounded-xl border-2 border-palmers-charcoal-grey bg-white p-4 text-center text-xl font-bold shadow-lg disabled:bg-neutral-200 disabled:bg-opacity-80 disabled:supports-hover:hover:bg-neutral-200 disabled:supports-hover:hover:bg-opacity-80"
            >
                PLACE ORDER
            </a>
        </Tooltip>
        <Tooltip
            v-else-if="order.isDelivery && cart.summary.subtotal.raw < 60"
            message="Deliveries must be at least $60"
            class="flex w-full items-start space-x-2 md:w-1/2"
        >
            <a
                href="#"
                class="w-full cursor-not-allowed space-x-2 rounded-xl border-2 border-palmers-charcoal-grey bg-white p-4 text-center text-xl font-bold shadow-lg disabled:bg-neutral-200 disabled:bg-opacity-80 disabled:supports-hover:hover:bg-neutral-200 disabled:supports-hover:hover:bg-opacity-80"
            >
                PLACE ORDER
            </a>
        </Tooltip>
        <div v-else class="flex w-full flex-col items-start space-x-2 md:w-1/2">
            <CheckoutButton :prevent-checkout="preventCheckout" />
            <div class="mt-1 pl-2 text-white" v-if="checkout.failed">Invalid.</div>
        </div>
    </div>
</template>
