<script setup>
import Accordion from '@/customer/Components/UI/Accordion/Accordion.vue';
import {
    CheckboxGroupInput,
    CheckboxGroupInputSingle,
    RadioGroupInput,
} from '@/customer/Components';
import { EmptyBoundary } from '@/common/Components';
import { InputGroup } from '@/company/Components';
import { computed, watch, ref } from 'vue';
import { mapValues } from 'lodash';
import { useMenuItemStore, useOrderStore } from '@/customer/Composables';
import { arrToObj } from '@/common/Utils';
import ComboItemListView from './ComboItemListView.vue';
import { useForm } from '@inertiajs/vue3';
import Spinner from '@/common/Components/UI/Progress/Spinner.vue';
import InnerListView from './InnerListView.vue';

const emit = defineEmits(['update:selections', 'update:quantities']);

const $store = useMenuItemStore();
const orderStore = useOrderStore();

const props = defineProps({
    comboItemOption: {
        type: Object,
        default: () => ({}),
    },
    selections: {
        type: [Object, Array],
        default: () => [],
    },
    quantities: {
        type: [Object, Array],
        default: () => [],
    },
    expanded: {
        type: Boolean,
        required: true,
    },
    handleAccordion: {
        type: Function,
        required: true,
    },
});

const form = useForm({
    innerConfigQuantities: $store.innerConfigQuantities,
    innerSelections: $store.selectedInnerConfigChoices,
});

const SelectionComponent = computed(() => {
    const { required } = props.comboItemOption;
    return required ? RadioGroupInput : CheckboxGroupInputSingle;
});

const handleSelections = ($event) => {
    const quantities = $store.getConfigQuantities(props.comboItemOption.id);
    const newQuantities = mapValues(arrToObj($event), (id) => Math.max(quantities[id] ?? 1, 1));
    $store.setConfigQuantities(props.comboItemOption.id, newQuantities);
};

const handleQuantities = ($event) => {
    const quantities = $store.getConfigQuantities(props.comboItemOption.id);
    const newQuantities = Object.assign(quantities, $event);
    $store.setConfigQuantities(props.comboItemOption.id, newQuantities);
};

const accordionLabel = computed(() => {
    const optionAdditionalPrice = props.comboItemOption.price.find(
        (priceObj) => priceObj.size.id === $store.form.size,
    );

    if (optionAdditionalPrice?.price?.formatted == '$0.00') {
        return props.comboItemOption.label;
    } else {
        return props.comboItemOption.label + ' +' + optionAdditionalPrice?.price?.formatted;
    }
});

const isUpgrade = computed(() => props.comboItemOption.type === 'upgrade');
const requiredConfigurations = ref();
const requiredConfigurationIds = ref();

const filteredComboChoices = computed(() => {
    return props.comboItemOption.items.filter((choice) => {
        if (choice.item.type !== 'schedule') {
            if (choice.activeLocations) {
                return choice.activeLocations.includes(orderStore.selectedLocation?.id);
            }
            return true;
        }
        return false;
    });
});

watch(
    () => props.selections,
    (newVal) => {
        if (!newVal || !newVal[0]) return;

        const selectedId = newVal[0];
        const selectedItem = props.comboItemOption.items.find((item) => item.id === selectedId);

        if (!selectedItem || !selectedItem.choice) return;

        const { type } = selectedItem.item;

        if (type !== 'configurable' && type !== 'preConfigured') return;

        const waitForConfigurations = () => {
            if (!selectedItem.item?.configurations) {
                setTimeout(waitForConfigurations, 100); // Wait for 100ms and check again
                return;
            }
            requiredConfigurations.value = selectedItem.item.configurations.filter(
                (config) => config.required,
            );
            requiredConfigurationIds.value = requiredConfigurations.value.map(
                (config) => config.id,
            );
            $store.setRequiredInnerConfigs(requiredConfigurationIds.value);
        };

        waitForConfigurations();
    },
    { immediate: true, deep: true },
);
</script>

<template>
    <Accordion
        :is-upgrade="isUpgrade"
        :label="accordionLabel"
        :expanded="expanded"
        :handleAccordion="props.handleAccordion"
        :id="comboItemOption.id"
        class="border-x-2 border-b-2"
        :class="[isUpgrade ? 'my-2 border-t-2' : '']"
        :no-space="true"
    >
        <template #label="{ label }">
            <div class="flex items-center space-x-2">
                <span
                    >{{ label }}
                    <span v-if="comboItemOption.required" class="required">*</span></span
                >
            </div>
        </template>
        <template #default>
            <div v-if="comboItemOption.description" class="text-gray-600">
                {{ comboItemOption.description }}
            </div>
            <EmptyBoundary v-model="SelectionComponent" message="Unknown selection type">
                <InputGroup
                    :label="true"
                    :type="SelectionComponent"
                    :options="filteredComboChoices"
                    value-key="id"
                    label-key="item.name"
                    :model-value="selections"
                    @update:model-value="handleSelections"
                    :quantities="quantities"
                    @update:quantities="handleQuantities"
                />
            </EmptyBoundary>
            <div v-for="item in comboItemOption.items" :key="item.item.id">
                <div v-if="item.item.type === 'schedule'">
                    <!-- <div v-for="configuration in item.item.configurations" :key="configuration.id"> -->
                    <InnerListView
                        :parentComboItemOption="comboItemOption.id"
                        :parentComboItem="comboItemOption.combo_item_id"
                        :choiceId="item.item.item_id"
                        :adderPricingSize="item.adder_pricing_size"
                        :menuItem="item.item"
                        :name="item.item.name"
                        :hideUpgrades="item.hide_upgrades"
                        :price="item.price"
                        v-model:innerConfigSelections="$store.selectedInnerConfigChoices[item.id]"
                        v-model:innerConfigQuantities="$store.innerConfigQuantities[item.id]"
                    />
                    <!-- </div> -->
                </div>
                <div v-else>
                    <div v-if="selections == item.item.id">
                        <div class="mt-4" v-if="item.item.type !== 'simple'">
                            <Spinner
                                class="m-auto size-16 text-palmers-dragon-fruit"
                                v-if="!item.item.configurations || !item.item.configurations.length"
                            />
                            <div v-if="item.item.configurations && item.item.configurations.length">
                                <ComboItemListView
                                    :parentComboItemOption="comboItemOption.id"
                                    :parentComboItem="comboItemOption.combo_item_id"
                                    :choiceId="item.id"
                                    :adderPricingSize="item.adder_pricing_size"
                                    :menuItem="item.item"
                                    :name="item.item.name"
                                    :hideUpgrades="item.hide_upgrades"
                                    :price="item.price"
                                    v-model:innerConfigSelections="
                                        $store.selectedInnerConfigChoices[item.id]
                                    "
                                    v-model:innerConfigQuantities="
                                        $store.innerConfigQuantities[item.id]
                                    "
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Accordion>
</template>
