import { states } from '@/common/Utils/states';
import zipState from 'zip-state';

export const checkStateZip = (state: string, zip: string): boolean => {
    state = states.find((item) => item.full === state).abbrev;
    let zipStateFlag: boolean = false;
    if (state) {
        const zipStateCheck = zipState(zip)?.toLowerCase();
        const providedState = state.toLowerCase();
        zipStateFlag = zipStateCheck === providedState;
    }
    return zipStateFlag;
};
