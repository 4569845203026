<script setup>
import { useLang } from '@/customer/Composables';

const { lang } = useLang();

defineProps({
    title: {
        default: null,
    },
});
</script>

<template>
    <section id="profile" class="mx-auto w-full max-w-screen-md space-y-4 pt-6 md:pt-0">
        <h2 class="px-4 text-2xl font-bold uppercase tracking-wider md:px-0">
            {{ title ?? lang.title }}
        </h2>
        <slot></slot>
    </section>
</template>
