<script setup>
import { computed, ref, watch, onBeforeMount } from 'vue';
import { router, usePage } from '@inertiajs/vue3';
import { useMenuItemStore, useCartStore, useOrderStore } from '@/customer/Composables';
import { MenuType } from '@/common/Utils/enums';
import { formatCurrency } from '@/customer/Utils';
import { Tooltip } from '@/customer/Components';
import Spinner from '@/common/Components/UI/Progress/Spinner.vue';

const props = defineProps({
    canClick: { type: Boolean },
    quantity: { type: Number },
    innerConfigQuantities: { type: Array },
    editing: { type: Boolean },
});

const $store = useMenuItemStore();
const $orderStore = useOrderStore();

const cart = useCartStore();
const loading = ref(false);

const emit = defineEmits(['loading-state']);

const addToCartHandler = async () => {
    loading.value = true;
    emit('loading-state', true);
    await $store.addToCart(props.quantity);
    router.get(
        route('customer.menu.index', {
            type: 'menu',
        }),
    );
    cart.open();
    loading.value = false;
    emit('loading-state', false);
};

const updateCartHandler = () => {
    $store.updateCart(props.quantity);
    router.get(
        route('customer.menu.index', {
            type: 'menu',
        }),
    );
    cart.open();
};

const choicePrice = computed(() => $store.totalChoicePrice + $store.totalInnerChoicePrice);
const totalPriceWithoutChoice = ref($store.price.raw);

onBeforeMount(async () => {
    if ($store.menuItem.editing) {
        await cart.fetchCart();
        totalPriceWithoutChoice.value = cart.items[$store.menuItem.editing].price.raw;
    }
});

watch(
    () => $store.price.raw,
    (newVal) => (totalPriceWithoutChoice.value = newVal),
);

const price = computed(() =>
    formatCurrency((totalPriceWithoutChoice.value + choicePrice.value) * props.quantity),
);

const preventBulkCheckout = computed(() => {
    return $store.preventBulkCheckout;
});

const requiredSelectionsLeft = computed(() => {
    const leftToChoose = [];
    if($store.menuItem.type == 'configurable' || $store.menuItem.type == 'preConfigured' || $store.menuItem.type == 'schedule') {
        $store.requiredConfigurations.map((config) => {
            if($store.quantities[config.id].length < 1 || Object.keys($store.quantities[config.id]).length === 0) {
                leftToChoose.push(config.label);
            }
        })
    } else if($store.menuItem.type == 'combination') {
        $store.requiredComboItemOptions.map((option) => {
            if($store.quantities[option.id].length < 1 || Object.keys($store.quantities[option.id]).length === 0) {
                leftToChoose.push(option.label);
            }
        })
        if($store.getRequiredInnerConfigs() !== undefined) {
            Object.entries($store.innerConfigQuantities).map((option) => {
                let key = option[0]
                $store.getRequiredInnerConfigs().map((requiredInnerConfig) => {
                    if(requiredInnerConfig in $store.innerConfigQuantities[key]) {
                        if($store.innerConfigItems[key]) {
                            if($store.innerConfigItems[key].item.configurations.filter((config) => config.id == requiredInnerConfig)[0]) {
                                if($store.innerConfigQuantities[key][requiredInnerConfig].length < 1 || Object.keys($store.innerConfigQuantities[key][requiredInnerConfig]).length === 0) {
                                    leftToChoose.push($store.innerConfigItems[key].item.configurations.filter((config) => config.id == requiredInnerConfig)[0].label)
                                }
                            }
                        }
                    }
                })

            })
        }
        
    }

    return leftToChoose;
})

</script>

<template>
    <template v-if="editing">
        <Tooltip
            v-if="usePage().props.menuItem.menuType !== 'menu'"
            message="This item is not from the regular menu"
        >
            <button
                @click="updateCartHandler"
                class="flex w-full gap-x-1.5 bg-palmers-dragon-fruit px-4 py-3 text-2xl font-bold uppercase text-white sm:px-8"
                data-button="update-cart"
                :class="
                    $store.testCanAddToCart() && !preventBulkCheckout
                        ? 'supports-hover:hover:bg-palmers-dragon-fruit/55 supports-hover:hover:ring-2 supports-hover:hover:ring-palmers-dragon-fruit group ring-inset active:bg-palmers-havarti active:text-palmers-mush-grey active:ring-2 active:ring-palmers-dragon-fruit'
                        : 'cursor-not-allowed bg-opacity-80'
                "
                :disabled="!$store.testCanAddToCart() || preventBulkCheckout"
            >
                <span class="hidden sm:block">{{
                    $orderStore.isCatering ? 'Update quote' : 'Update cart'
                }}</span>
                <span class="block sm:hidden">Update</span>
                <span v-if="price" class="font-normal">-</span>
                <span v-if="price" class="font-normal">{{ price }}</span>
            </button>
        </Tooltip>
        <Tooltip v-else-if="requiredSelectionsLeft.length" :message="'Please select an option for: ' + requiredSelectionsLeft.join(', ')">
            <button
                @click="updateCartHandler"
                class="flex w-full gap-x-1.5 bg-palmers-dragon-fruit px-4 py-3 text-2xl font-bold uppercase text-white sm:px-8"
                data-button="update-cart"
                :class="
                    props.canClick && $store.testCanAddToCart() && !preventBulkCheckout
                        ? 'group ring-inset active:bg-palmers-havarti active:text-palmers-mush-grey active:ring-2 active:ring-palmers-dragon-fruit supports-hover:hover:bg-palmers-dragon-fruit/55 supports-hover:hover:ring-2 supports-hover:hover:ring-palmers-dragon-fruit'
                        : 'cursor-not-allowed bg-opacity-80'
                "
                :disabled="!$store.testCanAddToCart() || preventBulkCheckout || !props.canClick"
            >
                <span class="hidden sm:block">{{
                    $orderStore.isCatering ? 'Update quote' : 'Update cart'
                }}</span>
                <span class="block sm:hidden">Update</span>
                <span v-if="price" class="font-normal">-</span>
                <span v-if="price" class="font-normal">{{ price }}</span>
            </button>
        </Tooltip>
        <template v-else>
            <div class="flex">
                <button
                    @click="updateCartHandler"
                    class="flex w-full gap-x-1.5 bg-palmers-dragon-fruit px-4 py-3 text-2xl font-bold uppercase text-white sm:px-8"
                    data-button="update-cart"
                    :class="
                        $store.testCanAddToCart() && !preventBulkCheckout
                            ? 'group ring-inset active:bg-palmers-havarti active:text-palmers-mush-grey active:ring-2 active:ring-palmers-dragon-fruit supports-hover:hover:bg-palmers-dragon-fruit/55 supports-hover:hover:ring-2 supports-hover:hover:ring-palmers-dragon-fruit'
                            : 'cursor-not-allowed bg-opacity-80'
                    "
                    :disabled="!$store.testCanAddToCart() || preventBulkCheckout"
                >
                    <span class="hidden sm:block">{{
                        $orderStore.isCatering ? 'Update quote' : 'Update cart'
                    }}</span>
                    <span class="block sm:hidden">Update</span>
                    <span v-if="price" class="font-normal">-</span>
                    <span v-if="price" class="font-normal">{{ price }}</span>
                </button>
                <Spinner class="ml-5 mt-2 h-10 w-10 text-palmers-dragon-fruit" v-if="loading" />
            </div>
        </template>
    </template>
    <template v-else>
        <Tooltip
            v-if="usePage().props.menuItem.menuType !== 'menu'"
            message="This item is not from the regular menu"
        >
            <button
                @click="addToCartHandler"
                class="flex w-full gap-x-1.5 bg-palmers-dragon-fruit px-4 py-3 text-2xl font-bold uppercase text-white sm:px-8"
                data-button="add-to-cart"
                :class="
                    props.canClick &&
                    usePage().props.menuItem.menuType === $orderStore.form.menu_type &&
                    !preventBulkCheckout
                        ? 'supports-hover:hover:bg-palmers-dragon-fruit/55 supports-hover:hover:ring-2 supports-hover:hover:ring-palmers-dragon-fruit group ring-inset active:bg-palmers-havarti active:text-palmers-mush-grey active:ring-2 active:ring-palmers-dragon-fruit'
                        : 'cursor-not-allowed bg-opacity-80'
                "
                :disabled="
                    !props.canClick ||
                    usePage().props.menuItem.menuType !== $orderStore.form.menu_type ||
                    preventBulkCheckout
                "
            >
                <span class="hidden sm:block">{{
                    $orderStore.isCatering ? 'Add to quote' : 'Add to cart'
                }}</span>
                <span class="block sm:hidden">Add</span>
                <span v-if="price" class="font-normal">-</span>
                <span v-if="price" class="font-normal">{{ price }}</span>
            </button>
        </Tooltip>
        <Tooltip v-else-if="requiredSelectionsLeft.length" :message="'Please select an option for: ' + requiredSelectionsLeft.join(', ')">
            <button
                @click="addToCartHandler"
                class="flex w-full gap-x-1.5 bg-palmers-dragon-fruit px-4 py-3 text-2xl font-bold uppercase text-white sm:px-8"
                data-button="add-to-cart"
                :class="
                    props.canClick &&
                    usePage().props.menuItem.menuType === $orderStore.form.menu_type &&
                    !preventBulkCheckout
                        ? 'group ring-inset active:bg-palmers-havarti active:text-palmers-mush-grey active:ring-2 active:ring-palmers-dragon-fruit supports-hover:hover:bg-palmers-dragon-fruit/55 supports-hover:hover:ring-2 supports-hover:hover:ring-palmers-dragon-fruit'
                        : 'cursor-not-allowed bg-opacity-80'
                "
                :disabled="
                    !props.canClick ||
                    usePage().props.menuItem.menuType !== $orderStore.form.menu_type ||
                    preventBulkCheckout
                "
            >
                <span class="hidden sm:block">{{
                    $orderStore.isCatering ? 'Add to quote' : 'Add to cart'
                }}</span>
                <span class="block sm:hidden">Add</span>
                <span v-if="price" class="font-normal">-</span>
                <span v-if="price" class="font-normal">{{ price }}</span>
            </button>
        </Tooltip>
        <template v-else>
            <div class="flex">
                <button
                    @click="addToCartHandler"
                    class="flex w-full gap-x-1.5 bg-palmers-dragon-fruit px-4 py-3 text-2xl font-bold uppercase text-white sm:px-8"
                    data-button="add-to-cart"
                    :class="
                        props.canClick && !preventBulkCheckout
                            ? 'supports-hover:hover:bg-palmers-dragon-fruit/55 supports-hover:hover:ring-2 supports-hover:hover:ring-palmers-dragon-fruit group ring-inset active:bg-palmers-havarti active:text-palmers-mush-grey active:ring-2 active:ring-palmers-dragon-fruit'
                            : 'cursor-not-allowed bg-opacity-80'
                    "
                    :disabled="!props.canClick || preventBulkCheckout"
                >
                    <span
                        class="supports-hover:group-hover:drop-shadow-sm hidden group-active:drop-shadow-none sm:block"
                        >{{ $orderStore.isCatering ? 'Add to quote' : 'Add to cart' }}</span
                    >
                    <span class="block sm:hidden">Add</span>
                    <span v-if="price" class="font-normal">-</span>
                    <span v-if="price" class="font-normal">{{ price }}</span>
                </button>
                <object
                    v-if="loading"
                    class="ml-5 mt-2 flex aspect-square h-full animate-spin items-center justify-center"
                >
                    <Spinner class="h-10 w-10 text-palmers-dragon-fruit" />
                </object>
            </div>
        </template>
    </template>
</template>
