<script setup>
import { EmptyBoundary } from '@/common/Components';
import { ref, computed, watch } from 'vue';
import { GridItem } from '.';
import { useMenuItemStore } from '@/customer/Composables';
import { PlusIcon } from '@heroicons/vue/24/outline';

const $store = useMenuItemStore();

const emit = defineEmits(['update:quantities']);

const props = defineProps({
    price: { type: String },
    quantities: { type: Object, default: () => ({}) },
});

/**
 * the SelectionSummary had variable height that was bugging
 * the position of the fixed bar.
 * For better UX, if the customer has selected only 1 or 2 options
 * we create placeholders that indicate they should add more items
 * based on the amount of empty selections.
 */

 const choices = computed(() => {
    return Object.values($store.choiceMap).filter((choice) => choice.layout !== 'list-view').sort((a, b) => a.sort_order - b.sort_order);
});
const emptyChoices = computed(() => {
    const choicesArr = Object.keys(choices.value);
    if (choicesArr.length === 1) {
        return ['', ''];
    } else if (choicesArr.length === 2) {
        return [''];
    } else {
        return [];
    }
});

/**
 * since scroll is disabled with the placeholders visible
 * if someone has enough selections where the empty placeholders
 * aren't visible, but then scrolls to the right and deselects options
 * to the point where scroll is disabled again, they won't be able to fully
 * see the first option in SelectionSummary, so instead we scroll back over for them.
 */
const summaryContainer = ref(null);
watch(
    () => emptyChoices.value,
    (array) => {
        if (array.length > 0) {
            summaryContainer.value?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    },
);

const handleQuantity = (option, $event) => {
    const newQuantities = {
        ...props.quantities,
        [option.id]: $event,
    };
    emit('update:quantities', newQuantities);
};

console.log($store.menuItem, 'menu item');
</script>

<template>
    <div class="space-y-3">
        <div class="text-2xl font-bold uppercase" v-if="$store.menuItem.hasOptions">
            {{ `${$store.menuItem.selectionSummaryLabel ?? 'Your Item'}:` }}
        </div>
        <!-- side-scrollable grid list of selected items -->
        <div
            ref="summaryContainer"
            class="relative flex h-[16rem] space-x-2"
            :class="[emptyChoices.length !== 0 ? 'overflow-x-hidden' : 'overflow-x-auto']"
            v-if="$store.menuItem.hasOptions"
        >
            <div
                v-if="Object.keys(choices).length === 0"
                class="absolute bottom-0 left-0 right-0 top-0 flex h-full items-center justify-center bg-gray-100"
            >
                <p class="w-60 text-center text-lg text-gray-500">
                    Make selections to see a summary here
                </p>
            </div>
            <EmptyBoundary :model-value="choices" message="na">
                <template v-for="option in choices" :key="option.id">
                    <div class="h-full bg-palmers-light-green">
                        <GridItem
                            :customizable="option.customizable"
                            :choice="option.choice"
                            :model-value="quantities[option.configuration_id][option.id]"
                            @update:model-value="handleQuantity(option, $event)"
                            choice-name-section-class="text-center font-bold"
                            image-section-class="relative w-full items-center justify-center"
                            quantity-section-class="absolute space-y-3 left-0 right-0 px-3 pt-1.5 bottom-4 "
                            :active="true"
                            class="min-w-[9rem]"
                        />
                    </div>
                </template>
                <template v-for="(empty, index) in emptyChoices" :key="`emptyOption${index}`">
                    <div
                        class="relative flex h-full min-h-12 w-full min-w-[9rem] select-none items-center justify-center border-2 transition-all duration-150"
                    >
                        <!-- <PlusIcon class="size-16 text-gray-200 opacity-80" /> -->
                        <div class="relative flex size-8 items-center justify-center">
                            <div class="absolute h-[2.5px] w-full bg-gray-200" />
                            <div class="absolute h-full w-[2.5px] bg-gray-200" />
                        </div>
                    </div>
                </template>
            </EmptyBoundary>
        </div>

        <slot />
    </div>
</template>
