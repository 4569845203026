<script setup>
import { ref, onMounted } from 'vue';
import { useCheckoutStore } from '@/customer/Composables';
import { Spinner } from '@/common/Components';

const cartItems = ref(null);
const checkout = useCheckoutStore();
checkout.initialize();
</script>

<template>
    <div class="absolute inset-0 grid grid-cols-1 xl:grid-cols-12">
        <div class="relative col-span-5 flex flex-col xl:order-last">
            <div class="absolute bottom-0 left-0 top-0 w-screen bg-white text-black" />
            <div
                v-if="checkout.processing"
                class="absolute bottom-0 left-0 top-0 z-20 w-screen cursor-wait bg-black opacity-50"
            ></div>
            <div class="inset-0 z-10 overflow-y-auto md:absolute" ref="cartItems">
                <div class="px-3 md:px-6 md:pl-12 md:pt-12">
                    <slot name="cart" />
                </div>

                <div class="relative z-10">
                    <div class="absolute inset-y-0 left-0 w-screen bg-palmers-havarti"></div>
                    <div class="relative z-10">
                        <slot name="addons" />
                    </div>
                </div>
            </div>
        </div>
        <div class="relative col-span-7 flex justify-start pt-8 text-white">
            <div class="absolute bottom-0 right-0 top-0 w-screen bg-palmers-dragon-fruit" />
            <div
                v-if="checkout.processing"
                class="absolute bottom-0 right-0 top-0 z-20 w-screen cursor-wait bg-black opacity-50"
            ></div>
            <div class="inset-0 overflow-auto px-6 md:absolute">
                <div class="z-10 pb-12 md:py-12 md:pr-12">
                    <slot name="form" />
                </div>
            </div>
        </div>
        <div
            class="absolute inset-0 z-20 flex cursor-wait items-center justify-center text-palmers-dragon-fruit"
            v-if="checkout.processing"
        >
            <div class="space-y-4">
                <!-- <span>We're processing your order</span> -->
                <Spinner class="mx-auto h-24 w-24" />
            </div>
        </div>
    </div>
</template>
