<script setup lang="ts">
import InputGroup from '@/customer/Components/InputGroup.vue';
import { string } from 'yup';
import {
    CreditCardNumberInput,
    CreditCardExpirationInput,
    CreditCardCodeInput,
} from '@/customer/Components';
import { useCheckoutStore, useAuthNetStore } from '@/customer/Composables';
import { guessCardVendor, CardVendor } from '@/common/Utils/cards';
import InputSelectState from '@/customer/Components/InputSelectState.vue';
import { watch, ref, computed } from 'vue';
import moment from 'moment';
import { states } from '@/common/Utils/states';
import { checkStateZip } from '@/customer/Composables/useCheckStateZip';
import CheckboxInput from '@/customer/Components/UI/Input/Checkboxes/CheckboxInput.vue';

/**
 * Accept.JS error codes are located here: https://developer.authorize.net/api/reference/features/acceptjs.html#Appendix_Error_Codes
 */

const checkout = useCheckoutStore();
const authnet = useAuthNetStore();

const cityStateZipIsValid = () => {
    const inputState = authnet.responseData.billingDetails.state;
    const inputZip = authnet.requestData.cardData.zip;
    if (
        typeof inputState === 'string' &&
        inputState.length > 0 &&
        typeof inputZip === 'string' &&
        inputZip.length > 0
    ) {
        return checkStateZip(inputState, inputZip);
    }
};

const cardVendor = ref<CardVendor | null>(null);
const cvvLength = computed<number>(() => (cardVendor.value === CardVendor.AMEX ? 4 : 3));

watch(
    () => authnet.requestData.cardData.cardNumber,
    () => {
        cardVendor.value = guessCardVendor(authnet.requestData.cardData.cardNumber);
    },
);

const now = moment();
const month = Number(now.format('M'));
const year = Number(now.format('YY'));
const expirationRegEx = new RegExp(
    `^(0[${month}-9]|1[0-2])\/${year}$|^(0[1-9]|1[0-2])\/(2[5-9]|[3-9]\\d)$`,
);
</script>
<template>
    <div class="space-y-2">
        <div class="text-xl font-bold text-palmers-charcoal-grey">Payment Info</div>
        <div class="grid grid-cols-1 gap-x-4 gap-y-3">
            <InputGroup
                class="w-full text-black"
                label-class="text-palmers-charcoal-grey"
                v-model="authnet.requestData.cardData.fullName"
                label="Name On Card"
                name="name"
                :validator="string().required()"
                :validate="checkout.failed"
                :validator-attributes="['E_WC_17', 'authnet.contactDetails.fullName']"
                error-class="text-[#404041]"
            />
            <InputGroup
                class="w-full text-black"
                label-class="text-palmers-charcoal-grey"
                label="Credit Card Number"
                name="cc-number"
                error-class="text-[#404041]"
                :type="CreditCardNumberInput"
                :validate="checkout.failed"
                :validator="string().required('Enter A Valid Card Number')"
                :validator-attributes="['E_WC_05']"
                v-model="authnet.requestData.cardData.cardNumber"
            />
        </div>
        <div class="grid grid-cols-2 gap-x-4 gap-y-3 sm:grid-cols-3">
            <InputGroup
                :type="CreditCardExpirationInput"
                label="Expiration Date"
                label-class="text-palmers-charcoal-grey"
                name="cc-exp"
                error-class="text-[#404041]"
                v-model:month="authnet.requestData.cardData.month"
                v-model:year="authnet.requestData.cardData.year"
                :validate="checkout.failed"
                :validator="string().required().matches(expirationRegEx, 'Enter Valid Expiration')"
                :validator-attributes="['E_WC_06', 'E_WC_07', 'E_WC_08']"
            />

            <InputGroup
                :type="CreditCardCodeInput"
                class="w-full text-black"
                label-class="text-palmers-charcoal-grey"
                label="CVC"
                name="cc-code"
                error-class="text-[#404041]"
                v-model="authnet.requestData.cardData.cardCode"
                :vendor="guessCardVendor(authnet.requestData.cardData.cardNumber)"
                :validate="checkout.failed"
                :validator-attributes="['E_WC_15']"
                :validator="string().required().length(cvvLength)"
            />
            <InputGroup
                class="col-span-2 w-full text-black sm:col-span-1"
                label-class="text-palmers-charcoal-grey"
                label="Zip Code"
                name="cc-zip"
                error-class="text-[#404041]"
                :model-value="authnet.requestData.cardData.zip"
                @update:model-value="
                    (value) => {
                        authnet.requestData.cardData.zip = value;
                        authnet.responseData.billingDetails.zip = value;
                    }
                "
                :validate="checkout.failed"
                :validator="
                    string()
                        .required()
                        .matches(/^\d{5}(?:-\d{4})?$/)
                        .test('state-zip', 'state-zip', () => {
                            return cityStateZipIsValid();
                        })
                "
                :validator-attributes="['E_WC_16']"
            />
        </div>
        <div class="grid grid-cols-1 gap-2">
            <InputGroup
                class="w-full text-black"
                label-class="text-palmers-charcoal-grey"
                label="Billing Street Address"
                name="cc-street"
                error-class="text-[#404041]"
                :validate="checkout.failed"
                :validator="string().required()"
                v-model="authnet.responseData.billingDetails.street1"
                :validator-attributes="['authnet.billingDetails.street1']"
            />
        </div>
        <div class="grid grid-cols-2 gap-x-4 gap-y-3">
            <InputGroup
                class="w-full text-black"
                label-class="text-palmers-charcoal-grey"
                label="City"
                name="cc-city"
                error-class="text-[#404041]"
                :validate="checkout.failed"
                :validator="string().required()"
                v-model="authnet.responseData.billingDetails.city"
                :validator-attributes="['authnet.billingDetails.city']"
            />
            <InputGroup
                class="w-full text-black"
                label-class="text-palmers-charcoal-grey"
                label="State"
                :type="InputSelectState"
                name="cc-state"
                :states="states"
                error-class="text-[#404041]"
                :validate="checkout.failed"
                :validator="
                    string()
                        .required()
                        .test('state-zip', 'state-zip', () => {
                            return cityStateZipIsValid();
                        })
                "
                v-model="authnet.responseData.billingDetails.state"
                :validator-attributes="['authnet.billingDetails.state']"
            />
            <div class="!mt-4 grid grid-cols-1 gap-2 pl-2">
                <InputGroup
                    class="text-black"
                    :type="CheckboxInput"
                    label-class="text-palmers-charcoal-grey"
                    label="Remember Payment Info"
                    name="saveForLater"
                    v-model="authnet.responseData.saveForLater"
                    :validate="checkout.failed"
                    :validator="string().required()"
                    :validator-attributes="['authnet.saveForLater']"
                    error-class="text-[#404041]"
                />
            </div>
        </div>
    </div>
</template>
