<script setup>
import InputError from '@/customer/Components/InputError.vue';
import InputLabel from '@/customer/Components/InputLabel.vue';
import { default as Button } from '@/customer/Components/BaseButton.vue';
import InputText from '@/customer/Components/InputText.vue';
import { Head, useForm, Link } from '@inertiajs/vue3';
import { ArrowLeftIcon } from '@heroicons/vue/24/solid';

defineProps({
    status: {
        type: String,
        default: null,
    },
});

const form = useForm({
    email: '',
});

const submit = () => {
    form.post(route('customer.password.email'));
};
</script>

<template>
    <Head title="Forgot Password" />

    <div class="mx-auto my-16 w-full max-w-4xl">
        <template v-if="!form.wasSuccessful">
            <div class="text-center">
                <h2 class="mb-2 font-sans text-2xl font-bold uppercase">Forgot password?</h2>
                <span
                    >No problem. Just let us know your email address and we will email you a
                    password reset link that will allow you to set a new one.</span
                >
            </div>

            <div v-if="status" class="mb-4 text-sm font-medium text-green-600">
                {{ status }}
            </div>

            <form @submit.prevent="submit">
                <div class="mt-4 md:mt-0">
                    <InputLabel for="email" value="Email" />

                    <InputText
                        id="email"
                        v-model="form.email"
                        type="email"
                        class="mt-1 block w-full"
                        required
                        autofocus
                        autocomplete="username"
                    />

                    <InputError class="mt-2" :message="form.errors.email" />
                </div>

                <div class="mt-4 flex w-full flex-wrap-reverse items-center justify-end gap-3">
                    <Link
                        :href="route('customer.login')"
                        class="inline-flex space-x-1 pr-2 text-sm font-semibold underline"
                    >
                        <ArrowLeftIcon class="w-4" />
                        <span>Back to Login</span>
                    </Link>

                    <div class="flex grow justify-end">
                        <Button
                            :class="{ 'opacity-25': form.processing }"
                            :disabled="form.processing"
                        >
                            Email Password Reset Link
                        </Button>
                    </div>
                </div>
            </form>
        </template>
        <div v-else class="text-center">
            <h2 class="mb-2 font-sans text-2xl font-bold uppercase">Check your inbox!</h2>
            <p>
                A password reset link was sent to
                <span class="font-semibold">{{ form.email }}</span>
                if an account exists.
            </p>
        </div>
    </div>
</template>
