<script setup>
import { CheckboxGroup, CheckboxGroupOption, CheckboxInput } from '.';
import { get } from 'lodash';
import { RadioGroupProps } from '../internal';
import { computed, getCurrentInstance } from 'vue';
import { func } from '@/common/Utils';
import { InputLabel } from '@/company/Components';
import { useMenuItemStore } from '@/customer/Composables';

const emit = defineEmits(['update:modelValue']);
const $store = useMenuItemStore();
const props = defineProps({
    ...RadioGroupProps,
    adderPricingSize: {
        type: Number,
        required: false,
        default: 0,
    },
    maxQuantityReached: {
        type: Boolean,
        required: true,
    },
});

const optValue = (option) => {
    return get(option, props.valueKey);
};

const optLabel = (option) => {
    return get(option, props.labelKey);
};

const name = computed(() => props.name ?? `checkbox-${getCurrentInstance().uid}`);

const handleUpdate = ($event) => {
    emit('update:modelValue', $event);
};

const priceLabel = (label, option) => {
    if (!option.price.length) {
        return label;
    } else {
        const comparison = props.adderPricingSize ? props.adderPricingSize : $store.form.size;
        const optionAdditionalPrice = option.price.find(
            (priceObj) => priceObj.size.id === comparison,
        );
        if (
            Number(optionAdditionalPrice?.price?.formatted.slice(1)) !== 'NaN' &&
            Number(optionAdditionalPrice?.price?.formatted.slice(1)) > 0
        ) {
            return (
                label +
                ' <span class="text-sm ml-1 font-medium">' +
                `+${optionAdditionalPrice?.price?.formatted}` +
                '</span>'
            );
        } else {
            return label;
        }
    }
};

const gridRowCount = computed(() => {
    const len = props.options.length;
    return len < 5 ? len : Math.ceil(len / 2);
});
</script>

<template>
    <CheckboxGroup
        v-bind="{ by, defaultValue, disabled }"
        :model-value="modelValue"
        @update:model-value="handleUpdate"
        :class="[
            'radioGroup',
            func(wrapperClass)(),
            wrapperClass.length === 0 ? 'grid grid-flow-col gap-y-2' : '',
        ]"
        :style="
            wrapperClass.length === 0
                ? `grid-template-rows: repeat(${gridRowCount}, minmax(0, 1fr));`
                : ''
        "
    >
        <template v-for="option in options" :key="optValue(option)">
            <CheckboxGroupOption
                v-bind="{ name, disabled }"
                :value="optValue(option)"
                :clickable="false"
                v-slot="{ checked, select }"
                as="template"
            >
                <div
                    :class="[
                        'checkboxItem relative flex w-full items-center',
                        props.as?.__name,
                        func(itemClass)(checked),
                    ]"
                >
                    <CheckboxInput
                        v-bind="{ name, as, option, ...$attrs }"
                        :label="!!label ? optLabel(option) : label"
                        :disabled="maxQuantityReached && !checked"
                        tabindex="0"
                        :checked="checked"
                        @update:model-value="select"
                        :class="[maxQuantityReached && !checked ? 'cursor-not-allowed' : '']"
                    >
                        <template #label="{ label, name, help }">
                            <InputLabel
                                :for="name"
                                :value="priceLabel(label, option)"
                                :help="help"
                                as="span"
                                :class="[
                                    maxQuantityReached && !checked ? 'cursor-not-allowed' : '',
                                ]"
                            />
                        </template>
                    </CheckboxInput>
                </div>
            </CheckboxGroupOption>
        </template>
    </CheckboxGroup>
</template>

<style scoped>
.checkboxGroup:not(.grid) {
    @apply space-y-2;
}
.checkboxItem {
    @apply flex items-center justify-start space-x-2 text-sm font-medium leading-none;
}
</style>
