<script setup>
import Form from '@/customer/Pages/Profile/Partials/Form.vue';
import { Head, useForm } from '@inertiajs/vue3';
import AccountSettingsLayout from '@/customer/Layouts/AccountSettingsLayout.vue';
import InputGroup from '@/customer/Components/InputGroup.vue';
import { InputPassword } from '@/customer/Components';
import { useLang } from '@/customer/Composables';

const { lang } = useLang();

const form = useForm(route().current(), {
    current_password: '',
    password: '',
    password_confirmation: '',
});

const submit = () => {
    const url = route('customer.password.update');

    form.put(url, {
        preserveScroll: true,
        onSuccess: () => form.reset(),
        onError: () => {
            if (form.errors.password) {
                form.reset('password', 'password_confirmation');
                passwordInput.value.focus();
            }
            if (form.errors.current_password) {
                form.reset('current_password');
                currentPasswordInput.value.focus();
            }
        },
    });
};
</script>

<template>
    <Head title="Profile" />

    <AccountSettingsLayout class="pb-6 md:pb-0">
        <Form v-bind="{ form }" @submit="submit">
            <InputPassword
                id="current_password"
                v-model="form.current_password"
                :label="lang.current_password"
                name="current_password"
                :error-message="form.errors.current_password"
                type="password"
                class="block w-full"
                required
                autocomplete="current-password"
            />

            <InputPassword
                id="password"
                v-model="form.password"
                :label="lang.password"
                name="password"
                :error-message="form.errors.password"
                type="password"
                class="block w-full"
                required
                :help="lang.password_help"
                autocomplete="password"
            />

            <InputPassword
                id="password_confirmation"
                v-model="form.password_confirmation"
                :label="lang.password_confirmation"
                name="password"
                :error-message="form.errors.password_confirmation"
                type="password"
                class="block w-full"
                required
                autocomplete="password_confirmation"
            />
        </Form>
    </AccountSettingsLayout>
</template>
