<script setup>
import { Link } from "@inertiajs/vue3";
import { PhotoIcon } from "@heroicons/vue/24/solid";
import { useMenuItemStore, useCartStore } from '@/customer/Composables';

defineProps({
    item: { type: Object, required: true },
    as: { type: String, default: "div" },
});

const menuItemStore = useMenuItemStore();
const cartStore = useCartStore();

const addSimpleItemToCart = async (item) => {
    const addToCartObj = formatSimpleCartObject(item, 1);
    await menuItemStore.addToCart(1, addToCartObj);
    cartStore.open();
};

const formatSimpleCartObject = (item, quantity = null) => {
    return {
        innerConfigQuantities: [],
        instructions: null,
        name: null,
        quantities: [],
        size: item.size_id,
        slug: item.slug,
        quantity: quantity,
        single_size: true,
    };
};
</script>

<template>
    <component :is="as" class="flex flex-col items-center cursor-pointer" @click="addSimpleItemToCart(item)">
        <img
            class="w-32 h-32"
            v-if="item.image"
            :src="item.image.original" 
        />
        <PhotoIcon class="w-32 h-32 text-gray-500 opacity-50" v-else/>
        <div class="text-center text-palmers-charcoal-grey">
            <h3 class="font-bold">
                {{ item.name }}
            </h3>
            <p>
                ${{ item.cost }}
            </p>
        </div>
    </component>
</template>
