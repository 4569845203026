<script setup>
import InputTextarea from '@/customer/Components/InputTextarea.vue';
import InputGroup from '@/customer/Components/InputGroup.vue';
import { useMenuItemStore, useOrderStore } from '@/customer/Composables';
import { computed } from 'vue';

const $store = useMenuItemStore();

const $orderStore = useOrderStore();

const props = defineProps({
    name: { type: String, default: null },
    instructions: { type: String, default: null },
});

const name = computed({
    get() {
        return $store.name;
    },
    set(value) {
        $store.setName(value);
    },
});

const instructions = computed({
    get() {
        return $store.instructions;
    },
    set(value) {
        $store.setInstructions(value);
    },
});

const emit = defineEmits(['update:name', 'update:instructions']);
</script>

<template>
    <!-- box with text inputs -->
    <div>
        <template v-if="!$orderStore.isCatering">
            <div class="font-semibold uppercase text-palmers-charcoal-grey">Name for Item</div>

            <!-- name -->
            <InputGroup class="mb-5 w-full" type="text" placeholder="First Name" v-model="name" />
        </template>

        <!-- special instructions -->
        <div class="mb-2 font-semibold uppercase text-palmers-charcoal-grey">
            Special Instructions
        </div>

        <InputTextarea
            class="min-h-[8rem] w-full"
            placeholder="Special Instructions"
            v-model="instructions"
        />
    </div>
</template>
