<script setup>
import { computed, reactive, watch } from 'vue';
import { Head } from '@inertiajs/vue3';
import { MenuType } from '@/common/Utils/enums.js';
import InputText from '@/customer/Components/InputText.vue';
import AddOnItems from '@/customer/Components/Cart/AddOnItems.vue';
import { useOrderStore } from '@/customer/Composables';
import { RadioGroupInput, InputGroup } from '@/customer/Components';

const order = useOrderStore();

const tip_options = [15, 20, 25];

const setTipPercent = (tip) => {
    cart.applyPercentageTip(tip);
};
</script>

<template>
    <button
        class="text-md rounded-full border-2 border-black px-4 py-2 font-bold supports-hover:hover:bg-palmers-avocado-green"
        :class="tip == order?.tip_percent ? 'bg-palmers-avocado-green' : 'bg-white'"
        @click="setTipPercent(tip)"
        v-for="tip in order.tips"
    >
        {{ tip.label }}
    </button>
</template>
