<script setup>
const props = defineProps({
    properties: {
        type: Array,
    },
});
const headerClasses =
    "border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-black text-left";
const bodyClasses =
    "border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-black";
</script>

<template>
    <table class="border-collapse table-auto w-full text-sm mt-4">
        <thead>
            <tr>
                <th :class="[headerClasses]">Property</th>
                <th :class="[headerClasses]">Description</th>
                <th :class="[headerClasses]">Values</th>
            </tr>
        </thead>
        <tbody class="bg-white">
            <tr v-for="property in properties">
                <td :class="[bodyClasses]">
                    {{ property.name }}
                </td>
                <td :class="[bodyClasses]">
                    {{ property.description }}
                </td>
                <td :class="[bodyClasses]">
                    <template v-if="Array.isArray(property.values)">
                        <ul>
                            <li v-for="value in property.values">
                                {{ value }}
                            </li>
                        </ul>
                    </template>
                    <template v-if="!Array.isArray(property.values)">
                        {{ property.values }}
                    </template>
                </td>
            </tr>
        </tbody>
    </table>
</template>
