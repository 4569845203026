<script setup>
import { useAttrs } from 'vue';
import { computed, watch, ref } from 'vue';
const attrs = useAttrs();
const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    modelValue: { default: null },
    options: {
        type: Array,
        default: null,
    },
    placeholder: {
        type: String,
        default: 'Select...',
    },
});
const input = ref('input');
const focus = () => input.value.focus();

const selected = computed(() => !!props.modelValue);
const selection = computed({
    get: () =>
        extendedOptions.value.filter((item) => item.id === Object.values(props.modelValue)[0])[0]
            ?.id,
    set: (value) =>
        emit(
            'update:modelValue',
            value === null
                ? {
                      value: null,
                      quantity: null,
                  }
                : {
                      value: value,
                      quantity: 1,
                  },
        ),
});
const extendedOptions = computed(() => [
    { id: null, choice: { name: props.placeholder } },
    ...props.options,
]);
</script>

<template>
    <select
        ref="input"
        v-model="selection"
        class="block w-full rounded-3xl border-2 border-palmers-dragon-fruit px-5 text-palmers-charcoal-grey focus:border-palmers-dragon-fruit focus:ring-0"
        :class="{ selected }"
    >
        <template v-for="({ id, choice }, index) in extendedOptions" :key="index">
            <option :value="id">{{ choice.name }}</option>
        </template>
    </select>
</template>

<style>
select:not(.selected) {
    color: rgba(107, 114, 128, 0.7);
}

option[value=''][disabled] {
    display: none;
    color: red;
}
</style>
