<script setup>
import { computed } from 'vue';
import { QuantityEditor } from '.';
import { Image } from '@/customer/Components';
import { useMenuItemStore } from '@/customer/Composables';

const emit = defineEmits(['update:modelValue']);

const $store = useMenuItemStore();

const props = defineProps({
    selected: {
        type: Boolean,
        default: false,
    },
    included: {
        type: Boolean,
        default: true,
    },
    active: {
        type: Boolean,
        default: false,
    },
    customizable: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: Number,
    },
    choice: {
        type: Object,
        default: () => ({}),
    },
    choiceNameSectionClass: {
        type: String,
        default: 'text-center font-bold',
    },
    imageSectionClass: {
        type: String,
        default: 'flex grow items-center justify-center',
    },
    quantitySectionClass: {
        type: String,
        default: 'absolute inset-x-0 bottom-4 z-10',
    },
    price: {
        type: Object,
        default: () => ({}),
    },
});

const computedQuantity = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        if (!props.choice.id) {
            console.error({ props });
            throw new Error('expected grid item to have choice id.');
        }
        $store.setQuantity(props.choice.id, value);
    },
});

const active = computed(() => props.active || props.selected);

const computedConfiguration = computed(() => $store.getConfiguration(props.choice.id));
const computedChoice = computed(() => $store.getChoice(props.choice.id));

const quantityAvailableForSelectionType = computed(() => {
    if (computedConfiguration?.value?.selection_type === 'single') {
        return active.value;
    }

    return true;
});

const showQuantity = computed(
    () =>
        props.customizable &&
        computedQuantity.value >= 0 &&
        quantityAvailableForSelectionType.value,
);
const usePrice = computed(() => {
    if(Object.keys(props.price).length) {
        return props.price?.filter(price => price.size.id == $store.form.size)[0]?.price
    } else {
        return {}
    }
});

const max = computed(() => {
    const configuration = $store.getConfiguration(computedChoice.value.id);
    const choice = computedChoice.value;

    if (configuration?.max) {
        if (choice?.max) {
            return Math.min(choice.max, configuration.max - $store.getConfigQuantitySum(choice.configuration_id));
        }
        return (configuration.max - $store.getConfigQuantitySum(choice.configuration_id)) + 1;
    } 
    else if (choice?.max) {
        return choice.max;
    }
    return Number.MAX_SAFE_INTEGER;
});
</script>

<template>
    <div
        class="relative flex h-full min-h-12 w-full min-w-[9rem] grow select-none flex-col overflow-hidden p-1 ring-4 ring-inset transition-all duration-150"
        :class="[selected ? 'ring-palmers-avocado-light' : 'ring-transparent']"
    >
        <div :class="choiceNameSectionClass" class="flex h-min items-center justify-center py-4">
            <p class="w-24">{{ choice.name }}</p>
        </div>

        <div :class="imageSectionClass" class="mb-14 flex h-full justify-center">
            <Image
                :src="choice.image?.original"
                image-container-class="rounded-md overflow-hidden flex w-32 items-center justify-center"
            />
        </div>

        <div
            v-if="showQuantity || usePrice?.raw > 0"
            class="absolute inset-x-0 bottom-1 z-10 mx-2 mb-1 flex justify-center rounded-md bg-gray-200/40 px-3 py-2 backdrop-blur-xl"
            :class="[!showQuantity ? 'w-min' : '']"
        >
            <div :class="{ 'min-w-[120px]': showQuantity }">
                <div :class="{ 'mb-2': showQuantity }" v-if="usePrice?.raw !== 0">
                    {{ usePrice?.formatted }}
                </div>
                <div :class="[usePrice?.formatted ? '' : '-mt-2']">
                    <QuantityEditor
                        v-if="showQuantity"
                        v-model="computedQuantity"
                        :max="max || computedChoice?.max || Number.MAX_SAFE_INTEGER"
                        :min="0"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.radioItem.ImageOption,
.checkboxItem.ImageOption {
    @apply cursor-pointer;
}
</style>
