<script setup>
import { PhotoIcon, DevicePhoneMobileIcon } from "@heroicons/vue/20/solid";
import { MapIcon } from "@heroicons/vue/24/outline";
import { BaseButton as Button} from "@/customer/Components";
import { Head, Link } from "@inertiajs/vue3";

defineProps({
    app: { type: Object },
});

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const hoursOfOperation = (location) => {
    return location.schedule.filter(item => 
        item.fulfillment_type === null && item.override_on === null
    ).sort((a, b) => {
        const dayA = daysOfWeek.indexOf(a.day);
        const dayB = daysOfWeek.indexOf(b.day);
        return dayA - dayB;
    })
}
</script>

<template>
    <Head :title="`Locations`" />

    <div class="mx-auto space-y-6 py-6">
        <h1 class="text-xl font-semibold text-center uppercase">
            {{ app.name }} Locations
        </h1>

        <ul
            class="sm:grid sm:grid-cols-3 sm:gap-4 space-y-3 sm:space-y-0 md:grid-cols-4"
            v-if="app.locations"
        >
            <li
                v-for="location, index in app.locations" :key="`location${index}`"
                class="border px-6 py-3 group flex items-center flex-col space-y-1 bg-palmers-havarti"
            >
                <h2 class="font-semibold text-xl uppercase py-3 text-center">
                    <span class="sr-only">{{ app.name }}<br /></span>
                    {{ location.name }}
                </h2>

                <template v-if="location.image">
                    <img :src="location.image.preview_url" class="block w-full h-auto border rounded-lg">
                </template>

                <template v-else>
                    <PhotoIcon
                        class="w-full h-auto bg-opacity-25 border bg-palmers-avocado-green text-palmers-havarti rounded-lg"
                    />
                </template>

                <div class="py-3">
                    <a class="flex items-start space-x-1 w-full">
                        <MapIcon class="w-4 h-6" />
                        <address class="not-italic text-xs leading-tight">
                            <span> {{ location.address.street }} </span><br />
                            <template v-if="location.address.street2">
                                <span> {{ location.address.street2 }} </span
                                ><br />
                            </template>
                            <span>
                                {{ location.address.city }},
                                {{ location.address.state }}
                                {{ location.address.zip }}
                            </span>
                        </address>
                    </a>

                    <a
                        :href="`tel:${location.address.phone}`"
                        class="flex items-center space-x-1 text-sm md:pointer-events-none md:cursor-auto w-full"
                    >
                        <DevicePhoneMobileIcon class="w-4 h-6" />
                        <span>{{ location.address.phoneDisplay }}</span>
                    </a>

                    <table class="text-xs my-3">
                        <tr>
                            <th colspan="2" class="text-center">
                                Hours of Operation
                            </th>
                        </tr>
                        <tr v-for="(item, index) in hoursOfOperation(location)" :key="index">
                            <td>{{ item.day }}</td>
                            <td>{{ item.starts_at }} - {{ item.ends_at }}</td>
                        </tr>
                    </table>
                </div>

                <Button
                    class="w-full text-center mt-auto"
                    :as="Link"
                    :href="route('customer.order.start')"
                >
                    <div class="w-full text-center">Start Order</div>
                </Button>
            </li>
        </ul>
    </div>
</template>
