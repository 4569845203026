<script setup>
import { useCheckoutStore } from '@/customer/Composables';
import { Spinner } from '@/common/Components';

defineProps({
    preventCheckout: {
        type: Boolean,
        default: false,
        required: false,
    },
});

const checkout = useCheckoutStore();

const emit = defineEmits(['submitted']);

const submit = () => {
    checkout.submitCatering().finally(() => emit('submitted'));
};
</script>

<template>
    <button
        type="button"
        id="checkout"
        @click.prevent="submit"
        class="w-full space-x-2 rounded-xl border-2 border-palmers-charcoal-grey bg-white p-4 text-xl font-bold shadow-lg disabled:cursor-not-allowed disabled:bg-neutral-200 disabled:bg-opacity-80 supports-hover:hover:bg-palmers-avocado-green disabled:supports-hover:hover:bg-neutral-200 disabled:supports-hover:hover:bg-opacity-80"
        :disabled="checkout.processing || preventCheckout"
    >
        <span v-if="!checkout.processing">PLACE INQUIRY</span>
        <Spinner v-if="checkout.processing" class="mx-auto h-6 w-6" />
    </button>
</template>
