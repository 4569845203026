<script setup>
import { HandWaveIcon, DataList, DataListItem } from '@/customer/Components';
import { useLang } from '@/customer/Composables';

const { lang } = useLang();

defineProps({
    first_name: { type: String },
    last_name: { type: String },
    email: { type: String },
    birthday: { type: String },
    phoneDisplay: { type: String },
});
</script>

<template>
    <section class="space-y-5 px-4 md:-mx-4">
        <div>
            <div class="flex items-center space-x-2 text-black">
                <HandWaveIcon class="size-[1.8rem]" />
                <h1 class="text-[2.5rem] font-bold uppercase leading-snug tracking-wider">
                    {{ lang.greeting }}
                </h1>
            </div>

            <h2 class="text-[1.63rem] font-bold uppercase tracking-widest text-pink">
                {{ `${first_name} ${last_name}` }}
            </h2>
        </div>

        <DataList>
            <DataListItem :label="lang.email" :value="email" />
            <DataListItem :label="lang.phone" :value="phoneDisplay" />
            <DataListItem :label="lang.birthday" :value="birthday" />
        </DataList>
    </section>
</template>
