const onlyList = (fields) => {
    return [...fields, "errors", "messages"];
};

const onlyObj = (fields) => {
    return {
        only: onlyList(fields),
    };
};

export function only(field, ...fields) {
    return Array.isArray(field) ? onlyList(field) : onlyObj([field, ...fields]);
}
