<script setup>
import InputTextarea from '@/customer/Components/InputTextarea.vue';
import ReadyAtFields from './OrderSummaryModal/ReadyAtFields.vue';
import { useCartStore } from '@/customer/Composables';
import { useOrderStore } from '@/customer/Composables';

const order = useOrderStore();

console.log(order.form.data());

const cart = useCartStore();
</script>

<template>
    <div class="flex flex-col gap-y-3 divide-y">
        <ReadyAtFields />
        <!-- <div class="pt-2">
            <div class="font-bold text-palmers-charcoal-grey">Delivery Instructions</div>
            <InputTextarea class="mt-3 min-h-[15rem] w-full text-black" />
        </div> -->
    </div>
</template>
