export default {
    root: ({ props }) => ({
        class: [
            // Display and Position
            {
                flex: props.fluid,
                'inline-flex': !props.fluid,
            },
            'w-full',
            'relative',

            // Misc
            { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled },
            'text-palmers-charcoal-grey',
        ],
    }),
    pcInput: ({ props, parent }) => ({
        root: {
            class: [
                'w-full rounded-full border-2 border-palmers-dragon-fruit bg-white px-6 py-3 text-base tracking-widest text-palmers-charcoal-grey focus:border-palmers-dragon-fruit focus:ring-palmers-dragon-fruit focus:ring-offset-2',

                // Filled State *for FloatLabel
                { filled: parent.instance?.$name == 'FloatLabel' && props.modelValue !== null },
            ],
        },
    }),
    dropdownIcon: {
        class: ['absolute top-[50%] -mt-2', 'text-palmers-charcoal-grey', 'right-[.75rem]'],
    },
    dropdown: {
        class: [
            'absolute right-3 top-[3px] size-12',

            // Alignments
            'items-center inline-flex text-center align-bottom justify-center',

            // Colors
            'text-palmers-charcoal-grey text-2xl',

            // States
            'focus:outline-none focus:outline-offset-0 focus:ring',
        ],
    },
    inputIconContainer:
        'absolute cursor-pointer top-1/2 right-5 -mt-[9px] text-palmers-charcoal-grey',
    inputIcon: 'inline-block text-2xl',
    panel: ({ props }) => ({
        class: [
            // Display & Position
            {
                absolute: !props.inline,
                'inline-block': props.inline,
            },

            // Size
            { 'w-auto': !props.inline },
            { 'min-w-[80vw] w-auto': props.touchUI },
            { 'min-w-full': props.inline },

            // Shape
            'border rounded-lg',
            {
                'shadow-md': !props.inline,
            },

            // Colors
            'bg-white',
            'border-palmers-mush-grey',

            //misc
            { 'overflow-x-auto': props.inline },
        ],
    }),
    header: {
        class: [
            //Font
            'font-semibold bg-palmers-havarti',

            // Flexbox and Alignment
            'flex items-center justify-between',

            // Spacing
            'p-2',
            'm-0',

            // Shape
            'rounded-t-lg',

            // Colors
            'text-palmers-charcoal-grey',
        ],
    },
    title: {
        class: [
            // Text
            'leading-8',
            'mx-auto my-0',
        ],
    },
    selectMonth: {
        class: [
            // Font
            'text-base leading-[normal]',
            'font-semibold',

            // Colors
            'text-palmers-charcoal-grey rounded-xl',

            // Transitions
            'transition duration-200',

            // Spacing
            'p-2',
            'm-0 mr-2',

            // States
            'supports-hover:hover:!bg-palmers-avocado-light',

            // Misc
            'cursor-pointer',
        ],
    },
    selectYear: {
        class: [
            // Font
            'text-base leading-[normal]',
            'font-semibold',

            // Colors
            'text-palmers-charcoal-grey rounded-xl',

            // Transitions
            'transition duration-200',

            // Spacing
            'p-2',
            'm-0',

            // States
            'supports-hover:hover:!bg-palmers-avocado-light',

            // Misc
            'cursor-pointer',
        ],
    },
    table: {
        class: [
            // Font
            'text-base leading-none',
            // Size & Shape
            'border-collapse',
            'w-full',

            // Spacing
            'm-0 my-2',
        ],
    },
    tableHeaderCell: {
        class: [
            // Spacing
            'p-0 md:p-2',
        ],
    },
    weekHeader: {
        class: ['leading-[normal]', 'text-palmers-charcoal-grey', 'opacity-60 cursor-default'],
    },
    weekNumber: {
        class: ['text-palmers-charcoal-grey', 'opacity-60 cursor-default'],
    },
    weekday: {
        class: [
            // Colors
            'text-palmers-charcoal-grey',
        ],
    },
    dayCell: {
        class: [
            // Spacing
            'p-2',
        ],
    },
    weekLabelContainer: {
        class: [
            // Flexbox and Alignment
            'flex items-center justify-center',
            'mx-auto',

            // Shape & Size
            'w-10 h-10',
            'rounded-full',
            'border-transparent border',

            // Colors
            'opacity-60 cursor-default',
        ],
    },
    dayView: 'w-full',
    day: ({ context }) => ({
        class: [
            // Flexbox and Alignment
            'flex items-center justify-center',
            'mx-auto',

            // Shape & Size
            'w-10 h-10',
            'rounded-full',
            'border-transparent border',

            // Colors
            {
                '!font-semibold': context.date.today,
                'text-palmers-charcoal-grey bg-transparent':
                    !context.selected && !context.disabled && !context.date.today,
                '!bg-palmers-himalayan-pink': context.selected && !context.disabled,
            },
            {
                'supports-hover:hover:!bg-palmers-himalayan-pink focus:!border-palmers-dragon-fruit':
                    !context.disabled,
            },
            {
                'opacity-40 cursor-not-allowed': context.disabled,
                'cursor-pointer': !context.disabled,
            },
        ],
    }),
    monthView: {
        class: [
            // Spacing
            'p-2',
        ],
    },
    month: ({ context }) => ({
        class: [
            // Flexbox and Alignment
            'inline-flex items-center justify-center',

            // Size
            'w-1/3',
            'p-2',

            // Shape
            'rounded-xl border border-transparent',

            // Colors
            {
                'text-palmers-charcoal-grey supports-hover:hover:bg-palmers-himalayan-pink':
                    !context.selected && !context.disabled,
                'border-palmers-dragon-fruit supports-hover:hover:!bg-palmers-himalayan-pink':
                    context.selected && !context.disabled,
            },
            {
                'opacity-40 cursor-not-allowed': context.disabled,
                'cursor-pointer': !context.disabled,
            },
        ],
    }),
    yearView: {
        class: [
            // Spacing
            'p-2',
        ],
    },
    year: ({ context }) => ({
        class: [
            // Flexbox and Alignment
            'inline-flex items-center justify-center',

            // Size
            'w-1/3',
            'p-2',

            // Shape
            'rounded-xl',

            // Colors
            {
                'text-palmers-charcoal-grey supports-hover:hover:bg-palmers-himalayan-pink':
                    !context.selected && !context.disabled,
                'border-palmers-dragon-fruit supports-hover:hover:!bg-palmers-himalayan-pink':
                    context.selected && !context.disabled,
            },
            {
                'opacity-40 cursor-not-allowed': context.disabled,
                'cursor-pointer': !context.disabled,
            },
        ],
    }),
    timePicker: {
        class: [
            // Flexbox
            'flex rounded-b-lg',
            'justify-center items-center',

            // Borders
            'bg-palmers-havarti',

            // Spacing
            'px-2',
        ],
    },
    separatorContainer: {
        class: [
            // Flexbox and Alignment
            'flex',
            'items-center',
            'flex-col',

            // Spacing
            'px-2',
        ],
    },
    separator: {
        class: [
            // Text
            'text-xl',
        ],
    },
    hourPicker: {
        class: [
            // Flexbox and Alignment
            'flex',
            'items-center',
            'flex-col',

            // Spacing
            'px-2',
        ],
    },
    minutePicker: {
        class: [
            // Flexbox and Alignment
            'flex',
            'items-center',
            'flex-col',

            // Spacing
            'px-2',
        ],
    },
    secondPicker: {
        class: [
            // Flexbox and Alignment
            'flex',
            'items-center',
            'flex-col',

            // Spacing
            'px-2',
        ],
    },
    ampmPicker: {
        class: [
            // Flexbox and Alignment
            'flex',
            'items-center',
            'flex-col uppercase',

            // Spacing
            'px-2',
        ],
    },
    calendarContainer: 'flex',
    calendar: 'flex-auto border-l first:border-l-0 border-surface-200',
    buttonbar: {
        class: [
            // Flexbox
            'flex justify-between items-center',

            // Spacing
            'py-3 px-0',

            // Shape
            'border-t',
        ],
    },
    transition: {
        enterFromClass: 'opacity-0 scale-y-[0.8]',
        enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
        leaveActiveClass: 'transition-opacity duration-100 ease-linear',
        leaveToClass: 'opacity-0',
    },
};
