<script setup>
import InputGroup from '@/customer/Components/InputGroup.vue';
import BaseButton from '@/customer/Components/BaseButton.vue';
import { ref } from 'vue';
import { useOrderStore } from '@/customer/Composables';

const props = defineProps({
    name: {
        type: [String, null],
    },
});

const $orderStore = useOrderStore();

const savedName = ref(props.name ?? '');
const isLoading = ref(false);
const showSuccess = ref(false);

const emit = defineEmits(['close', 'success']);

const handleSave = async () => {
    isLoading.value = true;
    const testString = savedName.value.split(' ').join('');
    if (testString.length > 0) {
        try {
            await $orderStore.saveOrderName(savedName.value);
            isLoading.value = false;
            showSuccess.value = true;
            emit('success');
        } catch (error) {
            isLoading.value = false;
            console.error(error);
        }
    } else {
        isLoading.value = false;
    }
};
</script>

<template>
    <div
        class="-mt-3 text-lg font-medium uppercase leading-none text-palmers-mush-grey md:!mt-1"
        v-if="showSuccess"
    >
        Order has been saved as
        <span class="font-semibold text-palmers-charcoal-grey">{{ savedName }}</span>
    </div>
    <div class="flex flex-col-reverse gap-y-4 md:!-mt-[2rem] md:flex-col" v-else>
        <div class="flex justify-end gap-x-3 pl-1 pr-1 md:pl-0 md:pr-3">
            <BaseButton
                @click="$emit('close')"
                :class="{ 'pointer-events-none opacity-60': isLoading }"
                class="flex w-full items-center justify-center py-3 shadow-none supports-hover:hover:!bg-palmers-himalayan-pink md:w-auto md:py-2"
                >cancel</BaseButton
            >
            <BaseButton
                @click="handleSave"
                :class="{ 'pointer-events-none opacity-60': isLoading }"
                class="flex w-full items-center justify-center py-3 shadow-none md:w-auto md:py-2"
                >save</BaseButton
            >
            <!-- <button
                class="text-md mb-5 w-full cursor-pointer rounded-full border-2 border-black px-4 py-2 font-bold text-palmers-charcoal-grey supports-hover:hover:bg-palmers-avocado-green sm:w-auto"
                @click="$emit('cancel')"
            >
                Cancel
            </button> -->
        </div>
        <InputGroup
            id="saved_name"
            name="saved_name"
            type="text"
            class="block w-full"
            v-model="savedName"
            required
            autofocus
            autocomplete="name"
            placeholder="Save as..."
        />
    </div>
</template>
