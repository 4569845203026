<script setup>
import { RadioGroup, RadioGroupOption, RadioGroupLabel } from '@headlessui/vue';
import StepHeader from './StepHeader.vue';
import OptionButton from './OptionButton.vue';
import InputError from '../../InputError.vue';
import { computed, onMounted } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    modelValue: String,
    prompt: String,
    description: String,
    options: Array,
    errorMessage: String,
    showOptions: { type: Boolean, default: true },
    by: {
        type: Function,
        default: (a, b) => a === b,
    },
    info: String,
});

const selection = computed({
    get: () => props.modelValue,
    set(value) {
        emit('update:modelValue', value);
    },
});

onMounted(() => {
    selection.value ??= props.options[0].value;
});
</script>

<template>
    <div>
        <RadioGroup v-model="selection" :by="by">
            <RadioGroupLabel>
                <StepHeader> {{ prompt }} </StepHeader>
            </RadioGroupLabel>

            <div
                v-if="description"
                class="text-md mx-auto -mt-3 mb-3 max-w-screen-sm px-6 text-center"
            >
                {{ description }}
            </div>

            <div
                v-show="showOptions"
                class="mx-auto grid max-w-max gap-6 sm:gap-12"
                :class="options.length > 1 ? 'grid-cols-2' : 'grid-cols-1'"
            >
                <RadioGroupOption
                    v-for="{ label, value, info } in options"
                    :value="value"
                    v-slot="{ checked, active }"
                >
                    <OptionButton v-bind="{ checked, active, label, value, info }" />
                </RadioGroupOption>
            </div>

            <InputError v-if="errorMessage" :message="errorMessage" class="text-center" />
        </RadioGroup>
    </div>
</template>
