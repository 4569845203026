<script setup>
import { useAttrs, watchEffect } from 'vue';
import { computed, watch, ref } from 'vue';
const attrs = useAttrs();
const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    modelValue: { default: null },
    options: {
        type: Array,
        default: null,
    },
    placeholder: {
        type: String,
        default: 'Select...',
    },
    customClass: {
        type: String,
        default: '',
    },
});

const extendedOptions = computed(() => [
    { id: 'placeholder', choice: { name: props.placeholder } },
    ...props.options,
]);

const selected = ref(!!props.modelValue);

const selection = computed({
    get: () =>
        extendedOptions.value.find((item) => item.choice.name === props.modelValue) ??
        extendedOptions.value[0],
    set: (value) => {
        selected.value = !(value === 'placeholder');
        emit(
            'update:modelValue',
            value === 'placeholder'
                ? {
                      value: null,
                      quantity: null,
                  }
                : {
                      value: value,
                      quantity: 1,
                  },
        );
    },
});
</script>

<template>
    <select
        :model-value="selection.id"
        @change="(e) => (selection = e.target.value)"
        class="block w-full rounded-3xl border-2 border-palmers-dragon-fruit px-5 focus:border-palmers-dragon-fruit focus:ring-0"
        :class="[customClass, selected ? '!text-black' : '!text-palmers-mush-grey']"
    >
        <template v-for="({ id, choice }, index) in extendedOptions" :key="index">
            <option :value="id">{{ choice.name }}</option>
        </template>
    </select>
</template>

<style>
option[value=''][disabled] {
    display: none;
    color: red;
}
</style>
