import DOMPurify from "dompurify";

// Add a hook to make all links open a new window
DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    if ("target" in node) {
        node.setAttribute("target", "_blank");
    }
});

/**
 * Limited API to reduce global changes
 * @returns
 */
export default function () {
    return {
        sanitize: DOMPurify.sanitize,
    };
}
