<script setup>
import { ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import AppIcon from '@/customer/Components/AppIcon.vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import CartItem from '@/customer/Components/Cart/CartItem.vue';
import AddOnItems from '@/customer/Components/Cart/AddOnItems.vue';
import { useCartStore } from '@/customer/Composables';
import { MenuType } from '@/common/Utils/enums.js';
import InputText from '@/customer/Components/InputText.vue';
import BaseButton from '@/customer/Components/BaseButton.vue';
import { ErrorBoundary } from '@/common/Components';

const cart = useCartStore();
</script>

<template>
    <template  v-for="(menu, i) of cart.menus" :key="i">
         <AddOnItems :items="menu.menuItems" :label="menu.name" />
    </template>
</template>
