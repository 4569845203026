<script setup>
import {
    TrashIcon,
    UserIcon,
    ChatBubbleBottomCenterTextIcon,
    CheckCircleIcon,
} from '@heroicons/vue/24/solid';
import { computed, ref } from 'vue';

import { Image, InputText } from '@/customer/Components';
import { QuantityEditor } from '@/customer/Pages/Menu/MenuItem/Partials';
import { useCartStore } from '@/customer/Composables';
import CartItemDifferences from './CartItemDifferences.vue';
import { router } from '@inertiajs/vue3';
import Spinner from '@/common/Components/UI/Progress/Spinner.vue';

const emit = defineEmits(['remove', 'updateItem', 'updateQuantity']);
const cart = useCartStore();

const props = defineProps({
    item: { type: Object, required: true },
    as: { type: String, default: 'div' },
});

const hasSingleSize = computed(() => props.item.meta?.single_size);

const handleEditClick = (item) => {
    const editHref = cart.getEditHref(item);
    cart.close();
    router.get(editHref);
};

const quantity = computed({
    get() {
        return props.item.quantity;
    },
    set(value) {
        cart.setQuantity(props.item.uuid, value);
    },
});

const additions = computed(() => props.item.differences.filter((x) => x.delta > 0));
const removals = computed(() => props.item.differences.filter((x) => x.delta <= 0));
const differences = computed(() => [].concat(removals.value).concat(additions.value));
const menuItem = computed(() => props.item.meta.menu_item);

const bulkQuantity = ref(props.item.meta.size?.quantity ?? 1);

const showAddName = ref(false);
const itemCustomerName = ref(props.item.meta.name ?? null);
const loadingUpdateName = ref(false);

const cancelAddName = () => {
    itemCustomerName.value = null;
    showAddName.value = false;
};

const updateItemName = async () => {
    loadingUpdateName.value = true;
    const newName =
        itemCustomerName.value === null || itemCustomerName.value === ''
            ? null
            : itemCustomerName.value;
    const response = await cart.addName(props.item.uuid, newName);
    if (response) {
        showAddName.value = false;
    }
    loadingUpdateName.value = false;
};
</script>

<template>
    <component :is="as">
        <div class="select-none flex-row-reverse justify-between text-left sm:flex">
            <div class="flex h-48 w-full items-center justify-center sm:w-48 sm:justify-start">
                <Image :src="menuItem?.image?.original" />
            </div>
            <div class="mt-2 flex-grow text-palmers-charcoal-grey sm:mt-0">
                <div class="flex flex-row justify-between sm:pr-5">
                    <div>
                        <h3 class="text-lg font-bold">
                            {{ menuItem?.name }}
                        </h3>

                        <h4 v-if="!hasSingleSize" class="font-semibold uppercase opacity-80">
                            {{ item.meta.size?.label ?? '' }}
                        </h4>
                    </div>
                    <h5 class="text-[3rem] font-semibold leading-none opacity-80">
                        <span class="pr-1 text-3xl">x</span>{{ item.quantity }}
                    </h5>
                </div>
                <div>
                    <CartItemDifferences
                        v-bind="{
                            differences: item.differences,
                            innerConfigDifferences: item.innerConfigDifferences,
                        }"
                    />
                </div>
                <p class="mt-2 text-4xl font-bold sm:mt-4 sm:text-5xl">
                    {{ item.summary.total.formatted }}
                </p>
                <p class="mt-5 text-sm text-red-500" v-if="item.unavailable_message">
                    {{ item.unavailable_message }}
                </p>
            </div>
        </div>

        <div class="mt-2 flex items-end justify-between sm:mt-4">
            <div class="text-palmers-charcoal-grey">
                <QuantityEditor v-model="quantity" class="space-x-2" />
            </div>

            <div class="flex select-none items-center gap-x-6 sm:items-end sm:gap-x-10">
                <a
                    class="text-lg underline supports-hover:hover:text-palmers-dragon-fruit"
                    @click="handleEditClick(item)"
                >
                    <span>Edit</span>
                </a>
                <button
                    type="button"
                    class="flex size-10 items-center justify-center rounded-full border border-palmers-dragon-fruit supports-hover:hover:bg-palmers-dragon-fruit/10"
                    @click="cart.removeItemById(item.uuid)"
                >
                    <TrashIcon class="h-6 w-6 text-palmers-dragon-fruit" />
                </button>
            </div>
        </div>

        <div
            class="mt-5 flex flex-col rounded-md"
            :class="[
                item.meta.instructions ? 'w-fit py-2' : 'w-min py-1',
                {
                    'gap-y-1': item.meta.instructions && itemCustomerName,
                    'border-2 px-3': (itemCustomerName && !showAddName) || item.meta.instructions,
                },
            ]"
        >
            <div class="flex items-center gap-2">
                <UserIcon v-if="!showAddName" class="size-4 min-w-4 text-palmers-mush-grey" />
                <p
                    v-if="itemCustomerName && !showAddName"
                    class="w-max text-lg font-semibold uppercase text-palmers-charcoal-grey/80"
                >
                    {{ itemCustomerName }}
                </p>
                <button
                    class="w-fit text-lg font-semibold uppercase text-palmers-charcoal-grey/60 active:opacity-60 supports-hover:hover:underline"
                    :class="[item.meta.instructions ? 'w-fit' : 'w-max']"
                    @click="showAddName = true"
                    v-if="!itemCustomerName && !showAddName"
                >
                    Add Name
                </button>
                <div
                    v-if="showAddName"
                    class="relative w-80"
                    :class="{
                        'pb-2 pt-1': item.meta.instructions,
                        'pointer-events-none opacity-80': loadingUpdateName,
                    }"
                >
                    <InputText
                        placeholder="Add Name to Item"
                        name="newCustomerNameOnItem"
                        v-model="itemCustomerName"
                        :disabled="loadingUpdateName"
                    />
                    <Spinner
                        v-if="loadingUpdateName"
                        :class="[
                            item.meta.instructions ? 'top-[calc(5.5px+.25rem)]' : 'top-[5.5px]',
                        ]"
                        class="absolute right-[7px] size-10 cursor-pointer text-palmers-dragon-fruit focus:opacity-90 active:opacity-80 supports-hover:hover:opacity-90"
                    />
                    <button v-else @click.stop="updateItemName">
                        <CheckCircleIcon
                            :class="[
                                item.meta.instructions ? 'top-[calc(1.5px+.25rem)]' : 'top-[1.5px]',
                            ]"
                            class="absolute right-[3px] size-12 cursor-pointer text-palmers-avocado-light focus:opacity-90 active:opacity-80 supports-hover:hover:opacity-90"
                        />
                        <span class="sr-only">Confirm customer name for item</span>
                    </button>
                </div>
            </div>
            <div class="flex items-start gap-2">
                <ChatBubbleBottomCenterTextIcon
                    v-if="item.meta.instructions"
                    class="mt-[4px] size-4 min-w-4 text-palmers-mush-grey"
                />
                <p class="text-[1rem]">
                    {{ item.meta.instructions }}
                </p>
            </div>
        </div>
    </component>
</template>
