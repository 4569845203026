<script setup>
import { MenuType } from '@/common/Utils/enums.js';
import CheckoutButton from './CheckoutButton.vue';
import { useCheckoutStore, useValidatorStore } from '@/customer/Composables';
import { computed } from 'vue';

const checkout = useCheckoutStore();
const validator = useValidatorStore();

const message = computed(() => validator.getError(['acceptjs', 'total', 'checkout', 'authnet']));

const isPhoneNumber = computed(
    () => (message.value = '{ "contactDetails": { "phone": "This field is required" } }'),
);
</script>

<template>
    <div class="space-x-2 text-palmers-charcoal-grey" v-if="message && !isPhoneNumber">
        <span class="font-bold">Error:</span>
        <span>{{ message }}</span>
    </div>
</template>
