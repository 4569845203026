<script setup>
import { ref, watch } from 'vue';
import {
    SelectionSummary,
    StartOrderButton,
    SizeSelectorPill,
    AccordionPanel,
    FixedItemBar,
} from '.';
import TrailingInputs from './TrailingInputs.vue';
import { Alert, EmptyBoundary } from '@/common/Components';
import { Image } from '@/customer/Components';
import { useMenuItemStore } from '@/customer/Composables';
import PrintButton from '@/common/Components/UI/PrintButton.vue';

const emit = defineEmits(['update:selections', 'update:quantities']);

const $store = useMenuItemStore();

const props = defineProps({
    menuItem: { type: Object },
    price: { type: String },
    selections: { type: Object, default: () => ({}) },
    quantities: { type: Object, default: () => ({}) },
    requiredSelections: { type: Array, default: [] },
});

const showDisplaySummary = ref(props.menuItem.displaySummary ?? false);

const allRequiredSelectionsSelected = ref(
    props.requiredSelections.every((selectionId) => {
        if (props.selections.hasOwnProperty(selectionId)) {
            return props.selections[selectionId].length > 0;
        }
        return false;
    }),
);

const allRequiredInnerSelectionsSelected = ref(
    props.menuItem.type !== 'combination' ? true : !$store.form.requiredInnerConfigs ? true : false,
);

watch(
    props.selections,
    (newSelections) => {
        allRequiredSelectionsSelected.value = props.requiredSelections.every((selectionId) => {
            if (newSelections.hasOwnProperty(selectionId)) {
                return newSelections[selectionId].length > 0;
            }
            return false;
        });
    },
    { deep: true },
);

watch(
    [() => $store.form.requiredInnerConfigs, () => $store.selectedInnerConfigChoices],
    ([newRequiredInnerConfigs, newSelectedInnerConfigChoices]) => {
        if (newRequiredInnerConfigs !== undefined) {
            console.log(
                'Watcher triggered, requiredInnerConfigs now exists:',
                newRequiredInnerConfigs,
            );

            allRequiredInnerSelectionsSelected.value = newRequiredInnerConfigs.every(
                (selectionId) => {
                    console.log(selectionId);
                    return Object.values(newSelectedInnerConfigChoices).some((choice) => {
                        if (choice && choice.hasOwnProperty(selectionId)) {
                            console.log(choice[selectionId]);
                            return choice[selectionId].length > 0;
                        }

                        return false;
                    });
                },
            );
        }
    },
    { immediate: true, deep: true },
);
</script>

<template>
    <div
        class="grid grid-cols-1 md:grid-cols-5 md:gap-6 md:pb-16"
        :class="[
            $store.ready && menuItem?.type !== 'simple' && showDisplaySummary ? 'pb-24' : 'pb-20',
        ]"
    >
        <!-- left half -->
        <div ref="leftHalf" class="h-min md:sticky md:top-16 md:col-span-2">
            <div class="flex justify-start">
                <div class="w-full">
                    <div class="aspect-square">
                        <Image :src="menuItem.image?.original" />
                    </div>
                </div>
            </div>

            <SelectionSummary
                class="hidden md:block"
                v-if="$store.ready && menuItem?.type !== 'simple' && showDisplaySummary"
                v-bind="{ menuItem, quantities }"
                @update:quantities="emit('update:quantities', $event)"
            />
            <template v-if="$store.ready">
                <TrailingInputs class="hidden p-4 md:block" />
            </template>
        </div>

        <div class="flex flex-col space-y-4 md:order-last md:col-span-3">
            <div class="px-4 md:px-0">
                <!-- name -->
                <h1 class="text-2xl font-semibold uppercase text-palmers-charcoal-grey">
                    {{ menuItem.name }}
                </h1>
                <!-- allergen indicators -->
                <div class="relative flex">
                    <template v-for="allergenIndicator in props.menuItem.allergenIndicators">
                        <Image
                            :src="allergenIndicator?.image?.preview_url"
                            image-container-class="flex"
                            style="width: 7%"
                            :title="allergenIndicator.label"
                        />
                    </template>
                </div>
                <div class="flex items-center justify-between" v-if="menuItem.type === 'schedule'">
                    <a
                        :href="menuItem.scheduleHref"
                        target="_blank"
                        class="font-bold text-palmers-dragon-fruit supports-hover:hover:underline"
                    >
                        View Schedule
                    </a>

                    <PrintButton
                        theme="pink"
                        title="Print soup schedule"
                        :url="menuItem.scheduleHref"
                    />
                </div>

                <p class="prose">
                    {{ menuItem.description }}
                </p>

                <!-- size selection -->
                <div class="z-20">
                    <SizeSelectorPill :quantities="quantities" v-if="$store.ready" />
                </div>
            </div>

            <!-- right half -->
            <EmptyBoundary
                :as="Alert"
                :model-value="menuItem.configurations"
                message="This item is does not provide any configurations"
                theme="danger"
                v-if="
                    $store.ready &&
                    menuItem.hasOptions &&
                    (menuItem.type !== 'simple' || menuItem.type !== 'combination')
                "
            >
                <!-- boxes -->
                <AccordionPanel
                    :menuItem="menuItem"
                    :item-configurations="menuItem.configurations"
                    :item-combo-item-options="menuItem.comboItemOptions"
                    :quantities="quantities"
                    :selections="selections"
                    :ready="$store.ready"
                    as="grid"
                />
            </EmptyBoundary>

            <EmptyBoundary
                :as="Alert"
                :model-value="menuItem.comboItemOptions"
                message="This item is does not provide any combo item options"
                theme="danger"
                v-if="$store.ready && menuItem.type == 'combination'"
            >
                <!-- boxes -->
                <AccordionPanel
                    :menuItem="menuItem"
                    :item-configurations="menuItem.configurations"
                    :item-combo-item-options="menuItem.comboItemOptions"
                    :quantities="quantities"
                    :selections="selections"
                    :ready="$store.ready"
                    as="grid"
                />
            </EmptyBoundary>

            <template v-if="$store.ready">
                <TrailingInputs class="block p-4 md:hidden" />
            </template>

            <SelectionSummary
                class="block p-4 md:hidden"
                v-if="$store.ready && menuItem?.type !== 'simple' && showDisplaySummary"
                v-bind="{ menuItem, quantities }"
                @update:quantities="emit('update:quantities', $event)"
            />

            <div class="hidden flex-1 items-start justify-center md:flex" v-if="!$store.ready">
                <StartOrderButton />
            </div>
        </div>

        <FixedItemBar
            :all-required-selections-selected="
                allRequiredSelectionsSelected && allRequiredInnerSelectionsSelected
            "
            v-if="$store.ready"
        />
        <StartOrderButton v-else class="absolute bottom-0 left-0 w-full md:hidden" />
    </div>
</template>
