<script setup>
import { Mask } from 'maska';
import { computed } from 'vue';
import InputText from '@/customer/Components/InputText.vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
        default: '',
    },
    mask: {
        type: String,
        default: '(###) ###-####',
    },
});

const mask = computed(() => new Mask({ mask: props.mask }));

const inputValue = computed({
    get: () => {
        return mask.value.masked(props.modelValue);
    },
    set: (value) => {
        emit('update:modelValue', value);
    },
});
</script>

<template>
    <InputText v-bind="{ ...$attrs }" v-model.lazy="inputValue" autocomplete="phone" />
</template>
