import { router, usePage } from '@inertiajs/vue3';
import { computed, onMounted, onUnmounted, reactive, ref } from 'vue';
import { only } from '@/customer/Utils/inertia';
import axios from 'axios';

const isOpen = ref(false);

const cart = ref({});

const hooks = ref({});

const nextId = ref(1);

const processing = ref(false);

export const useCart = (settings = {}) => {
    settings._id = nextId.value++;
    for (let hook in settings) {
        if (typeof settings[hook] === 'function') {
            const existingHooks = hooks.value?.[hook] ?? {};
            existingHooks[settings._id] = settings[hook];
            hooks.value[hook] = existingHooks;
        }
    }

    const invoke = (hookName, ...args) => {
        const availableHooks = Object.values(hooks.value[hookName] ?? {});

        for (let i = 0; i < availableHooks.length; i++) {
            const result = availableHooks[i](...args);
            if (result === false) return false;
        }
    };

    const hook = (hookName, callback, deps = []) => {
        if (invoke(`onBefore${hookName}`, ...deps) === false) return;
        const done = (...newDeps) => invoke(`onAfter${hookName}`, ...newDeps);
        callback(done);
    };

    const contents = computed(() => cart.value);

    const open = () => {
        hook(
            'Open',
            (done) => {
                isOpen.value = true;
                done();
            },
            [],
        );
    };

    const close = () => {
        hook(
            'Close',
            (done) => {
                isOpen.value = false;
                done();
            },
            [],
        );
    };

    const beginProcessing = () => {
        processing.value = true;
    };

    const endProcessing = () => {
        processing.value = false;
    };

    const process = (handler) => {
        beginProcessing();
        handler();
        endProcessing();
    };

    const toggle = () => {
        isOpen.value ? close() : open();
    };

    const removeItem = (item) => {
        processing.value = true;
        hook(
            'Remove',
            (done) => {
                router.delete(
                    route('cart.destroy', {
                        cart: item.id,
                    }),
                    {
                        data: item,
                        preserveScroll: true,
                        preserveState: true,
                        onSuccess() {
                            // console.log("success!");
                            refreshCart();
                            done();
                            processing.value = false;
                        },
                    },
                );
            },
            [item],
        );
    };

    const addItems = (items) => {
        processing.value = true;
        hook(
            'AddItems',
            (done) => {
                router.post(
                    route('cart.store'),
                    {
                        items,
                    },
                    {
                        ...only('cart'),
                        preserveScroll: true,
                        preserveState: true,
                        onSuccess(x) {
                            // console.log("success", x);
                            refreshCart();
                            done(items);
                            processing.value = false;
                        },
                    },
                );
            },
            [items],
        );
    };

    const editItem = (item) => {
        processing.value = true;
        hook(
            'Edit',
            (done) => {
                axios
                    .post(
                        route('storefront.product', {
                            product: item.company_product_id,
                        }),
                    )
                    .then(({ data }) => {
                        done(item, data.data);
                        processing.value = false;
                    });
            },
            [item],
        );
    };

    const updateItemQuantity = (item, value) => {
        console.log('update item quantity', item, value);
    };

    const applyDiscountCode = () => {
        console.log('apply discount code and recalculate total');
    };

    const applyTip = () => {
        console.log('apply tip');
    };

    const refreshCart = () => {
        cart.value = usePage().props.cart || {};
    };

    const saveItem = (item) => {
        processing.value = true;
        hook('Save', (done) => {
            router.patch(
                route('cart.update', {
                    cart: item.id,
                }),
                item,
                {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess() {
                        // console.log("success!");
                        refreshCart();
                        done();
                        processing.value = false;
                    },
                },
            );
        });
    };

    onMounted(() => {
        refreshCart();
    });

    onUnmounted(() => {
        for (let hook in hooks.value) {
            delete hooks.value[hook][settings._id];
        }
    });

    return reactive({
        // expose computed values, methods, etc.
        contents,
        isOpen,
        processing,
        open,
        close,
        toggle,
        removeItem,
        addItems,
        editItem,
        saveItem,
        beginProcessing,
        endProcessing,
        process,
        updateItemQuantity,
        applyDiscountCode,
        applyTip,
    });
};
