<script setup>
import { onMounted, ref, computed } from "vue";
import { vMaska } from "maska";

defineProps({
    modelValue: {
        type: [String, null],
        required: true,
        default: "",
    },
    name: {
        type: String,
        default: null,
    },
    maskOptions: {
        type: [Object, Boolean],
        default: false,
    },
    placeholder: {
        type: String,
        default: null,
    }
});

defineEmits([
    "update:modelValue",
    "focus",
    "blur",
    "input",
    "change",
    "keyup",
    "keydown",
    "maska",
]);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute("autofocus")) {
        input.value.focus();
    }
});

defineExpose({
    get input() {
        return input.value
    },
    focus: () => input.value.focus()
});

</script>

<template>
    <textarea
        v-bind="{ ...$attrs }"
        ref="input"
        v-maska:[$props.maskOptions]
        class="border-palmers-dragon-fruit border-2 rounded-xl focus:border-palmers-dragon-fruit focus:ring-palmers-dragon-fruit px-6 py-3 text-base tracking-widest focus:ring-offset-2"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @maska="$emit('maska', $event)"
        :placeholder="placeholder"
    />
</template>
