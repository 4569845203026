import { defineStore } from 'pinia';
import { usePage } from '@inertiajs/vue3';
import { pick, set } from 'lodash';
import { useScriptTag } from '@vueuse/core';

export default defineStore('authNetStore', {
    state: () => ({
        environment: {},
        sdkRequestLock: false,
        requestData: {
            authData: {
                clientKey: null,
                apiLoginID: null,
            },
            cardData: {
                cardNumber: null,
                month: null,
                year: null,
                cardCode: null,
                zip: null,
                fullName: null,
            },
            bankData: {
                accountNumber: null,
                routingNumber: null,
                nameOnAccount: null,
                accountType: null,
            },
        },
        responseData: {
            paymentProfileId: null,
            saveForLater: false,
            contactDetails: {
                phone: '',
                email: null,
            },
            billingDetails: {
                street1: null,
                city: null,
                state: null,
                zip: null,
            },
        },
    }),
    actions: {
        initialize() {
            this.environment = usePage().props.authnet;
            this.requestData.authData.apiLoginID = this.environment.login_id;
            this.requestData.authData.clientKey = this.environment.public_client_key;
            useScriptTag(this.environment.accept_js_url);
        },
        acceptSdk() {
            if (typeof window.Accept === 'undefined' || !window.Accept) {
                throw new Error('Could not find Accept.js library');
            }

            return window.Accept;
        },
        async lockRequest(promise) {
            if (this.sdkRequestLock) {
                throw new Error('Cannot send more than one Accept.js request at a time.');
            }
            this.sdkRequestLock = true;

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    promise
                        .then(resolve)
                        .catch((error) => {
                            console.error('Accept.js request failed', error);
                            reject(error);
                        })
                        .finally(() => (this.sdkRequestLock = false));
                }, 500);
            });
        },

        async sendDispatchDataRequest(requestPayload, opaqueType = 'opaqueData') {
            return this.lockRequest(
                new Promise((resolve, reject) => {
                    try {
                        this.acceptSdk().dispatchData(requestPayload, (response) => {
                            // handle accept.js response
                            if (response?.messages?.resultCode === 'Error') {
                                return reject(response);
                            }

                            set(this.responseData, opaqueType, response.opaqueData);
                            set(
                                this.responseData,
                                'contactDetails.fullName',
                                this.requestData.cardData.fullName,
                            );

                            resolve(response);
                        });
                    } catch (error) {
                        reject(error);
                    }
                }),
            );
        },
        async submit(dataObject = 'cardData') {
            // await dispatch request data
            const requestPayload = pick(this.requestData, ['authData', dataObject]);
            // generate two nonce opaque datas (required for individual authnet transactions) for storing payment profile and processing payment
            if (this.responseData.saveForLater) {
                await this.sendDispatchDataRequest(requestPayload, 'profileOpaqueData');
            }
            return this.sendDispatchDataRequest(requestPayload);
        },
    },
});
