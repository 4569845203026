<script setup>
import ConfirmationLink from '@/common/Components/UI/Alerts/ConfirmationLink.vue';
import { Link } from "@inertiajs/vue3"
import { useLang } from '@/customer/Composables'

const { lang } = useLang()

const props = defineProps({
    'id': { type: Number },
    'name': { type: String },
    'deliveryInstructions': { type: Object },
    'street': { type: String },
    'city': { type: String },
    'state': { type: String },
    'description': { type: String },
    'zip': { type: String },
    'landmarks': { type: String },
    'company': { type: String },
    'suite': { type: String },
    'floor': { type: String },
    'doorman': { type: Boolean },
    'notes': { type: String },
    'call': { type: Boolean },
})

</script>

<template>
    <div>
        <div>{{ deliveryInstructions?.name ?? '<Name>' }}</div>
        <div>{{ street }}</div>
        <div>{{ description }}</div>
        <div class="flex items-center space-x-3">
            <Link :href="route('customer.profile.addresses.edit', id)" class="font-medium underline">{{ lang.edit }}</Link>

            <ConfirmationLink v-slot="{show}" :href="route('customer.profile.addresses.destroy', id)" method="DELETE">
                <button @click.prevent="show" class="text-palmers-burnt-orange font-medium underline">{{ lang.delete }}</button>
            </ConfirmationLink>
        </div>
    </div>
</template>
