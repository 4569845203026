<script setup>
import { switcherProps } from '@/customer/Components/Order/Internal/props';
import ProgressSwitcher from '../ProgressSwitcher.vue';
import { inject, computed } from 'vue';
import { orderState } from '@/customer/Utils/keys';
import { Link } from '@inertiajs/vue3';

const order = inject(orderState);

const props = defineProps({
    ...switcherProps,
});
const dropdownDisabled = computed(() => order.isStep('menu_type') && !order.inProgress);
</script>

<template>
    <ProgressSwitcher v-bind="{ ...props, dropdownDisabled }">
        <template #dropdown.content>
            <div class="-mt-3 bg-palmers-himalayan-pink">
                <div class="flex items-center justify-center space-x-3 whitespace-pre p-5 px-8">
                    <span>Need to change menu type?</span>
                    <Link
                        @success="order.toMenuType"
                        class="border-b-2 border-dotted border-black supports-hover:hover:border-solid"
                        >Start Over</Link
                    >
                </div>
            </div>
        </template>
    </ProgressSwitcher>
</template>
