<script setup>
import OrderProgressBanner from './OrderProgressBanner.vue';
import { inject, computed } from 'vue';
import { orderState } from '@/customer/Utils/keys';
import { usePage } from '@inertiajs/vue3';

const order = inject(orderState);
const showBanner = computed(() => order.isStarted && !usePage().props.cart);
</script>

<template>
    <section id="layout-banners" class="pt-0">
        <OrderProgressBanner v-if="showBanner" />
    </section>
</template>
