<script setup>
import { Head } from '@inertiajs/vue3';
import {
    LocationStep,
    FulfillmentTypeStep,
    ReadyTimeStep,
    BaseButton as Button,
} from '@/customer/Components';
import { inject, computed } from 'vue';
import { orderState } from '@/customer/Utils/keys.js';

defineProps({
    order: { type: Object },
});

const order = inject(orderState);

const continueButtonLabel = computed(() => (order.isLast ? 'Start Ordering!' : 'Continue'));

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validateEmail = (email) => {
    return emailPattern.test(email);
};

const disabled = computed(() => {
    if (order.progress.order_type === 'group') {
        return order.progress.group_invites.some((groupInvite) => {
            return groupInvite.email === '' || !validateEmail(groupInvite.email);
        });
    } else {
        return false;
    }
});
</script>

<template>
    <Head :title="`Start New Order`" />

    <div class="mx-auto max-w-screen-md space-y-6 pb-10 md:py-6">
        <!-- Location -->
        <LocationStep
            v-if="order.isStep('location')"
            v-bind="{ form: order.form, ...order.options.location }"
        />

        <!-- Order Type -->
        <!-- <OrderTypeStep
            v-if="order.isStep('order_type')"
            v-bind="{ form: order.form, ...order.options.order_type }"
        /> -->

        <!-- Menu Type -->
        <!-- <MenuTypeStep
            v-if="order.isStep('menu_type')"
            v-bind="{ form: order.form, ...order.options.menu_type }"
        /> -->

        <!-- Fulfillment Type -->
        <FulfillmentTypeStep
            v-if="order.isStep('fulfillment_type')"
            v-bind="{ form: order.form, ...order.options.fulfillment_type }"
        />

        <!-- Ready Time -->
        <ReadyTimeStep
            v-if="order.isStep('ready_type')"
            :form="order.form"
            :options="order.options.ready_type.options"
            :prompt="order.options.ready_type.prompt"
        />

        <div class="mx-auto flex items-center justify-center space-x-6">
            <Button v-if="!order.isFirst" @click="order.goToPreviousStep"> Previous </Button>

            <Button :disabled="order.form.processing || disabled" @click="order.goToNextStep">
                {{ continueButtonLabel }}
            </Button>
        </div>
    </div>
</template>
