<script setup>
import { Head, useForm, usePage } from '@inertiajs/vue3';
import AccountSettingsLayout from '@/customer/Layouts/AccountSettingsLayout.vue';
import AddressForm from '@/customer/Pages/Profile/Partials/AddressForm.vue';
import { computed } from 'vue';
import { useLang } from '@/customer/Composables';

const { lang } = useLang();

const props = defineProps({
    address: { type: Object },
});

const address = computed(() => props.address);

const form = useForm(route().current(), {
    name: address.value.deliveryInstructions?.name,
    phone: address.value.phoneDisplay,
    street: address.value.street,
    city: address.value.city,
    state: address.value.state,
    zip: address.value.zip,
    landmarks: address.value.deliveryInstructions?.landmarks,
    building: address.value.deliveryInstructions?.building,
    suite: address.value.deliveryInstructions?.suite,
    floor: address.value.deliveryInstructions?.floor,
    drop_off: address.value.deliveryInstructions?.drop_off ?? false,
    notes: address.value.deliveryInstructions?.notes,
    call: address.value.deliveryInstructions?.call ?? false,
});

const submit = () => {
    const url = route('customer.profile.addresses.update', address.value);

    form.patch(url, {
        preserveScroll: true,
        preserveState: true,
    });
};
</script>

<template>
    <AccountSettingsLayout :title="lang.title_edit" class="pb-6 md:pb-0">
        <Head title="Profile" />

        <AddressForm v-bind="{ form }" @submit="submit" />
    </AccountSettingsLayout>
</template>
