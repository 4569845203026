<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        class="lds-rolling"
        style="background: none"
        aria-hidden="true"
    >
        <circle
            class="loader-svg stroke-current stroke-[7]"
            cx="50"
            cy="50"
            fill="none"
            r="35"
            stroke-dasharray="164.93361431346415 56.97787143782138"
            transform="rotate(159.821 50 50)"
        ></circle>
    </svg>
</template>
