<script setup>
import { ref, watch, computed, onBeforeMount, onMounted } from 'vue';
import {
    TabbedNavigation,
    ConfigurationListPanel,
    ConfigurationGridPanel,
    ConfigurationDropdownPanel,
    ComboItemOptionListPanel,
    ComboItemOptionGridPanel,
} from '.';

const props = defineProps({
    menuItem: {
        required: true,
    },
    itemConfigurations: {
        type: Array,
        required: true,
    },
    itemComboItemOptions: {
        type: [Array, null],
        required: true,
    },
    selections: {
        type: Object,
        required: true,
    },
    quantities: {
        type: [Object, null],
        required: true,
    },
    ready: {
        type: Boolean,
        required: true,
    },
    as: {
        type: String,
        required: true,
    },
    itemWithin: {
        type: Boolean,
        required: false,
        default: false,
    },
    itemWithinWithin: {
        type: Boolean,
        required: false,
        default: false,
    },
    parentComboItem: {
        type: Number,
        required: false,
        default: 0,
    },
    parentComboItemOption: {
        type: Number,
        required: false,
        default: 0,
    },
    choiceId: {
        type: Number,
        required: false,
        default: 0,
    },
    adderPricingSize: {
        type: Number,
        required: false,
        default: 0,
    },
    hideUpgrades: {
        type: Boolean,
        default: false,
    },
});

const sortedConfigurations = computed(() => {
    const sortedItems = props.menuItem.configurations?.sort((a, b) => {
        if (a.configuration_type === 'upgrade' && b.configuration_type !== 'upgrade') {
            return 1;
        } else if (a.configuration_type !== 'upgrade' && b.configuration_type === 'upgrade') {
            return -1;
        } else {
            return 0;
        }
    });

    return sortedItems;
});

const component = computed(() => {
    if (props.as === 'grid') {
        if (props.menuItem.type === 'combination') {
            return ComboItemOptionGridPanel;
        } else {
            return ConfigurationGridPanel;
        }
    } else {
        if (props.menuItem.type === 'combination') {
            return ComboItemOptionListPanel;
        } else {
            return ConfigurationListPanel;
        }
    }
});

const getComboComponent = (options) => {
    if (options.required === false) {
        return ConfigurationDropdownPanel;
    } else {
        return component.value;
    }
};

const expandedAccordionIndices = ref([]);

const menuItemConfigurations = ref(props.itemConfigurations);
const menuItemComboItemOptions = ref(props.itemComboItemOptions);

onBeforeMount(() => {
    if (menuItemComboItemOptions?.value?.length) {
        const comboItemOption = menuItemComboItemOptions?.value;
        const comboItemOptionKeys = comboItemOption ? Object.keys(comboItemOption) : [];
        expandedAccordionIndices.value = comboItemOptionKeys.map(
            (key) => comboItemOption[key].expanded,
        );
    } else {
        const config = menuItemConfigurations?.value;
        const configKeys = config ? Object.keys(config) : [];
        expandedAccordionIndices.value = configKeys.map((key) => config[key].expanded);
    }
});

// DOM refs for accordion elements
const listElements = ref(null);
const accordionRefs = ref(null);

// parse listElement nodes to get dropdown tabs
onMounted(() => {
    const accordionTabsRaw = Array.from(listElements.value?.childNodes ?? []);
    const accordionTabs = accordionTabsRaw.slice(1, -1);
    accordionRefs.value = accordionTabs;
});

// DOM refs for accordion container
const accordionElement = ref(null);
const accordionRectTop = computed(() => {
    return accordionElement.value
        ? accordionElement.value.getBoundingClientRect().top + window.scrollY
        : null;
});

// DOM ref to get page header height
const headerRect = ref(null);
onMounted(() => (headerRect.value = document.getElementById('navHeader').getBoundingClientRect()));

// handle what occurs when tabs/items are clicked
const handleAccordion = (event, fromTab) => {
    // get an absolute offset reference to the top of the selections panel
    const absoluteTop = accordionRectTop.value - headerRect.value.height;

    /**
     * if the item you're selecting has open accordion items before it
     * those need to be considered in scrollToPosition
     */
    let elementOffset = 0;
    accordionRefs.value.forEach((_, index) => {
        event > index
            ? (elementOffset += accordionRefs.value[index].getBoundingClientRect().height)
            : null;
    });

    props.menuItem.configurations?.forEach((item, index) => {
        event >= index && item.configuration_type === 'upgrade' ? (elementOffset += 8) : null;
    });

    // where the page will scroll to line up with accordion tabs selected
    const scrollToPosition = absoluteTop - 66 + elementOffset;

    /**
     * if the item is open and it was clicked itself, close it
     * if the item is open and it was clicked in the tab, scroll to it
     * if the item is closed, open it and scroll to it
     */
    if (expandedAccordionIndices.value[event] === true && !fromTab) {
        expandedAccordionIndices.value[event] = false;
    } else {
        expandedAccordionIndices.value[event] = true;
        window.scrollTo({
            top: scrollToPosition,
            behavior: 'smooth',
        });
    }
};

const emit = defineEmits(['update:selections', 'update:quantities', 'action']);

const internalSelections = ref(props.selections);
const handleSelectionsUpdate = (config, $event) => {
    internalSelections.value[config.id] = $event;
    emit('update:selections', internalSelections.value);
};

const internalQuantities = ref(props.quantities);
const handleQuantitiesUpdate = (config, $event) => {
    internalQuantities.value[config.id] = $event;
    emit('update:quantities', internalQuantities.value);
};

watch(
    () => expandedAccordionIndices.value,
    () => {
        emit('action');
    },
    { deep: true },
);

const getComponent = (config) => {
    if (config.layout_type === 'list-view') {
        return ConfigurationListPanel;
    } else if (config.layout_type === 'grid-view') {
        return ConfigurationGridPanel;
    } else if (config.required === false && config.selection_type === 'single') {
        return ConfigurationDropdownPanel;
    } else if (props.itemWithin || config.layout_type === null) {
        return component.value;
    }
};

const parseScheduledConfig = () => {
    const rawConfig = props.menuItem?.configurations;
    const parsedConfig = [];
    rawConfig.forEach((item, i) => {
        const configItem = item;
        const uniqueChoices = [],
            newChoices = [];
        configItem.choices.forEach((choice) => {
            const choiceName = choice.choice.name;
            if (uniqueChoices.indexOf(choiceName) === -1) {
                newChoices.push(choice);
                uniqueChoices.push(choiceName);
            }
        });
        parsedConfig.push({
            ...item,
            choices: newChoices,
        });
    });
    return parsedConfig;
};

const configs = computed(() => {
    if (props.hideUpgrades == true) {
        return props.menuItem?.configurations.filter((config) => config.type !== 'upgrade');
    } else if (props.menuItem.type === 'schedule') {
        return parseScheduledConfig();
    } else {
        return props.menuItem?.configurations;
    }
});
</script>

<template>
    <div id="accordionPanel">
        <!-- tabbed navigation -->
        <div class="sticky top-[100px] z-20 -mt-[16px] bg-white pt-[20px]">
            <TabbedNavigation
                @expand="handleAccordion($event, true)"
                v-bind="{ configurations: configs }"
                class="border-2"
                :class="itemWithinWithin ? '-mt-12' : ''"
                v-if="menuItem.configurations"
            />
            <TabbedNavigation
                @expand="handleAccordion($event, true)"
                v-bind="{ comboItemOptions: menuItem.comboItemOptions }"
                class="border-2"
                v-if="menuItem.comboItemOptions"
            />
        </div>
        <div class="relative bg-white" ref="accordionElement" v-if="ready">
            <!-- ingredients accordion -->
            <div v-if="menuItem.type !== 'combination'">
                <div ref="listElements">
                    <template v-for="(configuration, index) in configs" :key="configuration.id">
                        <component
                            :is="getComponent(configuration)"
                            :id="`AccordionItem${configuration.id}`"
                            v-bind="{ configuration }"
                            :selections="internalSelections[configuration.id]"
                            @update:selections="handleSelectionsUpdate(configuration, $event)"
                            :quantities="internalQuantities[configuration.id]"
                            @update:quantities="handleQuantitiesUpdate(configuration, $event)"
                            :expanded="expandedAccordionIndices[index]"
                            :handleAccordion="() => handleAccordion(index, false)"
                            :name="`AccordionItem-${configuration.id}`"
                            :item-within="itemWithin"
                            :item-within-within="itemWithinWithin"
                            :within-within-config-id="menuItem.id"
                            :parent-combo-item="parentComboItem"
                            :parent-combo-item-option="parentComboItemOption"
                            :choice-id="choiceId"
                            :adder-pricing-size="adderPricingSize"
                            :hide-upgrades="hideUpgrades && index === configs.length - 1"
                            :type="menuItem.type"
                        />
                    </template>
                </div>
            </div>
            <div v-else>
                <div ref="listElements">
                    <template
                        v-for="(comboItemOption, index) in menuItem.comboItemOptions"
                        :key="comboItemOption.id"
                    >
                        <component
                            :is="getComboComponent(comboItemOption)"
                            :id="`AccordionItem${comboItemOption.id}`"
                            v-bind="{ comboItemOption }"
                            :configuration="comboItemOption"
                            :selections="internalSelections[comboItemOption.id]"
                            @update:selections="handleSelectionsUpdate(comboItemOption, $event)"
                            :quantities="internalQuantities[comboItemOption.id]"
                            @update:quantities="handleQuantitiesUpdate(comboItemOption, $event)"
                            :expanded="expandedAccordionIndices[index]"
                            :handleAccordion="() => handleAccordion(index, false)"
                        />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
