<script setup>
import { computed } from 'vue';
import { CreditCardForm, ContactInfoForm, PaymentProfileForm, DeliveryInfoForm } from '.';
import { useCheckoutStore, useAuthNetStore, useOrderStore } from '@/customer/Composables';
import { usePage } from '@inertiajs/vue3';

const authnet = useAuthNetStore();
const paymentProfiles = computed(() => usePage().props.paymentProfiles?.data);
</script>
<template>
    <div class="space-y-10">
        <PaymentProfileForm v-if="paymentProfiles?.length" />
        <template v-if="!authnet.responseData.paymentProfileId">
            <CreditCardForm />
        </template>
        <ContactInfoForm />
    </div>
</template>
