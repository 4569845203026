<script setup>
import { ref } from 'vue';
import OrderSummaryDetails from './OrderSummaryDetails.vue';
import Modal from './OrderSummaryModal/Modal.vue';

const modalData = ref({
    open: false,
    step: '',
});

const handleModal = (open, step = '') => {
    document.body.style.overflow = open ? 'hidden' : '';
    if (typeof step === 'string' && step.length > 1) {
        modalData.value.step = step;
    }
    modalData.value.open = open;
};
</script>

<template>
    <div class="rounded-xl border-2 border-palmers-charcoal-grey bg-white p-6 shadow-lg">
        <Modal @close-modal="handleModal(false)" :step="modalData.step" v-if="modalData.open" />
        <OrderSummaryDetails @open-modal="handleModal(true, $event)" />
        <!-- <DeliveryOptions v-if="order.isDelivery" /> -->
        <!-- <CarryOutOptions
                        v-if="order.isCarryOut"
                        @update-error="($event) => (preventCheckout = $event)"
                    /> -->
    </div>
</template>
