<script setup>
import { RadioGroup, RadioGroupOption } from '@headlessui/vue';

import { useMenuItemStore, useCartStore } from '@/customer/Composables';
import { computed, ref, watch } from 'vue';
import BaseButton from '@/customer/Components/BaseButton.vue';

const props = defineProps({
    quantities: {
        type: Object,
        default: () => ({}),
    },
});

const $store = useMenuItemStore();

const modelValue = computed({
    get() {
        return $store.menuItem.size;
    },
    set(value) {
        $store.setSize(value);
    },
});

const choiceMap = computed(() => $store.choiceMap);

const hasBulk = computed(() => $store.sizes.filter((size) => size.quantity > 1).length > 0);
const size = computed(() => $store.sizes.find((size) => size.id === $store.menuItem.size));

const editingItem = computed(() => $store.menuItem.editing);
const quantity = ref(editingItem.value ? 1 : $store.quantity);

const bulkQuantity = computed(() => size.value.quantity);
const count = ref(0);

watch(
    () => [choiceMap.value, size.value],
    () => {
        if (hasBulk.value) {
            count.value = 0;
            const choiceArr = Object.values(choiceMap.value);
            choiceArr.forEach(
                (option) => (count.value += props.quantities[option.configuration_id][option.id]),
            );
        }
        $store.preventBulkCheckout = hasBulk.value && count.value !== bulkQuantity.value;
    },
    { deep: true, immediate: true },
);

const emit = defineEmits(['update:modelValue']);
</script>

<template>
    <div
        class="my-2 mr-2 flex justify-between rounded-full border-2 p-2 px-4 text-center font-semibold text-palmers-charcoal-grey"
        v-if="hasBulk"
    >
        <p>
            {{ size.label }}
        </p>
        <p
            :class="[
                count === bulkQuantity ? 'text-palmers-avocado-green' : 'text-palmers-dragon-fruit',
            ]"
        >
            {{ count }} / {{ bulkQuantity }}
            <span v-if="count < bulkQuantity" class="font-light text-palmers-charcoal-grey">
                (add some more)
            </span>
            <span v-if="count > bulkQuantity" class="font-light text-palmers-charcoal-grey">
                (remove some)
            </span>
        </p>
    </div>
    <RadioGroup v-model="modelValue" v-if="$store.sizes.length > 1">
        <div class="grid w-full -translate-x-1 grid-cols-2 gap-4 px-2 py-1">
            <template v-for="size in $store.sizes" :key="size.id">
                <RadioGroupOption
                    :value="size.id"
                    as="template"
                    v-slot="{ active, checked }"
                    class="w-full"
                >
                    <BaseButton
                        :type="`${checked ? 'secondary' : 'primary'}`"
                        :class="{
                            'supports-hover:hover:cursor-default supports-hover:hover:bg-[#96c432]':
                                checked,
                        }"
                        class="flex justify-center py-3"
                    >
                        {{ size.label }}
                    </BaseButton>
                </RadioGroupOption>
            </template>
        </div>
    </RadioGroup>
</template>
