import AmexIcon from './aaronfagan-amex.vue'
import DinersIcon from './aaronfagan-diners.vue'
import DiscoverIcon from './aaronfagan-discover.vue'
import MastercardIcon from './aaronfagan-mastercard.vue'
import VisaIcon from './aaronfagan-visa.vue'
import GenericFrontIcon from './aaronfagan-generic-front.vue'
import GenericBackIcon from './aaronfagan-generic-back.vue'
// import CVVIcon from './noun-project-cvv.vue'

export default {
    'amex': AmexIcon,
    'diners': DinersIcon,
    'discover': DiscoverIcon,
    'mastercard': MastercardIcon,
    'visa': VisaIcon,
    'generic': GenericFrontIcon,
    'code': GenericBackIcon,
}
