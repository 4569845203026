<script setup>
import { computed, ref } from 'vue';
import DatePicker from 'primevue/datepicker';
import moment from 'moment';

const props = defineProps({
    modelValue: {
        type: [String, Date, Object, null],
        required: false,
        default: null,
    },
    placeholder: {
        type: String,
        default: 'Select a date',
    },
    minTime: {
        type: [Object, String, Date],
        required: true,
    },
    maxTime: {
        type: [Object, String, Date],
        required: true,
    },
});

const selectedDate = ref(moment(props.modelValue, 'YYYY-MM-DD h:mm A'));

const emit = defineEmits(['update:modelValue']);

const handleUpdate = ($event) => {
    console.log($event);
    selectedDate.value = moment($event).format('YYYY-MM-DD hh:mm A');
    emit('update:modelValue', selectedDate.value);
};
</script>

<template>
    <DatePicker
        :timeOnly="true"
        hourFormat="12"
        showIcon
        :pt="{
            timePicker: 'flex rounded-lg justify-center items-center bg-palmers-havarti px-2',
        }"
        fluid
        iconDisplay="input"
        :placeholder="placeholder"
        :model-value="moment(props.modelValue, 'YYYY-MM-DD h:mm A').toDate()"
        @update:model-value="($event) => handleUpdate($event)"
        dateFormat="M dd, yy"
        :minDate="moment(minTime).toDate()"
        :maxDate="moment(maxTime).toDate()"
    >
        <template #inputicon="slotProps">
            <i class="pi pi-clock" @click="slotProps.clickCallback" />
        </template>
    </DatePicker>
</template>
