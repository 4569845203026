<script setup>
import { Head, useForm, usePage } from '@inertiajs/vue3';
import AccountSettingsLayout from '@/customer/Layouts/AccountSettingsLayout.vue';
import ContactForm from '@/customer/Pages/Profile/Partials/ContactForm.vue';
import { useLang } from '@/customer/Composables';

const { lang } = useLang();

const form = useForm(route().current(), {
    label: null,
    number: '',
});

const submit = () => {
    const url = route('customer.profile.contacts.store');

    form.post(url, {
        preserveScroll: true,
        preserveState: true,
    });
};
</script>

<template>
    <AccountSettingsLayout :title="lang.title_add" class="pb-6 md:pb-0">
        <Head title="Profile" />

        <ContactForm v-bind="{ form }" @submit="submit" />
    </AccountSettingsLayout>
</template>
