<script setup>
import { PhotoIcon } from '@heroicons/vue/20/solid';
import { ChevronRightIcon } from '@heroicons/vue/24/outline';
import { Head, router } from '@inertiajs/vue3';
import { MenuItem } from './Partials';
import { onBeforeMount, ref } from 'vue';
import Spinner from '@/customer/Components/UI/Progress/Spinner.vue';
import { useOrderStore } from '@/customer/Composables';

const props = defineProps({
    menu: { type: Object },
});

const orderStore = useOrderStore();

const loading = ref(true);

const redirectToMenuItem = async (menu) => {
    if (menu.menuItems?.length !== 1) {
        loading.value = false;
        return;
    }

    const rawURL = menu.menuItems[0].href;
    const basePath = menu.type === 'catering' ? 'catering/' : 'menu/';
    const basePathIndex = rawURL.indexOf(basePath);

    if (basePathIndex === -1) {
        loading.value = false;
        return;
    }

    const parsedAtMenu = rawURL.slice(basePathIndex + basePath.length);
    const parts = parsedAtMenu.split('/');
    const currentMenu = parts.slice(0, -1).join('/');
    const itemSlug = parts[parts.length - 1];
    const routeURL = route('customer.menu.menuItem.show', {
        type: menu.type,
        menu: currentMenu,
        menuItem: itemSlug,
    });

    try {
        await router.get(routeURL);
    } catch (error) {
        console.error('Failed to redirect:', error);
        loading.value = false;
    }
};

const menuItemsForThisLocation = ref(
    props.menu?.menuItems?.filter((menuItem) =>
        menuItem.locations.includes(orderStore.selectedLocation.id),
    ),
);

onBeforeMount(() => {
    redirectToMenuItem(props.menu);
});
</script>

<template>
    <Head :title="menu.name || 'Menu'" />

    <div v-if="loading" class="mx-auto flex h-[50vh] max-w-xl items-center justify-center">
        <Spinner class="size-16 text-palmers-dragon-fruit" />
    </div>
    <div v-else class="md:mx-auto md:max-w-screen-md" id="menu">
        <p class="m-5 mb-5 text-center text-gray-900 md:mb-10">{{ menu.description }}</p>
        <ul v-if="menuItemsForThisLocation?.length" class="grid grid-cols-1 divide-y border">
            <template v-for="menuItem in menuItemsForThisLocation" :key="menuItem.sort_order">
                <MenuItem v-bind="{ menuItem }" />
            </template>
        </ul>

        <template v-else>
            <div class="border p-6 text-center text-lg">
                No Items for <strong>{{ menu.name }}</strong>
            </div>
        </template>
    </div>
</template>
