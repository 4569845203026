<script setup>
import { computed, useAttrs } from 'vue';
import { ErrorBoundary } from '@/common/Components';
import { RadioOptionProps } from '../internal';
import InputLabel from '@/company/Components/InputLabel.vue';

const emit = defineEmits([
    'update:modelValue',
    'focus',
    'blur',
    'input',
    'change',
    'keyup',
    'keydown',
    'maska',
]);

const attrs = useAttrs();
const props = defineProps({
    ...RadioOptionProps,
});

const proxyChecked = computed({
    get: () => props.modelValue || attrs.checked || props.checked,
    set: (value) => emit('update:modelValue', value),
});
</script>

<template>
    <component
        :is="label || !!$slots.label ? 'label' : 'span'"
        class="group h-full w-full cursor-pointer items-center space-x-2"
        :class="[label || !!$slots.label ? 'inline-flex' : 'block']"
    >
        <template v-if="typeof props.as === 'string'">
            <input
                v-bind="{ name, value, checked, disabled, ...$attrs }"
                v-model="proxyChecked"
                :type="props.as === 'text' ? 'radio' : props.as"
                class="h-6 w-6 border-2 border-palmers-dragon-fruit text-primary checked:bg-palmers-dragon-fruit focus:ring-2 checked:focus:bg-palmers-dragon-fruit disabled:checked:bg-gray-500 checked:supports-hover:hover:bg-palmers-dragon-fruit"
                :class="inputClass"
                :tabindex="tabindex"
            />
        </template>
        <template v-else>
            <ErrorBoundary>
                <component
                    :is="props.as"
                    ref="inputElement"
                    v-bind="{
                        ...$attrs,
                        label,
                        value,
                        checked,
                        disabled,
                        errorMessage,
                        description,
                        option,
                    }"
                    :name="$attrs['name'] ?? name"
                    :id="$attrs['id'] ?? name"
                    @click="$emit('update:modelValue', $event)"
                />
            </ErrorBoundary>
        </template>

        <slot name="label" v-if="!props.as?.props?.label" v-bind="{ name, label, help }">
            <InputLabel :for="name" :value="label" :help="help" as="span" />
        </slot>
    </component>
</template>
