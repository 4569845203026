<script setup>
import { ref, useAttrs } from 'vue';
import { Head } from '@inertiajs/vue3';
import InputText from '@/customer/Components/InputText.vue';
import { useCartStore, useOrderStore } from '@/customer/Composables';
import { CartContents, AddOnItems } from '@/customer/Components/Cart';
import {
    CheckoutLayout,
    OrderSummary,
    ReviewDetails,
    DeliveryOptions,
    CarryOutOptions,
    PaymentForm,
    Disclaimers,
    Actions,
    Errors,
    DeliveryInfoForm,
    CateringContactForm,
    CateringActions,
} from './Partials';

const order = useOrderStore();

const cart = useCartStore();
cart.initialize();

defineProps({
    app: { type: Object },
});

const preventCheckout = ref(false);

const preventCateringCheckout = ref(true);
const attrs = useAttrs();
</script>

<template>
    <Head :title="`Checkout`" />

    <CheckoutLayout>
        <template #form>
            <div class="relative z-10 space-y-7 md:space-y-10 xl:static">
                <OrderSummary />
                <template v-if="!order.isCatering">
                    <div
                        class="space-y-6 rounded-xl border-2 border-palmers-charcoal-grey bg-white p-6 shadow-lg"
                    >
                    
                        <ReviewDetails :deliveryFee="attrs.deliveryFee" />
                    </div>
                    <div
                        class="space-y-10 rounded-xl border-2 border-palmers-charcoal-grey bg-white p-6 shadow-lg"
                    >
                        <PaymentForm />
                        <Errors />
                    </div>
                    <div
                        v-if="order.isDelivery"
                        class="space-y-6 rounded-xl border-2 border-palmers-charcoal-grey bg-white p-6 shadow-lg"
                    >
                        <DeliveryInfoForm />
                    </div>
                    <div>
                        <Actions :prevent-checkout="preventCheckout" />
                        <Disclaimers />
                    </div>
                </template>
                <template v-else>
                    <div
                        class="space-y-10 rounded-xl border-2 border-palmers-charcoal-grey bg-white p-6 shadow-lg"
                    >
                        <CateringContactForm
                            @allow="preventCateringCheckout = false"
                            @deny="preventCateringCheckout = true"
                        />
                    </div>
                    <div>
                        <CateringActions :prevent-checkout="preventCateringCheckout" />
                        <Disclaimers />
                    </div>
                </template>
            </div>
        </template>
        <template #cart>
            <div>
                <CartContents itemClass="px-0" />
            </div>
        </template>
        <template #addons>
            <template v-if="!order.isCatering">
                <template v-for="(menu, i) of cart.menus" :key="i">
                    <AddOnItems :items="menu.menuItems" :label="menu.name" />
                </template>
            </template>
            <template v-else>
                <div
                    class="w-full space-y-4 bg-palmers-havarti px-10 py-8 text-palmers-charcoal-grey"
                >
                    <div class="flex items-center justify-between gap-x-2">
                        <h3 class="text-4xl font-bold">Quote:</h3>
                        <div class="text-5xl font-bold">{{ cart.summary.subtotal.formatted }}</div>
                    </div>
                    <p class="text-md leading-8">
                        Upon placing your inquiry, we reach out via the contact information you have
                        provided. This allows us to confirm that our idea of greatness is a shared
                        one. A catering specialist will work with you to ensure your expectations
                        are met, and the proper considerations made (we've done this a few times).
                        After confirming we are on the same page, a final cost will be provided for
                        your order.
                    </p>
                </div>
            </template>
        </template>
    </CheckoutLayout>
</template>
