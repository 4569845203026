<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { directive as vClickAway } from 'vue3-click-away';
import { twMerge } from 'tailwind-merge';
import onAnimationFrame from '../Composables/onAnimationFrame';

const props = defineProps({
    align: {
        type: String,
        default: 'right',
    },
    width: {
        type: String,
        default: '48',
    },
    contentClasses: {
        type: String,
        default: 'py-1 bg-white',
    },
    popperClasses: {
        type: String,
        default: '',
    },
    arrow: {
        type: Boolean,
        default: false,
    },
    open: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    isCheckout: {
        type: Boolean,
        default: false,
    },
    checkoutModal: {
        type: Boolean,
        default: false,
    },
});

const closeOnEscape = (e) => {
    if (open.value && e.key === 'Escape') {
        open.value = false;
    }
};

onMounted(() => document.addEventListener('keydown', closeOnEscape));
onUnmounted(() => document.removeEventListener('keydown', closeOnEscape));

const widthClass = computed(() => {
    return {
        48: 'w-48',
        72: 'w-80',
    }[props.width.toString()];
});

const alignmentClasses = computed(() => {
    if (props.align === 'left') {
        return 'origin-top-left left-0';
    } else if (props.align === 'right') {
        return 'origin-top-right right-0';
    } else if (props.align === 'center') {
        return 'origin-top-right left-1/2 -translate-x-1/2';
    } else {
        return 'origin-top';
    }
});

const arrowClass = computed(() => {
    const className = ['dropdown-arrow'];
    if (props.align === 'left') {
        className.push('before:left-3 after:left-3');
    } else if (props.align === 'right') {
        className.push('before:right-3 after:right-3');
    } else if (props.align === 'center') {
        className.push(
            'before:left-1/2 after:left-1/2 before:-translate-x-1/2 after:-translate-x-1/2',
        );
    } else {
        return;
    }

    return className.join(' ');
});

const popperClasses = computed(() => props.popperClasses);

const open = ref(!props.disabled && props.open);
const contentWrapper = ref(null);
const contentBackgroundColor = ref(null);

const close = () => {
    open.value = false;
};

const emit = defineEmits(['responsive-dialog', 'open-modal']);

const show = () => {
    if (!props.disabled) {
        if (props.isCheckout && props.checkoutModal) {
            emit('open-modal');
        } else if (window.innerWidth > 894 || props.isCheckout) {
            open.value = true;
        } else {
            emit('responsive-dialog');
        }
    }
};

onAnimationFrame(() => {
    contentBackgroundColor.value = contentWrapper.value
        ? window.getComputedStyle(contentWrapper.value, null).getPropertyValue('background-color')
        : null;
});
</script>

<template>
    <div class="relative">
        <div @click="show">
            <slot name="trigger" />
        </div>

        <Transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95"
        >
            <div class="absolute top-full w-full">
                <div v-if="open" :class="[twMerge('absolute', 'top-3', 'w-full', popperClasses)]">
                    <div
                        class="absolute !z-50 rounded-md shadow-lg"
                        :class="[widthClass, alignmentClasses, arrowClass]"
                        @click="close"
                        v-click-away="close"
                    >
                        <div
                            ref="contentWrapper"
                            :class="
                                twMerge(
                                    'absolute left-1/2 w-full -translate-x-1/2 rounded-md shadow-lg ring-1 ring-black ring-opacity-5',
                                    contentClasses,
                                )
                            "
                        >
                            <slot name="content" />
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<style scoped>
.dropdown-arrow::before,
.dropdown-arrow::after {
    content: '';
    @apply absolute block h-3 w-3;
    bottom: calc(100% - 2px);
    border-left: theme('width.3') solid transparent;
    border-right: theme('width.3') solid transparent;
    border-bottom: theme('height.3') solid transparent;
}
.dropdown-arrow:before {
    border-left: calc(theme('width.3') + 1px) solid transparent;
    border-right: calc(theme('width.3') + 1px) solid transparent;
    border-bottom: calc(theme('height.3') + 2px) solid transparent;
    border-bottom-color: theme('colors.gray.100');
}
.dropdown-arrow:after {
    @apply z-[60];
    border-bottom-color: v-bind(contentBackgroundColor);
}
</style>
