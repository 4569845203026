<script setup>
import { computed } from 'vue';
const props = defineProps({
    type: {
        type: String,
        default: 'primary',
        required: false,
        validators: (value) => ['primary', 'secondary'].includes(value),
    },
    as: {
        type: [String, Object],
        default: 'button',
    },
    href: {
        type: String,
        default: '',
    },
});

const getButtonStyling = computed(() => {
    let classes =
        'inline-flex items-center px-4 py-2 border-2 border-black shadow-[4px_3px] shadow-palmers-avocado-green rounded-full font-bold text-xs text-black uppercase tracking-widest enabled:active:translate-y-1 enabled:active:translate-x-1 active:shadow-none focus:outline-none transition ease-in-out duration-100';
    classes += ' disabled:shadow-none disabled:opacity-50 disabled:!bg-white';
    if (props.type === 'secondary') {
        classes +=
            ' bg-palmers-avocado-green supports-hover:hover:bg-palmers-avocado-green active:bg-palmers-avocado-green';
    } else {
        classes +=
            ' bg-palmers-white supports-hover:hover:bg-palmers-avocado-green active:bg-palmers-havarti';
    }
    return classes;
});
</script>

<template>
    <component :is="as" v-bind="{ href }" :class="getButtonStyling">
        <slot />
    </component>
</template>
