<script setup>
import { ref, provide, watch, inject, computed } from 'vue';

const emit = defineEmits(['select']);
const props = defineProps({
    as: {
        type: String,
        default: 'div',
    },
    value: {
        type: [String, Object, Number],
        default: () => ({}),
    },
    defaultValue: {
        type: Object,
        default: () => ({}),
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    name: {
        type: String,
        default: 'checkbox',
    },
    clickable: {
        type: Boolean,
        default: true,
    },
    focusable: {
        type: Boolean,
        default: true,
    },
});

const checkboxGroup = inject(Symbol.for('CheckboxGroup'));
const uid = checkboxGroup.registerOption(props.value);
const checked = computed(() => checkboxGroup.isSelected(uid));
const active = computed(() => checkboxGroup.isActive(uid));

const focus = () => {
    checkboxGroup.markActive(uid);
};

const parentFocus = (e) => {
    if (props.focusable) {
        e.preventDefault();
        e.stopPropagation();
        focus();
    }
};

const select = () => {
    checkboxGroup.select(uid);
};

const parentSelect = (e) => {
    if (props.clickable) {
        e.preventDefault();
        e.stopPropagation();
        select();
    }
};
</script>

<template>
    <template v-if="props.as === 'template'">
        <slot
            v-bind="{ checked, active, select, focus }"
            @keydown.space.stop.prevent="select"
            @click="parentSelect"
            @focus="parentFocus"
        />
    </template>
    <template v-else>
        <component
            :is="as"
            @keydown.space.stop.prevent="select"
            @click="parentSelect"
            @focus="parentFocus"
        >
            <slot v-bind="{ checked, active, select, focus }" />
        </component>
    </template>
</template>
