<script setup>
import { ref, inject, computed } from 'vue';
import { orderState } from '@/customer/Utils/keys';
import { optionIcon, optionSwitcher, optionSwitchers } from '@/customer/Components/Order';
import { usePage } from '@inertiajs/vue3';

const order = inject(orderState);

const optionValue = (option) => {
    const value = order.progress[option];
    return typeof value === 'string' ? value : option;
};

const optionValueLabel = (option) =>
    order.options[option]['options'].find(({ value }) => value === optionValue(option))?.display;

const progressList = computed(() =>
    Object.keys(optionSwitchers).filter((name) => order.progress[name] != null),
);

const modalComponents = ref(['fulfillment_type', 'ready_type']);

const currentDelayInMinutes = computed(() => {
    let currentLocation = usePage().props.app.locations.filter(location => location.slug == order.form.location)[0];
    let delay = parseInt(currentLocation.carryout_delay);
    if(order.form.fulfillment_type == 'delivery') {
        delay += parseInt(currentLocation.delivery_delay);
    }
    return delay;
})
</script>

<template>
    <div>
        <h1 class="text-xl font-bold uppercase text-palmers-charcoal-grey">
            {{ order.isCatering ? 'Catering' : 'Order' }} Summary
        </h1>
        <div class="items-top -mx-3 mt-4 space-y-4">
            <template v-for="option in progressList">
                <div>
                    <component
                        :is="optionSwitcher(option)"
                        :icon="optionIcon(optionValue(option))"
                        :label="optionValueLabel(option) == 'ASAP' ? optionValueLabel(option) + ' (~' + currentDelayInMinutes + ' minutes)' : optionValueLabel(option)"
                        iconClass="w-9 h-9 text-palmers-dragon-fruit"
                        class="inline-block text-palmers-charcoal-grey"
                        :isCheckout="true"
                        @open-modal="
                            modalComponents.indexOf(option) !== -1
                                ? $emit('open-modal', $event)
                                : undefined
                        "
                    />
                </div>
            </template>
        </div>
    </div>
</template>
