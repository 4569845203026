import { computed, toValue } from 'vue';
import { usePage } from '@inertiajs/vue3';

export default function useApp() {
    //  // console.log("app", (window.appCount = (window.appCount || 0) + 1));
    const app = computed(() => usePage().props.app);
    const locations = toValue(app.value.locations);
    const social = toValue(app.value.social);
    const copyright = toValue(app.value.copyright);
    const policies = toValue(app.value.policies);
    const footer = toValue(app.value.footer);

    return { app, locations, social, copyright, policies, footer };
}
