<script setup>
import { Head, usePage } from '@inertiajs/vue3';
import { GridItem } from './Partials';
import { computed } from 'vue';
import { useOrderStore } from '@/customer/Composables';

const props = defineProps({
    menus: { type: Object },
});

const menus = computed(() => props.menus.data);
const orderStore = useOrderStore();
</script>

<template>
    <Head :title="`Menu`" />

    <div class="mx-auto max-w-screen-lg md:min-h-screen md:space-y-6 md:py-6" id="menus">
        <h1 class="hidden text-center text-xl font-semibold uppercase md:block">
            Select your items 
        </h1>

        <ul
            class="grid divide-y border-b md:grid-cols-3 md:gap-6 md:divide-y-0 md:border-0 lg:grid-cols-4"
        >
            <template v-for="menu in menus" :key="menu.slug">
                <GridItem v-bind="{ menu }" />
            </template>
        </ul>
    </div>
</template>
