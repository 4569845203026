<script setup>
import CartItem from '@/customer/Components/Cart/CartItem.vue';
import { useCartStore } from '@/customer/Composables';
import { ErrorBoundary } from '@/common/Components';
import { Link } from '@inertiajs/vue3';

const cart = useCartStore();

const props = defineProps({
    itemClass: {
        type: String,
        default: 'px-6 sm:px-20',
    },
});
</script>

<template>
    <div>
        <ul role="list" class="divide-y-2" v-if="!cart.isEmpty">
            <template v-for="item in cart.tree" :key="item.uuid">
                <li :class="[itemClass, 'flex items-center space-x-3 pb-8 pt-4 sm:pt-8']">
                    <ErrorBoundary message="Something went wrong.">
                        <template #default>
                            <CartItem
                                class="w-full"
                                :item="cart.getItem(item.uuid)"
                                @remove="cart.removeItem(item)"
                                @updateQuantity="cart.updateItemQuantity(item)"
                            />
                        </template>
                        <template #after>
                            <div>
                                <button
                                    type="button"
                                    @click.prevent="cart.reset()"
                                    class="btn bg-palmers-dragon-fruit p-2 text-white supports-hover:hover:underline"
                                >
                                    Try Again
                                </button>
                            </div>
                        </template>
                    </ErrorBoundary>
                </li>
            </template>
            <!-- Feature: Reset cart  -->
            <!-- <li :class="[itemClass, 'py-8']">
                <h1 class="text-xl">Want to start fresh?</h1>
                <button
                    type="button"
                    @click.prevent="cart.reset()"
                    class="btn mt-4 block w-full bg-palmers-dragon-fruit p-4 text-center text-2xl font-bold uppercase text-white"
                >
                    Start Over
                </button>
            </li> -->
        </ul>
        <div class="space-y-12 px-10 py-8 text-palmers-charcoal-grey sm:px-20" v-else>
            <h1 class="text-xl">Your cart is empty!</h1>

            <Link
                :href="route('customer.order.start')"
                @before="cart.close"
                class="btn mt-4 block w-full bg-palmers-dragon-fruit p-4 text-center text-2xl font-bold uppercase text-white"
            >
                View Menu
            </Link>
        </div>
    </div>
</template>
