<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { PhotoIcon, MinusIcon, PlusIcon } from "@heroicons/vue/20/solid";
import { Head, useForm, usePage  } from '@inertiajs/vue3';
import Checkbox from '@/customer/Components/UI/Input/Checkboxes/CheckboxInput.vue';
import InputTextarea from '@/customer/Components/InputTextarea.vue';
import InputGroup from '@/customer/Components/InputGroup.vue';
import Accordion from "@/customer/Components/UI/Accordion/Accordion.vue";

const props = defineProps({
    ingredient: { type: Object },
    selected: { type: Boolean },
    selectedClass: { type: String },
});

const emit = defineEmits(["updateCount", "select"]);

const countMinus = () => {
    update(props.ingredient.count - 1);
}

const countPlus = () => {
    update(props.ingredient.count + 1);
}

const select = () => {
    if(!props.selected) {
        emit('select', props.ingredient);
    }
}

const update = (count) => {
    emit('updateCount', props.ingredient, count);
}

</script>

<template>
    <div
        class="m-2 p-4 mx-2 bg-palmers-havarti flex flex-col"
        :class="selected ? selectedClass : 'cursor-pointer'"
        @click="select()"
    >
        <div class="font-semibold text-center">
            {{ ingredient.name }}
        </div>
        <div class="flex justify-center items-center grow">
            <PhotoIcon class="w-16 h-16 text-gray-500 opacity-50" />
        </div>
        <div class="flex items-center justify-evenly" v-if="selected">
            <MinusIcon
                class="w-8 h-8 p-1 border-2 rounded-full border-palmers-dragon-fruit cursor-pointer"
                @click.stop="countMinus()"
            />
            <span class="w-8 h-8 p-1 border-2 rounded-full border-palmers-dragon-fruit flex items-center justify-center">
                {{ ingredient.count }}
            </span>
            <PlusIcon
                class="w-8 h-8 p-1 border-2 rounded-full border-palmers-dragon-fruit cursor-pointer"
                @click.stop="countPlus()"
            />
        </div>
        <!-- for appropriate spacing -->
        <div class="h-8" v-else></div>
    </div>
</template>
