<script setup>
import AppHeader from '@/customer/Layouts/MainLayout/Header.vue';
import AppFooter from '@/customer/Layouts/MainLayout/Footer.vue';
import useOrderProvider from '../Composables/useOrderProvider';
import { Head, Link } from '@inertiajs/vue3';
import OrderBanners from '../Components/Order/OrderBanners.vue';
import Breadcrumbs from '../Components/Navigation/Breadcrumbs.vue';
import NewBreadcrumbs from '../Components/Navigation/New/NewBreadcrumbs.vue';
import OrderTopHeader from '@/customer/Components/Order/OrderTopHeader.vue';
import { computed, onMounted, onUnmounted, watch, ref, provide, nextTick } from 'vue';
import { get } from 'lodash';
import * as yup from 'yup';

const props = defineProps({
    app: { type: Object },
    auth: { type: Object },
    order: { type: Object },
    breadcrumbs: { type: Object },
    lang: { type: Object },
    'vue::storefront': { type: Object },
});

useOrderProvider();

window.yup = yup;

const vueData = computed(() => props['vue::storefront']);
const layout = computed(() => get(vueData.value, 'layout', 'default').toLowerCase());

/**
 * Observe the parent tree for changes to the slot in order
 * to conditionally style the container for mobile/tablet views
 * via isBoundless. Array provided to computed property
 * aligns with an id assigned to the parent element of each page.
 */
const parentElement = ref(null);
const slotElementName = ref(null);
const slotElement = ref(null);

onMounted(() => {
    nextTick(() => {
        if (parentElement.value.firstElementChild.id === '') {
            slotElementName.value = parentElement.value.firstElementChild.firstElementChild.id;
        } else {
            slotElementName.value = parentElement.value.firstElementChild.id;
        }
        const config = { childList: true, subtree: true };
        const callback = (mutationList) => {
            for (const mutation of mutationList) {
                if (mutation.type === 'childList') {
                    if (parentElement.value.firstElementChild.id === '') {
                        slotElementName.value =
                            parentElement.value.firstElementChild.firstElementChild.id;
                    } else {
                        slotElementName.value = parentElement.value.firstElementChild.id;
                    }
                }
            }
        };
        const observer = new MutationObserver(callback);
        observer.observe(parentElement.value, config);
    });
});

onUnmounted(() => observer.disconnect());

const isBoundless = computed(
    () => ['menus', 'menu', 'menuItem', 'profile'].indexOf(slotElementName.value) !== -1,
);

const parentPadding = () => {
    if (slotElement.value) {
        const windowWidth = window.innerWidth;
        const containerWidth = slotElement.value.getBoundingClientRect().width;
        return containerWidth >= windowWidth ? 0 : (windowWidth - containerWidth - 63) / 2;
    }
    return 0;
};
const absoluteOffset = ref(0);
provide('absoluteOffset', absoluteOffset);

const setOffset = () => {
    const padding = parentPadding();
    absoluteOffset.value = padding;
};

watch(
    () => slotElement,
    () => setOffset(),
    { immediate: true, deep: true },
);

onMounted(() => document.addEventListener('resize', setOffset));
onUnmounted(() => document.removeEventListener('resize', setOffset));
</script>

<template>
    <!-- Page -->
    <div class="flex flex-col bg-white md:min-h-screen">
        <!-- <OrderTopHeader>
            <div
                class="flex flex-col items-center justify-center gap-x-2 gap-y-1 bg-pink p-2 text-center text-sm font-bold leading-none text-white [@media(width>920px)]:flex-row"
            >
                <p class="leading-[18px]">
                    Delivery is available for orders over $60 within our delivery area with a
                    minimum of 90 minutes notice.
                </p>
                <a class="underline underline-offset-2"> More info about delivery </a>
            </div>
        </OrderTopHeader> -->

        <Head>
            <link rel="icon" type="image/x-icon" :href="app?.favicon?.company_url" />
        </Head>

        <!-- App Header -->
        <AppHeader />

        <!-- Banner Area -->
        <OrderBanners />

        <!-- Breadcrumbs -->
        <!-- <Breadcrumbs :breadcrumbs="breadcrumbs" /> -->
        <NewBreadcrumbs :breadcrumbs="breadcrumbs" />

        <!-- Page Content -->
        <main class="flex flex-grow flex-col">
            <template v-if="layout === 'default'">
                <div
                    id="defaultLayout"
                    ref="parentElement"
                    class="relative mx-auto w-full max-w-screen-2xl flex-grow md:px-6 md:py-16"
                    :class="[isBoundless ? 'px-0' : 'px-4']"
                >
                    <div ref="slotElement">
                        <slot />
                    </div>
                </div>
            </template>

            <template v-if="layout === 'centered'">
                <div
                    ref="parentElement"
                    class="relative mx-auto w-full max-w-screen-2xl flex-grow px-6"
                >
                    <slot />
                </div>
            </template>

            <template v-if="layout === 'full'">
                <div ref="parentElement" class="relative w-full flex-grow">
                    <slot />
                </div>
            </template>
        </main>
    </div>
    <!-- App Footer -->
    <AppFooter />
</template>
