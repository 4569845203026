<script setup>
import { RadioGroup, RadioGroupOption } from '@headlessui/vue';
import { RadioInput } from '.';
import { get } from 'lodash';
import { getCurrentInstance, computed } from 'vue';
import { RadioGroupProps } from '../internal';
import { func } from '@/common/Utils';
import { InputLabel } from '@/company/Components';
import { useMenuItemStore } from '@/customer/Composables';

const emit = defineEmits(['update:modelValue']);
const $store = useMenuItemStore();

const props = defineProps({
    ...RadioGroupProps,
    adderPricingSize: {
        type: Number,
        required: false,
        default: 0,
    },
    schedule: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const optValue = (option) => {
    return get(option, props.valueKey);
};

const optLabel = (option) => {
    return get(option, props.labelKey);
};

const name = computed(() => props.name ?? `radio-${getCurrentInstance().uid}`);

const handleUpdate = ($event) => {
    emit('update:modelValue', [].concat($event));
};

const priceLabel = (label, option) => {
    if (
        option?.comboItemOption?.hide_adder_pricing == true ||
        option?.comboItemOption?.hide_adder_pricing == false
    ) {
        if (option.comboItemOption.hide_adder_pricing == true) {
            return label;
        }
    }
    if (option?.hide_schedule_adder_pricing == true) {
        return label;
    }
    if (!option.price.length || props.schedule) {
        return label;
    } else {
        const comparison = props.adderPricingSize ? props.adderPricingSize : $store.form.size;
        const optionAdditionalPrice = option.price.find(
            (priceObj) => priceObj.size.id === comparison,
        );
        if (
            Number(optionAdditionalPrice?.price?.formatted.slice(1)) !== 'NaN' &&
            Number(optionAdditionalPrice?.price?.formatted.slice(1)) > 0
        ) {
            return (
                label +
                ' <span class="text-sm ml-1 font-medium">' +
                `+${optionAdditionalPrice?.price?.formatted}` +
                '</span>'
            );
        } else {
            return label;
        }
    }
};

const gridRowCount = computed(() => {
    const len = props.options.length;
    return len < 5 ? len : Math.ceil(len / 2);
});
</script>

<template>
    <RadioGroup
        v-bind="{ by, defaultValue, disabled }"
        :model-value="[].concat(modelValue)[0]"
        @update:model-value="handleUpdate"
        :class="[
            'radioGroup',
            func(wrapperClass)(),
            wrapperClass.length === 0 ? 'grid grid-flow-col gap-y-2' : '',
        ]"
        :style="
            wrapperClass.length === 0
                ? `grid-template-rows: repeat(${gridRowCount}, minmax(0, 1fr));`
                : ''
        "
    >
        <template v-for="option in options" :key="optValue(option)">
            <RadioGroupOption
                v-bind="{ name, disabled }"
                :value="optValue(option)"
                v-slot="{ checked }"
                as="template"
            >
                <div :class="['radioItem', props.as?.__name, func(itemClass)(checked)]">
                    <RadioInput
                        v-bind="{ name, disabled, as, option, ...$attrs }"
                        :label="!!label ? optLabel(option) : label"
                        :value="optValue(option)"
                        :disabled="false"
                        tabindex="0"
                        :checked="checked"
                        @update:model-value="handleUpdate(optValue(option))"
                    >
                        <template #label="{ label, name, help }">
                            <InputLabel
                                :for="name"
                                :value="priceLabel(label, option)"
                                :help="help"
                                as="span"
                            />
                        </template>
                    </RadioInput>
                </div>
            </RadioGroupOption>
        </template>
    </RadioGroup>
</template>
