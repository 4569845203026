import { default as Cards } from '@/customer/Components/Icons/cards';

export function cardIcons() {
    return Cards;
}

export enum CardVendor {
    AMEX = 'AMEX',
    VISA = 'VISA',
    MASTERCARD = 'MASTERCARD',
    DISCOVER = 'DISCOVER',
    DINERS = 'DINERS',
    GENERIC = 'generic',
}

export function guessCardVendor(cc: any) {
    // try to guess the type of card vendor early
    // to format forms based on information needed.

    // https://locastic.com/blog/how-to-check-credit-card-type-with-javascript
    // client side cc parsing not provided by auth net, covers common cases.

    const cc_number: string = cc?.replaceAll(/\s/g, '');

    let amex = new RegExp('^3[47]');
    let visa = new RegExp('^4');

    let mastercard = new RegExp('^5[1-5]');
    let mastercard2 = new RegExp('^2[2-7]');

    let disco1 = new RegExp('^6011');
    let disco2 = new RegExp('^62[24568]');
    let disco3 = new RegExp('^6[45]');

    let diners = new RegExp('^3[0689]');
    let jcb = new RegExp('^35');

    if (visa.test(cc_number)) {
        return CardVendor.VISA;
    }
    if (amex.test(cc_number)) {
        return CardVendor.AMEX;
    }
    if (mastercard.test(cc_number) || mastercard2.test(cc_number)) {
        return CardVendor.MASTERCARD;
    }
    if (disco1.test(cc_number) || disco2.test(cc_number) || disco3.test(cc_number)) {
        return CardVendor.DISCOVER;
    }
    if (diners.test(cc_number)) {
        return CardVendor.DINERS;
    }

    return CardVendor.GENERIC;
}

export function detectCardVendor(cc) {
    // use the whole number to detect the card vendor.

    // https://locastic.com/blog/how-to-check-credit-card-type-with-javascript
    // client side cc parsing not provided by auth net, covers common cases.

    cc = cc?.replaceAll(/\s/g, '');

    let amex = new RegExp('^3[47][0-9]{13}$'); // 15 digits
    let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$'); // 13 or 16 digits?

    let mastercard = new RegExp('^5[1-5][0-9]{14}$'); // 16 digits
    let mastercard2 = new RegExp('^2[2-7][0-9]{14}$'); // 16 digits

    let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$'); // 15 or 16 digits
    let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$'); // 15 or 16 digits
    let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$'); // 15 or 16 digits

    let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$'); // 15 or 16 digits
    let jcb = new RegExp('^35[0-9]{14}[0-9]*$'); // 15 or 16 digits

    if (visa.test(cc)) {
        return CardVendor.VISA;
    }
    if (amex.test(cc)) {
        return CardVendor.AMEX;
    }
    if (mastercard.test(cc) || mastercard2.test(cc)) {
        return CardVendor.MASTERCARD;
    }
    if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
        return CardVendor.DISCOVER;
    }
    if (diners.test(cc)) {
        return CardVendor.DINERS;
    }
    return undefined;
}

export function cardIcon(number) {
    const vendor = guessCardVendor(number);
    return cardIcons()[vendor.toLowerCase()];
}
