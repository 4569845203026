<script setup>
import ShoppingStoreIcon from '@/customer/Components/Icons/ShoppingStoreIcon.vue';
import { RadioGroup, RadioGroupOption } from '@headlessui/vue';
import { computed } from 'vue';
import { DevicePhoneMobileIcon } from '@heroicons/vue/20/solid';
import InputError from '@/customer/Components/InputError.vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: String,
        default: null,
    },
    locations: {
        type: [Array, Object],
        default: [],
    },
    errorMessage: String,
});

const selection = computed({
    get: () => props.modelValue,
    set(value) {
        emit('update:modelValue', value);
    },
});
</script>

<template>
    <RadioGroup v-model="selection">
        <ul class="grid grid-cols-2 gap-4" v-if="locations">
            <RadioGroupOption
                v-if="locations.length"
                v-for="(location, index) in locations"
                :key="`location${index}`"
                :value="location.slug"
                as="template"
                v-slot="{ active, checked }"
            >
                <li
                    class="group flex cursor-pointer flex-row items-center rounded-xl border px-3 py-3 shadow-md transition-colors [@media(width>610px)]:space-x-6 [@media(width>610px)]:px-6"
                    :class="[
                        checked
                            ? 'border-transparent bg-palmers-havarti shadow-none ring-4 ring-palmers-dragon-fruit'
                            : 'bg-white supports-hover:hover:border-transparent supports-hover:hover:bg-palmers-havarti supports-hover:hover:bg-opacity-30 supports-hover:hover:ring-4 supports-hover:hover:ring-palmers-himalayan-pink',
                        active ? '' : '',
                    ]"
                >
                    <ShoppingStoreIcon
                        class="hidden h-14 w-14 bg-white [@media(width>610px)]:block"
                    />

                    <div>
                        <h3 class="text-lg font-semibold leading-[1.3rem] md:text-base">
                            {{ location.name }}
                        </h3>

                        <address class="text-xs not-italic leading-tight" v-if="location.address">
                            <span> {{ location.address.street }} </span><br />
                            <template v-if="location.address.street2">
                                <span> {{ location.address.street2 }} </span><br />
                            </template>
                            <span class="leading-none">
                                {{ location.address.city }},
                                {{ location.address.state }}
                                {{ location.address.zip }}
                            </span>
                        </address>

                        <a
                            v-if="location.address"
                            :href="`tel:${location.address.phone}`"
                            class="mt-1 inline-flex items-center space-x-1 text-sm underline md:pointer-events-none md:cursor-auto md:no-underline"
                        >
                            <DevicePhoneMobileIcon class="h-6 w-4" />
                            <span>{{ location.address.phoneDisplay }}</span>
                        </a>
                    </div>
                </li>
            </RadioGroupOption>
        </ul>
        <InputError v-if="errorMessage" :message="errorMessage" class="text-center" />
    </RadioGroup>
</template>
