<script setup>
import InputError from "@/customer/Components/InputError.vue";
import InputLabel from "@/customer/Components/InputLabel.vue";
import { default as Button } from "@/customer/Components/BaseButton.vue";
import InputText from "@/customer/Components/InputText.vue";
import { Head, useForm } from "@inertiajs/vue3";

const form = useForm({
    password: "",
});

const submit = () => {
    form.post(route("customer.password.confirm"), {
        onFinish: () => form.reset(),
    });
};
</script>

<template>
    <Head title="Confirm Password" />

    <div class="mb-4 text-sm text-gray-600">
        This is a secure area of the application. Please confirm your password
        before continuing.
    </div>

    <form @submit.prevent="submit">
        <div>
            <InputLabel for="password" value="Password" />
            <InputText
                id="password"
                v-model="form.password"
                type="password"
                class="mt-1 block w-full"
                required
                autocomplete="current-password"
                autofocus
            />
            <InputError class="mt-2" :message="form.errors.password" />
        </div>

        <div class="flex justify-end mt-4">
            <Button
                class="ml-4"
                :class="{ 'opacity-25': form.processing }"
                :disabled="form.processing"
            >
                Confirm
            </Button>
        </div>
    </form>
</template>
