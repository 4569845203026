import { onMounted } from 'vue';

/**
 * When Laravel renders an exception as a Laravel component, ensure
 * the details of the error are logged to the console.
 */
export default {
    install(app, options) {
        const inertia = app.config.globalProperties?.$inertia;
        console.log({ app });
        let executed = false;
        app.mixin({
            mounted() {
                if (!executed && inertia?.page?.props) {
                    if ('error' in inertia.page.props) {
                        const error = inertia.page.props.error;
                        console.error(error.message, error);
                    }
                    executed = true;
                }
            },
        });
    },
};
