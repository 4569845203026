<script setup>
import { computed, reactive, watch } from 'vue';
import InputText from '@/customer/Components/InputText.vue';
import { useOrderStore, useCartStore } from '@/customer/Composables';

const order = useOrderStore();
const cart = useCartStore();

const state = reactive({
    otherTipSelected: false,
    selectedTip: order.currentQuickTip,
});

const selectTip = (tipValue) => {
    state.otherTipSelected = false;
    state.selectedTip = tipValue;
    order.applyPercentageTip(tipValue);
};

const handleOtherButtonClick = () => {
    state.otherTipSelected = true;
    state.selectedTip = null;
    order.applyPercentageTip(0);
};

watch(
    () => order.fixedTip,
    (value) => {
        if (value == null || value == '') {
            selectTip(0);
        } else {
            order.tip_percent = null;
            order.applyFixedTip(value);
        }
    },
);
</script>

<template>
    <div class="mt-4">
        <p class="text-sm font-bold uppercase text-palmers-mush-grey">Optional</p>
        <div class="flex w-full items-center gap-x-2 text-xl font-bold text-palmers-charcoal-grey">
            <p>TIP:</p>
            <p>{{ cart.getCartAdjustmentPrice('tip') }}</p>
        </div>

        <div class="mt-3 text-palmers-charcoal-grey md:mt-1">
            <div class="flex w-full flex-wrap gap-x-1 gap-y-3">
                <template v-for="tip in order.tips" :key="tip.value">
                    <button
                        class="text-md w-auto rounded-full border-2 border-black px-4 py-2 font-bold supports-hover:hover:bg-palmers-avocado-green"
                        :class="
                            tip.value == state.selectedTip ? 'bg-palmers-avocado-green' : 'bg-white'
                        "
                        @click.prevent="selectTip(tip.value)"
                        :id="`tip_${tip.value}`"
                    >
                        {{ tip.label }}
                    </button>
                </template>
                <button
                    class="text-md rounded-full border-2 border-black px-4 py-2 font-bold supports-hover:hover:bg-palmers-avocado-green"
                    :class="state.otherTipSelected ? 'bg-palmers-avocado-green' : 'bg-white'"
                    @click.prevent="handleOtherButtonClick"
                    id="tip_other"
                >
                    Other
                </button>
                <button
                    v-if="order.showsNoTip"
                    class="text-md rounded-full border-2 border-black px-4 py-2 font-bold supports-hover:hover:bg-palmers-avocado-green"
                    :class="
                        0 == state.selectedTip && !state.otherTipSelected
                            ? 'bg-palmers-avocado-green'
                            : 'bg-white'
                    "
                    @click.prevent="selectTip(0)"
                    id="tip_0"
                >
                    {{ order.noTipLabel }}
                </button>
                <div class="relative flex items-center" v-if="state.otherTipSelected">
                    <span class="absolute left-4 text-xl text-palmers-charcoal-grey">$</span>
                    <InputText
                        :error-message="cart.errors.tip"
                        v-model="order.fixedTip"
                        class="ml-1 !max-w-40 flex-grow !py-2 !pl-[2.3rem] text-palmers-charcoal-grey"
                        error-class="text-white"
                        name="tip_custom"
                        :mask-options="{
                            mask: '0.99',
                            tokens: {
                                '0': { pattern: /\d/, multiple: true },
                                '9': { pattern: /\d/, optional: true },
                            },
                        }"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
