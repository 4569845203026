import { computed, readonly, ref, toValue } from 'vue';
import { usePage, useForm, router } from '@inertiajs/vue3';
import { useStepper } from '@vueuse/core';
import { provide } from 'vue';
import useApp from './useApp';
import { orderState } from '../Utils/keys';
import { find, some, startsWith, get, isEmpty } from 'lodash';
import useCartStore from './useCartStore';
import useOrderStore from './useOrderStore';

export default function useOrderProvider() {
    const order = useOrderStore();
    order.initialize();

    provide(orderState, order);
    return order;
}
