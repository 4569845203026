<script setup>
import { ref } from 'vue';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/vue/24/solid';
import AddOnItem from '@/customer/Components/Cart/AddOnItem.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

const props = defineProps({
    items: { type: Object, required: true },
    label: { type: String, default: null },
});

const swiper = ref(null);
const onSwiper = (swipe) => {
    swiper.value = swipe;
};
</script>

<template>
    <div class="bg-palmers-havarti px-10 py-8 text-palmers-charcoal-grey sm:px-20">
        <div class="flex justify-between">
            <h3 class="text-2xl font-bold">
                {{ label }}
            </h3>
            <div v-if="items.length > 3" class="flex space-x-6">
                <ArrowLeftIcon
                    class="h-8 w-8 cursor-pointer stroke-palmers-charcoal-grey stroke-2"
                    @click="swiper.slidePrev()"
                />
                <ArrowRightIcon
                    class="h-8 w-8 cursor-pointer stroke-palmers-charcoal-grey stroke-2"
                    @click="swiper.slideNext()"
                />
            </div>
        </div>
        <Swiper
            :slides-per-view="1"
            @swiper="onSwiper"
            :breakpoints="{
                360: {
                    slidesPerView: 2,
                },
                500: {
                    slidesPerView: 3,
                },
            }"
        >
            <SwiperSlide v-if="items.length > 3" class="my-5" v-for="item in items" :key="item.id">
                <AddOnItem :item="item" as="a" />
            </SwiperSlide>
            <div v-else class="flex justify-between my-5">
                 <div v-for="item in items" :key="item.id">
                    <AddOnItem :item="item" as="a" />
                </div>
            </div>
        </Swiper>
    </div>
</template>
