import { defineStore } from 'pinia';
import { useForm, usePage } from '@inertiajs/vue3';
import { watch } from 'vue';
import { checkStateZip } from '@/customer/Composables/useCheckStateZip';
import { CheckboxInput, InputSelectState, PhoneInput, TextareaInput } from '@/customer/Components';
import { states } from '@/common/Utils/states';
import { string, boolean } from 'yup';

export default defineStore('deliveryAddressStore', {
    state: () => ({
        addressForm: useForm(route().current(), {
            id: 0,
            name: null,
            street: null,
            street2: null,
            city: null,
            state: null,
            zip: null,
            phone: '',
            landmarks: null,
            notes: null,
            building: null,
            floor: null,
            drop_off: false,
            call: false,
            save: true,
            same_address: false,
        }),
        selectedAddressInfo: null,
        userAddresses: usePage().props.auth.user.addresses,
        newAddressFlag: false,
        authNetStore: null,
        cityStateZipIsValid: true,
    }),
    getters: {
        getFormElements() {
            const disabledClass =
                'disabled:bg-palmers-mush-grey/20 disabled:border-palmers-dragon-fruit/65 disabled:text-opacity-50';
            return [
                {
                    name: 'same_address',
                    type: { ...CheckboxInput },
                    validator: string().required('name'),
                    class: 'col-span-2',
                    props: {
                        label: 'Delivery Address Same As Payment Address',
                    },
                },
                {
                    name: 'name',
                    type: 'text',
                    validator: string().required('name'),
                    class: 'col-span-2 w-full',
                    inputClass: disabledClass,
                    props: {
                        disabled: this.addressForm.same_address,
                        autofocus: true,
                        autocomplete: 'name',
                    },
                },
                {
                    name: 'phone',
                    type: { ...PhoneInput },
                    validator: string().required('phone'),
                    class: 'col-span-2 w-full',
                    inputClass: disabledClass,
                    props: {
                        disabled: this.addressForm.same_address,
                        autocomplete: 'tel',
                    },
                },
                {
                    name: 'street',
                    type: 'text',
                    validator: string().required('cc-street'),
                    class: 'col-span-2 w-full',
                    inputClass: disabledClass,
                    props: {
                        disabled: this.addressForm.same_address,
                        autocomplete: 'street-address',
                    },
                },
                {
                    name: 'city',
                    type: 'text',
                    validator: string().required('cc-city'),
                    class: 'col-span-2 w-full',
                    inputClass: disabledClass,
                    props: {
                        disabled: this.addressForm.same_address,
                        autocomplete: 'address-level2',
                    },
                },
                {
                    name: 'state',
                    type: { ...InputSelectState },
                    validator: string()
                        .required('cc-state')
                        .test('state-zip', 'state-zip', () => {
                            return $address.cityStateZipIsValid;
                        }),
                    class: 'col-span-2 w-full',
                    inputClass: 'disabled:bg-palmers-mush-grey/20',
                    props: {
                        disabled: this.addressForm.same_address,
                        autocomplete: 'address-level1',
                        states: states,
                    },
                },
                {
                    name: 'zip',
                    type: 'text',
                    inputClass: disabledClass,
                    validator: string()
                        .required('cc-zip')
                        .matches(/^\d{5}(?:-\d{4})?$/, 'cc-zip')
                        .test('state-zip', 'state-zip', () => {
                            return $address.cityStateZipIsValid;
                        }),
                    class: 'col-span-2 w-full',
                    props: {
                        disabled: this.addressForm.same_address,
                        autocomplete: 'postal-code',
                    },
                },
                {
                    name: 'landmarks',
                    type: { ...TextareaInput },
                    validator: string().optional('optional'),
                    class: 'col-span-2 w-full text-palmers-black',
                    props: {
                        autocomplete: 'landmarks',
                        rows: 4,
                    },
                },
                {
                    name: 'building',
                    type: 'text',
                    validator: string().optional('optional'),
                    class: 'col-span-2 w-full',
                    props: {
                        autocomplete: 'building',
                    },
                },
                {
                    name: 'street2',
                    type: 'text',
                    validator: string().optional('optional'),
                    class: 'col-span-2 w-full md:col-span-1',
                    props: {
                        autocomplete: 'street2',
                        label: 'Suite',
                    },
                },
                {
                    name: 'floor',
                    type: 'text',
                    validator: string().optional('optional'),
                    class: 'col-span-2 w-full md:col-span-1',
                    props: {
                        autocomplete: 'floor',
                    },
                },
                {
                    name: 'drop_off',
                    type: { ...CheckboxInput },
                    validator: boolean(),
                    class: 'col-span-2',
                    props: {
                        autocomplete: 'drop_off',
                    },
                },
                {
                    name: 'notes',
                    type: { ...TextareaInput },
                    validator: string().optional('optional'),
                    class: 'col-span-2 w-full text-palmers-black',
                    props: {
                        autocomplete: 'notes',
                        rows: 4,
                    },
                },
                {
                    name: 'call',
                    type: { ...CheckboxInput },
                    validator: boolean(),
                    class: 'col-span-2',
                    props: {
                        autocomplete: 'call',
                    },
                },
                {
                    name: 'save',
                    type: { ...CheckboxInput },
                    validator: boolean(),
                    class: 'col-span-2',
                    props: {
                        autocomplete: 'save',
                    },
                },
            ];
        },
        getDeliveryAddressLabels() {
            return this.userAddresses.map((address) => ({
                id: address.id,
                choice: {
                    name: `${address.deliveryInstructions.name ? `${address.deliveryInstructions.name}: ` : ''}${address.street} - ${address.city}, ${address.state}`,
                },
            }));
        },
        getParsedAddressForm() {
            const propertiesToExclude = [
                'isDirty',
                'errors',
                'hasErrors',
                'processing',
                'progress',
                'wasSuccessful',
                'recentlySuccessful',
                '__rememberable',
                'same_address',
            ];
            return Object.fromEntries(
                Object.entries(this.addressForm).filter(
                    ([key]) => propertiesToExclude.indexOf(key) === -1,
                ),
            );
        },
    },
    actions: {
        init(authNetStore) {
            this.authNetStore = authNetStore;

            watch(
                () => [this.addressForm?.state, this.addressForm.zip],
                ([stateInput, zipInput]) => {
                    const checkInput = (input) => typeof input === 'string' && input.length > 0;
                    if (checkInput(stateInput) && checkInput(zipInput)) {
                        this.cityStateZipIsValid = checkStateZip(stateInput, zipInput);
                    }
                },
            );

            watch(
                () => [
                    this.addressForm.same_address,
                    this.authNetStore.requestData,
                    this.authNetStore.responseData,
                ],
                ([newSameAddress], [oldSameAddress]) => {
                    if (!this.addressForm.same_address) {
                        if (newSameAddress !== oldSameAddress) {
                            this.addressForm.reset();
                        }
                        return;
                    }
                    const withPaymentProfile = !!this.authNetStore.responseData.paymentProfileId;
                    const paymentProfileInfo = withPaymentProfile
                        ? usePage().props.paymentProfiles.data.find(
                              (profile) =>
                                  profile.customerPaymentProfileId ==
                                  this.authNetStore.responseData.paymentProfileId,
                          )
                        : null;
                    this.merge({
                        name: withPaymentProfile
                            ? paymentProfileInfo.billTo.firstName
                            : this.authNetStore.requestData.cardData.fullName,
                        street: withPaymentProfile
                            ? paymentProfileInfo.billTo.address
                            : this.authNetStore.responseData.billingDetails.street1,
                        street2: withPaymentProfile
                            ? null
                            : this.authNetStore.responseData.billingDetails.street2,
                        city: withPaymentProfile
                            ? paymentProfileInfo.billTo.city
                            : this.authNetStore.responseData.billingDetails.city,
                        state: withPaymentProfile
                            ? paymentProfileInfo.billTo.state
                            : this.authNetStore.responseData.billingDetails.state,
                        phone: this.authNetStore.responseData.contactDetails.phone,
                        zip: withPaymentProfile
                            ? paymentProfileInfo.billTo.zip
                            : this.authNetStore.responseData.billingDetails.zip,
                    });
                },
                { deep: true },
            );
        },
        setNewAddressFlag(boole) {
            this.newAddressFlag = boole;
            this.addressForm.reset();
        },
        selectInputIngress(selectAddressObject) {
            if (selectAddressObject.id !== 'placeholder' && selectAddressObject.value) {
                this.selectedAddressInfo = this.userAddresses.find((a) => {
                    return a.id === Number(selectAddressObject.value);
                });
                const nestedAddress = this.selectedAddressInfo;

                const flatAddress = {};

                for (const key in nestedAddress) {
                    if (typeof nestedAddress[key] === 'object' && nestedAddress[key] !== null) {
                        Object.assign(flatAddress, nestedAddress[key]);
                    } else {
                        flatAddress[key] = nestedAddress[key];
                    }
                }
                this.addressForm.reset();
                this.merge(flatAddress);
            } else {
                this.addressForm.reset();
            }
        },
        merge(newData) {
            Object.entries(newData).forEach(([key, value]) => {
                this.addressForm[key] = value;
            });
        },
    },
});
