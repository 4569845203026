<script setup>
import { ref, watch } from 'vue';
import DatePicker from 'primevue/datepicker';
import moment from 'moment';

const props = defineProps({
    modelValue: {
        type: [String, Date, Object, null],
        required: false,
        default: null,
    },
    minDate: {
        type: [null, Object, Date, String],
        default: moment(),
        required: false,
    },
    maxDate: {
        type: [null, Object, Date, String],
        default: null,
        required: false,
    },
    minTime: {
        type: [null, String, Object, Date],
        default: null,
        required: false,
    },
    maxTime: {
        type: [null, String, Object, Date],
        default: null,
        required: false,
    },
    placeholder: {
        type: String,
        default: 'Select a date',
    },
    showTime: {
        type: Boolean,
        default: false,
    },
    timeOnly: {
        type: Boolean,
        default: false,
    },
    disabledDaysOfWeek: {
        type: Array,
        default: [],
        required: false,
    },
    closedDays: {
        type: Array,
        default: [],
        required: false,
    },
    adjustTimeBasedOnDate: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const selectedDate = ref(moment(props.modelValue, 'YYYY-MM-DD h:mm A').format('MMM DD, YYYY'));
const vmod = ref(moment(props.modelValue, 'YYYY-MM-DD hh:mm A').toDate());

const emit = defineEmits(['update:modelValue']);

const handleUpdate = ($event) => {
    if (props.minTime || props.maxTime) {
        if (moment($event).format('HH:mm') > moment(props.maxTime, 'hh:mm A').format('HH:mm')) {
            selectedDate.value = moment(
                moment($event).format('YYYY-MM-DD') + ' ' + props.maxTime,
                'YYYY-MM-DD hh:mm A',
            ).toDate();
            vmod.value = moment(
                moment($event).format('YYYY-MM-DD') + ' ' + props.maxTime,
                'YYYY-MM-DD hh:mm A',
            ).toDate();
        } else if (
            moment($event).format('HH:mm') <
            moment(
                moment($event).format('YYYY-MM-DD') + ' ' + props.minTime,
                'YYYY-MM-DD hh:mm A',
            ).format('HH:mm')
        ) {
            selectedDate.value = moment(
                moment($event).format('YYYY-MM-DD') + ' ' + props.minTime,
                'YYYY-MM-DD hh:mm A',
            ).toDate();
            vmod.value = moment(
                moment($event).format('YYYY-MM-DD') + ' ' + props.minTime,
                'YYYY-MM-DD hh:mm A',
            ).toDate();
        } else {
            selectedDate.value = moment($event).toDate();
            vmod.value = moment($event).toDate();
        }
    } else {
        selectedDate.value = moment($event).format('YYYY-MM-DD HH:mm');
        vmod.value = moment($event).toDate();
    }

    emit('update:modelValue', selectedDate.value);
};

watch(
    () => props.modelValue,
    (newVal) => {
        vmod.value = moment(newVal).toDate();
    },
);
</script>

<template>
    <DatePicker
        :show-time="showTime"
        :time-only="timeOnly"
        hour-format="12"
        :show-icon="true"
        fluid
        :placeholder="placeholder"
        :model-value="vmod"
        @update:model-value="($event) => handleUpdate($event)"
        date-format="M dd, yy"
        :disabledDays="disabledDaysOfWeek"
        :disabledDates="closedDays.map((closedDay) => moment(closedDay).toDate())"
        :minDate="moment(minDate).toDate()"
        :maxDate="maxDate ? moment(maxDate).toDate() : null"
    />
</template>
