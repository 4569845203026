<script setup>
import { inject, computed } from 'vue';
import { orderState } from '@/customer/Utils/keys';
import { Link } from '@inertiajs/vue3';
import { switcherProps } from '@/customer/Components/Order/Internal/props';
import ProgressSwitcher from '../ProgressSwitcher.vue';

const props = defineProps({
    ...switcherProps,
    isCheckout: {
        type: Boolean,
        default: false,
    },
});

const order = inject(orderState);

const address = computed(() => order.progress.location.address);
const cityStateZip = computed(
    () => `${address.value.city}, ${address.value.state}  ${address.value.zip}`,
);

const dropdownDisabled = computed(() => order.isStep('location') && !order.inProgress);
const label = computed(() => order.progress.location.nameWithStreet);
</script>

<template>
    <ProgressSwitcher
        :is-checkout="isCheckout"
        :align="isCheckout ? 'left' : 'center'"
        v-bind="{ ...props, dropdownDisabled, label }"
    >
        <template #dropdown.content>
            <div class="w-full space-y-1 py-3 text-center">
                <div class="whitespace-pre px-8 text-lg font-extrabold">{{ address.street }}</div>
                <div class="whitespace-pre px-8">{{ cityStateZip }}</div>
                <div>
                    <Link
                        :href="route('customer.locations')"
                        class="border-b-2 border-dotted border-gray-400 supports-hover:hover:border-solid"
                        >Store Info</Link
                    >
                </div>
            </div>
            <div class="bg-palmers-himalayan-pink">
                <div class="flex items-center justify-center space-x-3 whitespace-pre p-5 px-8">
                    <span>Need to change locations?</span>
                    <Link
                        :href="order.startOverHref"
                        @success="() => $emit('start-over')"
                        class="border-b-2 border-dotted border-black supports-hover:hover:border-solid"
                        >Start Over</Link
                    >
                </div>
            </div>
        </template>
    </ProgressSwitcher>
</template>
